<template>
  <header id="Cronology__header">
    <nav class="foodType noMobile" v-if="(desayuno && almuerzo) || (almuerzo && cena) || (cena && desayuno)">
      <label v-if="desayuno" for="Desayuno" :class="{ active: mealTime.includes('Desayuno') }"
        > <p>Desayuno</p>
        <input
          type="checkbox"
          value="Desayuno"
          v-model="mealTime"
          id="Desayuno"
      /></label>
      <label v-if="almuerzo" for="Almuerzo" :class="{ active: mealTime.includes('Almuerzo') }"
        >
        <p>Almuerzo</p>
        <input
          type="checkbox"
          value="Almuerzo"
          v-model="mealTime"
          id="Almuerzo"
      /></label>
      <label v-if="cena" for="Cena" :class="{ active: mealTime.includes('Cena') }"
        > <p>Cena</p> <input type="checkbox" value="Cena" v-model="mealTime" id="Cena"
      /></label>
    </nav>
    <div class="divisor notMobile"></div>
    <div class="buttonContainer" v-if="!validatePermission('ReadOnly') && !validatePermission('MixSFCN')">
      <button
        class="ReservationButton"
        @click.prevent.stop="setAddReservation()"
      >
        <p>Reserva</p>
        <span>+</span>
      </button>
      <button v-if="selectedDate == today" class="WalkButton" @click.prevent.stop="setAddWalkin()">
        <p>Walk-In</p>
        <span>+</span>
      </button>
      <button class="PrecompraButton" @click.prevent.stop="setAddPrecompra()" v-if="params.prePurchaseDashboard">
        <p>Precompra</p>
        <span>+</span>
      </button>
    </div>
    <div class="divisor onlyTablet"></div>
    <nav class="typeReservation desktop">
      <div :class="{ active: recents }" @click="() => (recents = true)">
        <p>Actuales</p>
      </div>
      <div :class="{ active: !recents }" @click="() => (recents = false)">
        <p>Finalizadas</p>
      </div>
    </nav>
    <div class="inputContainer"  v-click-outside="() => (activeFilterList = false) ">
      <div class="searcherContainer" :class="{optionsOuts:activeFilterList}">
      <span class="SearchIcon"></span>
      <form @submit.prevent="submitFilter">
              <input type="text" v-model="filterByName">
      </form>

      </div>
      <div class="options" v-show="activeFilterList">
        <div class="optionsContainer" v-show="reservationsFiltered.length > 0">

                              <div v-for="reservation in reservationsFiltered" :key="'header-' + reservation.id" @click="filterByName=reservation.displayName">
                                <span></span>
        <p>{{ reservation.displayName }}</p>
        </div>

        </div>
        <p v-show="reservationsFiltered.length === 0" class="noCoincidences">No se encontró resultados para esa búsqueda.</p>
      </div>
    </div>
    <div class="GenerateReport">
      <span
        class="imprimir icons"
        @click="generateReport($event)"
      ></span>
        <ReporteReservation
          v-if="reportMenuOpen"
          v-model="apiReportDownload"
          :event="eventForReportModal"
          @close-emit="() => (reportMenuOpen = false)"
        ></ReporteReservation>
    </div>

    <b-dropdown
      class="showConventions"
      position="is-bottom-left"
      aria-role="list"
    >
      <p slot="trigger" class="conv_btn ffr">Convenciones</p>
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons nextReservation"></div>
        <div class="msg_cvn ffr">Reserva proxima</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons sittingReservation"></div>
        <div class="msg_cvn ffr">Reserva sentada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons lateReservation"></div>
        <div class="msg_cvn ffr">Reserva retrasada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons sobreHoraReservation"></div>
        <div class="msg_cvn ffr">Reserva sobre hora</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons precompra"></div>
        <div class="msg_cvn ffr">Precompra sentada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons walkSit"></div>
        <div class="msg_cvn ffr">Walk-in sentada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons warning"></div>
        <div class="msg_cvn ffr">Multiples Reservas</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons key"></div>
        <div class="msg_cvn ffr">Mesa privada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons blocked"></div>
        <div class="msg_cvn ffr">Mesa bloqueada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons hoursBlocked"></div>
        <div class="msg_cvn ffr">Horas bloqueadas</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons redWarning"></div>
        <div class="msg_cvn ffr">Sobre reserva</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons bell"></div>
        <div class="msg_cvn ffr">Varias notificaciones</div></b-dropdown-item
      >
    </b-dropdown>
    <b-dropdown
      class="showConventionsSmallDevices"
      position="is-bottom-left"
      aria-role="list"
      ref="dropdown"
      :mobile-modal="false"
      :close-on-click="false"
    >
      <img
        slot="trigger"
        class="ImgTrigger"
        src="@/assets/icons/allTimeLineIcons/configuraciones.svg"
      />
                  <b-dropdown-item
                  v-if="windowZise "
        :focusable="false"
        custom
        class="titleAbsolute"
        aria-role="listitem"
      >
        <header>
<span class="icon_close" @click="$refs.dropdown.toggle()" >
x
</span>
          <h3 class="configuraciones">Configuraciones</h3>
          </header
      ></b-dropdown-item>
            <b-dropdown-item
            v-if="windowZise <=800"
        :focusable="false"
        custom
        class="item-dd"
        aria-role="listitem"
        >
    <nav class="foodType"   v-if="(desayuno && almuerzo) || (almuerzo && cena) || (cena && desayuno)">
      <label v-if="desayuno" for="Desayuno" :class="{ active: mealTime.includes('Desayuno') }"
        > <p>Desayuno</p>
        <input
          type="checkbox"
          value="Desayuno"
          v-model="mealTime"
          id="Desayuno"
      /></label>
      <label v-if="almuerzo" for="Almuerzo" :class="{ active: mealTime.includes('Almuerzo') }"
        >
        <p>Almuerzo</p>
        <input
          type="checkbox"
          value="Almuerzo"
          v-model="mealTime"
          id="Almuerzo"
      /></label>
      <label v-if="cena" for="Cena" :class="{ active: mealTime.includes('Cena') }"
        > <p>Cena</p> <input type="checkbox" value="Cena" v-model="mealTime" id="Cena"
      /></label>
    </nav>
    </b-dropdown-item
      >
            <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd notHorizontal"
        aria-role="listitem"
        >
            <nav class="typeReservation onlyMobile">
      <div :class="{ active: recents }" @click="() => (recents = true)">
        Actuales
      </div>
      <div :class="{ active: !recents }" @click="() => (recents = false)">
        Finalizadas
      </div>
    </nav>
        </b-dropdown-item
      >

      <b-dropdown-item
        :focusable="false"
        custom
        class="titleContionsMobile"
        aria-role="listitem"
      >
        <header>
          <span></span>
          <h3>Convenciones</h3>
          <span></span></header
      ></b-dropdown-item>
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons nextReservation"></div>
        <div class="msg_cvn ffr">Reserva proxima</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons sittingReservation"></div>
        <div class="msg_cvn ffr">Reserva sentada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons lateReservation"></div>
        <div class="msg_cvn ffr">Reserva retrasada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons sobreHoraReservation"></div>
        <div class="msg_cvn ffr">Reserva sobre hora</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons precompra"></div>
        <div class="msg_cvn ffr">Precompra sentada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons walkSit"></div>
        <div class="msg_cvn ffr">Walk-in sentada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons warning"></div>
        <div class="msg_cvn ffr">Multiples Reservas</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons key"></div>
        <div class="msg_cvn ffr">Mesa privada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons blocked"></div>
        <div class="msg_cvn ffr">Mesa bloqueada</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons hoursBlocked"></div>
        <div class="msg_cvn ffr">Horas bloqueadas</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons redWarning"></div>
        <div class="msg_cvn ffr">Sobre reserva</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="item-dd info"
        aria-role="listitem"
        ><div class="icons bell"></div>
        <div class="msg_cvn ffr">Varias notificaciones</div></b-dropdown-item
      >
      <b-dropdown-item
        :focusable="false"
        custom
        class="getReportMobileDropDown"
        aria-role="listitem"
      >
        <div class="GenerateReportMobile">
          <div class="separatorForReportMobile"></div>
          <div
            class="buttonDownload"
            @click="generateReport($event)"
          >
          <span></span>
          <p>Descargar Reporte</p> </div>
            <ReporteReservation
              v-if="reportMenuOpen"
              :event="eventForReportModal"
              v-model="apiReportDownload"
              @close-emit="() => (reportMenuOpen = false)"
            ></ReporteReservation></div
      ></b-dropdown-item>
    </b-dropdown>
  </header>
</template>
<script>
import ReporteReservation from '@/components/_shared/ReporteReservation';
import reservationTypes from '@/store/types/reservation';
import { mapGetters } from 'vuex';
export default {
  name: 'CronologyHeader',
  props: ['coincidences', 'filterActivate'],
  components: {
    ReporteReservation
  },
  data () {
    return {
      reportMenuOpen: false,
      generate: false,
      recents: true,
      filterByName: '',
      mealTime: [],
      reservationsFiltered: [],
      activeFilterList: false,
      mealTimesAvailables: [],
      eventForReportModal: {}
    };
  },
  mounted () {
    const dayOfWeek = this.$moment(this.selectedDate).format('d');
    if (this.turns.desayuno) {
      if (this.turns.desayuno[dayOfWeek]) {
        this.mealTimesAvailables.push('Desayuno');
      }
    }
    if (this.turns.almuerzo) {
      if (this.turns.almuerzo[dayOfWeek]) {
        this.mealTimesAvailables.push('Almuerzo');
      }
    }
    if (this.turns.cena) {
      if (this.turns.cena[dayOfWeek]) {
        this.mealTimesAvailables.push('Cena');
      }
    }
    this.$emit('mealTime', this.mealTimesAvailables);
  },
  methods: {
    generateReport (event) {
      const posicion = event.target.getBoundingClientRect();
      this.eventForReportModal = { pageX: posicion.x + 8, pageY: posicion.y + 9 };
      if (this.reportMenuOpen) {
        this.reportMenuOpen = false;
        return;
      }
      this.reportMenuOpen = true;
    },
    submitFilter () {
      this.activeFilterList = false;
    }
  },
  computed: {
    ...mapGetters({
      turns: reservationTypes.getters.theTurns
    }),
    windowZise () {
      return window.innerWidth;
    },
    desayuno () {
      const dayOfWeek = this.$moment(this.selectedDate).format('d');
      if (this.turns.desayuno) {
        if (!this.turns.desayuno[dayOfWeek]) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    almuerzo () {
      const dayOfWeek = this.$moment(this.selectedDate).format('d');
      if (this.turns.almuerzo) {
        if (!this.turns.almuerzo[dayOfWeek]) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    cena () {
      const dayOfWeek = this.$moment(this.selectedDate).format('d');
      if (this.turns.cena) {
        if (!this.turns.cena[dayOfWeek]) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    filterActivate (value) {
      switch (value) {
        case 0:
          this.mealTime = [];
          break;
        case 1:
          this.mealTime = ['Desayuno'];
          break;
        case 2:
          this.mealTime = ['Almuerzo'];
          break;
        case 3:
          this.mealTime = ['Cena'];
          break;

        default:
          break;
      }
    },
    filterByName (value) {
      this.$emit('filterByName', value);
      this.reservationsFiltered = [];
      if (value) {
        this.reservationsFiltered = this.coincidences.filter(reservation => (reservation.displayName.toLowerCase()).includes(this.filterByName.toLowerCase()));
      }
    },
    recents (value) {
      this.$emit('recents', value);
    },
    mealTime (value) {
      this.$emit('mealTime', value);
    },
    selectedDate () {
      const dayOfWeek = this.$moment(this.selectedDate).format('d');
      this.mealTime = [];
      if (this.turns.desayuno) {
        if (this.turns.desayuno[dayOfWeek]) {
          this.mealTimesAvailables.push('Desayuno');
        }
      }
      if (this.turns.almuerzo) {
        if (this.turns.almuerzo[dayOfWeek]) {
          this.mealTimesAvailables.push('Almuerzo');
        }
      }
      if (this.turns.cena) {
        if (this.turns.cena[dayOfWeek]) {
          this.mealTimesAvailables.push('Cena');
        }
      }
      this.$emit('mealTime', this.mealTimesAvailables);
    },
    coincidences () {
      this.activeFilterList = false;
      if (this.filterByName) {
        this.activeFilterList = true;
      }
    }
  }
};
</script>
<style lang="scss">
@mixin icon($url, $w: 15px, $h: 15px) {
  width: $w;
  height: $h;
  background-image: url($url);
}
#Cronology__header {
  background-color: #fbfbfc;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 12px;
  z-index: 11;
  width: 100%;
  .foodType {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    max-height: 30px;
    background-color:#f3f3f3;
    gap:2px;
    padding:2px;
    border-radius:4px;
    .dropdown .dropdown-menu{
      border-radius: 8px;
      border: solid 1px #A8ADB5;
      box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.25);
      min-width: 220px;
      max-width: 220px;
      padding-top: unset;
      @media screen and (max-width:1200px) {
        min-width: 215px;
        max-width: 215px;
        padding-top: unset;
      }
      @media screen and (max-width:600px) {
        max-height: 440px !important;
        padding: unset !important;
        overflow: scroll  !important;
      }
      .titleAbsolute.dropdown-item{
        padding: unset;
      }
      .dropdown-content{
        cursor: auto;
        padding-top: unset ;
        border-radius: 8px;
        @media screen and (min-width:1200px) {
          padding-top: 15px;
          padding-bottom: 16px;
        }
      }
    }
    .notHorizontal{
      @media screen and (min-width:1000px){
        display: none;
      }
    }
    @media screen and (max-width: 1100px) {
      margin-right: 0px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 7px;
    }
    &.noMobile{
      @media screen and (max-width: 890px) {
        display: none;
      }
    }
    &:not(.noMobile){
      @media screen and (min-width: 890px) {
        display: none;
      }
    }
    input {
      visibility: hidden;
      position: absolute;
    }
    label {
      background-color: white;
      color: #444B57;
      text-align: center;
      width: 72px;
      border-radius:4px;
      max-height: 26px;
      width:70px;
      min-height: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 860px) {
        font-size: 13px;
        padding: 5px 8px;
      }
      p{
        font-size: 12px;
        color: #444B57;
        font-weight: 400;
      }
      &.active {
        color: white;
        background-color: #444b57;
        p{
          color: white;
          font-weight:500;
        }
      }
    }
  }
  .ReservationButton {
    background-color: #00adc6;
  }
  .requestReservation {
    background-color: #8eb2bd;
  }
  .WalkButton {
    background-color: #8a51b4;
  }
  .PrecompraButton {
    background-color: #BDC30E;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    @media screen and (min-width:1400px) {
      gap: 15px;
    }
    @media screen and (min-width:900px) and (max-width:1400px) {
      gap: 8px;
    }
    @media screen and (max-width: 900px) {
      gap: 8px;
      justify-content: space-between;
      justify-content: space-between;
    }
    @media screen and (max-width: 700px) {
      display: none;
    }
    button {
      font-size: 15px;
      color: white;
      border-radius: 7px;
      padding: 7px 30px;
      outline: unset;
      border: unset;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content:center;
      gap: 15px;
      height: 30px;
      width:138px;
      font-weight: 500;
      cursor: pointer;
      @media screen and (max-width: 1200px) {
        padding: 7px 20px;
        font-size: 14px;
        width: 120px;
      }
      @media screen and (min-width:900px) and (max-width:1200px) {
        width: 115px;
      }
      @media screen and (max-width: 900px) {
        width: 110px;
      }
      @media screen and (max-width: 860px) {
        padding: 7px 16px;
        gap: 8px;
      }
      p{
        font-size:14px;
      }
      span {
        font-size: 27px;
        max-height: 20px;
        line-height: 0em;
        position: relative;
        bottom: 3px;
        gap: 3px;
        @media screen and (max-width: 1200px) {
          font-size: 25px;
        }
      }
    }
  }
  .typeReservation {
    display: flex;
    align-items: center;
    background-color: #f3f3f3 ;
    border-radius: 4px;
    height: 30px;
    padding:2px;
    margin: 0px 108px;
    width: fit-content;
    &.desktop{
      @media screen and (max-width:1600px) {
        margin: 0px 0px;
      }
      @media screen and (max-width:1200px) {
        margin: unset;
        p{
          font-size: 12px;
        }
      }
      @media screen and (max-width:880px){
        display: none;
      }
    }
    &.onlyMobile{
      margin: auto;
      @media screen and (min-width:900px) {
        display: none;
      }
    }
    div {
      width:90px;
      cursor: pointer;
      background-color: #f3f3f3;
      color: white;
      color: black;
      display:flex;
      align-items:center;
      justify-content:center;
      height: 26px;
      p{
        font-size: 13px;
        color: #444B57 ;
      }
      &:first-child {
        position:relative;
        z-index:1;
        border-radius:4px;
        background-color: #dddddd43;
        &.active {
          background-color: white;
          z-index:2;
          box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.1);
        }
      }
      &:last-child {
        position:relative;
        z-index:1;
        border-radius:4px;
        background-color: #dddddd43;
        &.active {
          background-color: white;
          z-index:2;
          box-shadow: -3px 0px 2px rgba(0, 0, 0, 0.1);
        }
      }
      &:hover {
        background-color: white;
        font-weight: 500;
        color: #444B57 ;
      }
    }
    .active {
      background-color: white;
      font-weight:500;
      color: #444B57 ;
    }
  }
  .inputContainer {
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 30px;
    overflow: visible;
    @media screen and (max-width: 1200px) {
      max-width: 170px;
    }
    @media screen and (max-width: 900px) {
      min-width: 250px;
      max-width: 259px;
    }
    @media screen and (min-width:600px) and (max-width:1000px) {
      flex: 0.8;
    }
    @media screen and (max-width: 600px) {
      width: 90%;
      min-width: 315px;
      max-width: 345px;
    }
    form{
      flex: 1;
      max-width: fit-content;
      @media screen and (max-width: 900px) {
        max-width: 115px;
      }
    }
    .searcherContainer{
      width: 100%;
      height: 100%;
      border-radius: 6px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border: 1px solid #a8adb5;
      max-height: 30px;
      width: 100%;
      &.optionsOuts{
        border-bottom: unset;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
      }
    }
    .options{
      background-color: white;
      width: 100%;
      z-index: 11;
      border: 1px solid #a8adb5;
      border-top: unset;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      height: auto;
      max-height: 80px;
      .noCoincidences{
        flex:1;
        max-width: 100%;
        font-size: 10.3px;
        text-align: center;
        padding: 5px 0;
        font-size: 8.2px;
      }
      .optionsContainer{
        overflow-y: scroll;
        width: 100%;
        max-height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        div{
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 5px;
          align-items: center;
        }
        span {
          display: block;
          width: 25px;
          height: 25px;
          -webkit-mask-image: url(../../assets/icons/icon_search.svg);
          mask-image: url(../../assets/icons/icon_search.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          mask-repeat: no-repeat;
          mask-size: cover;
          mask-position: center;
          background: #444b578d;
        }
      }
    }
    span {
      min-width: 30px;
      min-height: 30px;
      -webkit-mask-image: url(../../assets/icons/icon_search.svg);
      mask-image: url(../../assets/icons/icon_search.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-size: cover;
      mask-position: center;
      background: #444B57;
    }
    input {
      height: 100%;
      flex: 1;
      outline: none;
      border: unset;
      background-color: transparent;
      font-size: 13px;
      @media screen and (min-width:800px) and (max-width:1200px) {
        max-width:85%
      }
      @media screen and (min-width:600px) and (max-width:900px) {
        max-width:82%
      }
    }
  }
  .showConventions {
    outline: unset;
    border: 2px solid #444b57;
    color: white;
    background: #444b57;
    border-radius: 8px;
    padding: 8px 20px;
    max-height: 30px;
    display: flex ;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
      display: none;
    }
    &:hover {
      background-color: white;
      border: solid 2px #444b57;
      color: #444b57;
    }
    &.active {
      background-color: white;
      border: solid 2px #444b57;
      color: #444b57;
      &:hover {
        color: white;
        background: #444b57;
      }
    }
  }
  .icons {
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    display: flex;
    cursor: help;
    &.nextReservation {
      margin-left: 3.5px;
      margin-right: 9px;
      @include icon("../../assets/icons/allTimeLineIcons/nextReservation.svg");
    }
    &.sittingReservation {
      margin-left: 3.5px;
      margin-right: 9px;
      @include icon("../../assets/icons/allTimeLineIcons/sittingReservation.svg");
    }
    &.lateReservation {
      margin-left: 3.5px;
      margin-right: 9px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      border: 2px solid #FF8A00;
    }
    &.sobreHoraReservation {
      margin-left: 3.5px;
      margin-right: 9px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      border: 2px solid #FFD43B;
    }
    &.precompra {
      margin-left: 3.5px;
      margin-right: 9px;
      @include icon("../../assets/icons/allTimeLineIcons/precompraSit.svg");
    }
    &.walkSit {
      margin-left: 3.5px;
      margin-right: 9px;
      @include icon("../../assets/icons/allTimeLineIcons/walkSit.svg");
    }
    &.warning {
      @include icon(
        "../../assets/icons/allTimeLineIcons/warningReservation.svg",
        28px,
        20px
      );
    }
    &.key {
      @include icon("../../assets/icons/allTimeLineIcons/key.svg", 28px, 20px);
    }
    &.blocked {
      @include icon(
        "../../assets/icons/allTimeLineIcons/blocked.svg",
        28px,
        20px
      );
    }
    &.hoursBlocked {
      @include icon(
        "../../assets/icons/allTimeLineIcons/hoursBlocked.svg",
        28px,
        20px
      );
    }
    &.redWarning {
      @include icon(
        "../../assets/icons/allTimeLineIcons/warningRed.svg",
        28px,
        20px
      );
    }
    &.bell {
      @include icon("../../assets/icons/allTimeLineIcons/bell.svg", 28px, 20px);
    }
  }
  .item-dd.info {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content:flex-start;
  }
  .item-dd.info {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content:flex-start;
    width: 88%;
    margin: auto;
  }
  .imprimir {
    cursor: pointer;
    @include icon("../../assets/icons/allTimeLineIcons/imprimir.svg", 24px, 24px);
  }
  .GenerateReport {
    position: relative;
    z-index: 11;
    @media screen and (max-width: 1100px) {
      display: none;
    }
    .reportContainer {
      position: absolute;
      top: 130%;
      right: 0;
      background-color: white;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
      &:after {
        content: " ";
        display: block;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        position: absolute;
        top: -5px;
        right: 10%;
        background-color: white;
      }
    }
  }
  .dropdown + .dropdown{
    margin: unset;
  }
  .showConventionsSmallDevices {
    width: 30px;
    height: 30px;
    border: solid 1px #606773;
    border-radius: 5px;
    padding: 5px;
    @media screen and (min-width: 1100px) {
      display: none;
    }
    img {
      height: 25px;
      width: 25px;
      transform: translateY(-3px);
      cursor: pointer;
    }
    .titleContionsMobile {
      padding-top: 13px;
      padding-bottom: 6px;
      header {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        color: #a8adb5;
        h3 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 11px;
          color: #444b57;
        }
      }
      span {
        display: block;
        height: 1px;
        width: 20%;
        background-color: #a8adb5;
      }
    }
    .GenerateReportMobile{
      min-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .separatorForReportMobile{
        width: 90%;
        border-top: 1px solid #A8ADB5;
        display: block;
        height: 15px;
      }
      .buttonDownload{
        color: white;
        background-color:#444B57;
        padding: 2px 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 30px;
        margin-bottom: 1px;
        span{
          display: block;
          height: 14px;
          width: 14px;
          mask: url('../../assets/icons/allTimeLineIcons/imprimir.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          mask-repeat: no-repeat;
          mask-size: cover;
          mask-position: center;
          background-color: white;
        }
      }
    }
  }
  .divisor{
    width: 2px;
    height: 30px;
    display: block;
    background-color: black;
    border-radius: 30%;
  }
  .onlyTablet{
    display: none;
  }
  .notMobile{
    display: block;
    @media screen and (max-width:900px) {
      display: none;
    }
  }
  .conv_btn{
    font-weight: 500;
  }
}
.configuraciones{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  width: 100%;
  text-align: center;
  color: #444B57;
  padding-bottom: 8px;
  padding-top: 15px;
}
.icon_close{
  position: absolute;
  background-color: black;
  mask-position: center;
  mask-size: contain;
  width: 18px;
  height: 18px;
  right:9px;
  top: 9px;
  background: #444B57;
}
.dropdown-menu{
  transform:translateY(5px) ;
}
@media screen and (max-width:1100px) {
  .dropdown-menu{
    transform:translateY(-40px) ;
  }
}
</style>
