<template>
  <div
    id="TablesContainer"
    ref="TablesContainer"
    :class="{
      IsmodalOpenFromMap: modalOpenFromMap,
      IsmodalOpen: this.activeModal !== '',
    }"
    :style="{
      '--scrollTop': lastScrollY + 'px',
      '--positionTopModal': positionTopModal + 'px',
    }"
  >

            <portal selector="#mainContainer">
                    <section
                    v-click-outside="()=>out()"
        id="modalForTable"
        class="modalForTable"
        :class="{
          modalOnTop: modalPositionOnTop,
          middlePosition: middlePosition,
          bottomPosition: bottomPosition,
          showBlockHours: showBlockHours,
        }"
        v-if="activeModal"
      >
        <header>
          <p>{{ textFormatter(tableForModal.displayName, 16) }}</p>
          <div class="sittingSpace">
            <p>{{ tableForModal.maxSize }}</p>
            <span class="iconChair"></span>
          </div>
        </header>
        <main>
          <div class="buttonsContainer">
            <div class="availabilityContainer">
              <button
                class="iconButtons lock"
                :class="{ active: showBlockHours }"
                @click="() => (showBlockHours = !showBlockHours)"
              ></button>
              <button
                class="iconButtons key"
                :class="{
                  desactivatedPrivated: tableForModal.preventReservations === 0,
                }"
                @click="
                  keyClicked(
                    tableForModal,
                    selectedindexZone,
                    selectedtableIndex,
                    selectedindexSubZone
                  )
                "
              ></button>
            </div>
            <div class="reservationContainer">
              <button
                class="iconButtons plus"
                @click.prevent.stop="
                  viewReservation(
                    tablesInZones[selectedindexZone].id,
                    tableForModal.displayName,
                    tableForModal.id
                  )
                "
              ></button>
              <button
                class="iconButtons plusWalk"
                @click.prevent.stop="
                  viewWalkIn(
                    tablesInZones[selectedindexZone].id,
                    tableForModal.displayName,
                    tableForModal.id
                  )
                "
              ></button>
            </div>
          </div>
          <section class="blockHoursContainer" v-show="showBlockHours">
            <h1>Bloquear mesa/hora</h1>
            <div class="blockContainer">
              <section class="block" v-if="amHours.length > 0">
                <h2>AM</h2>
                <div class="HourContainer">
                  <div
                    v-for="(infoHours, keyHourBloker) in amHours"
                    :key="'keyHour-' + keyHourBloker + '.4'"
                    @click="
                      () =>
                        checkHourIfBlocked(
                          tableForModal,
                          infoHours.militaryFormat
                        )
                          ? desbloquear(
                              tableForModal.id,
                              tablesInZones[selectedindexZone].id,
                              infoHours.militaryFormat,
                              selectedindexZone,
                              selectedtableIndex
                            )
                          : bloquear(
                              tableForModal.id,
                              tablesInZones[selectedindexZone].id,
                              infoHours.militaryFormat,
                              selectedindexZone,
                              selectedtableIndex,
                              selectedindexSubZone
                            )
                    "
                  >
                    <p
                      :class="{
                        blocked: checkHourIfBlocked(
                          tableForModal,
                          infoHours.militaryFormat
                        ),
                      }"
                    >
                      {{ infoHours.formatedHour.replaceAll(":am", "") }}
                    </p>
                  </div>
                </div>
              </section>
              <section class="block" v-if="pmHours.length > 0">
                <h2>PM</h2>
                <div class="HourContainer">
                  <div
                    v-for="(infoHours, keyHourBloker) in pmHours"
                    :key="'keyHour2-' + keyHourBloker + '.4'"
                    @click="
                      () =>
                        checkHourIfBlocked(
                          tableForModal,
                          infoHours.militaryFormat
                        )
                          ? desbloquear(
                              tableForModal.id,
                              tablesInZones[selectedindexZone].id,
                              infoHours.militaryFormat,
                              selectedindexZone,
                              selectedtableIndex,
                              selectedindexSubZone
                            )
                          : bloquear(
                              tableForModal.id,
                              tablesInZones[selectedindexZone].id,
                              infoHours.militaryFormat,
                              selectedindexZone,
                              selectedtableIndex,
                              selectedindexSubZone
                            )
                    "
                  >
                    <p
                      :class="{
                        blocked: checkHourIfBlocked(
                          tableForModal,
                          infoHours.militaryFormat
                        ),
                      }"
                    >
                      {{ infoHours.formatedHour.replaceAll(":pm", "") }}
                    </p>
                  </div>
                </div>
              </section>
            </div>

            <div class="blockAllContainer">
              <button
                class="blockAllButton"
                @click="
                  () =>
                    allBlocked(tableForModal)
                      ? desbloquearTable(
                          tableForModal.id,
                          tablesInZones[selectedindexZone].id,
                          selectedindexZone,
                          selectedtableIndex,
                          selectedindexSubZone
                        )
                      : bloquearTable(
                          tableForModal.id,
                          tablesInZones[selectedindexZone].id,
                          selectedindexZone,
                          selectedtableIndex,
                          selectedindexSubZone
                        )
                "
                :class="{ allBlocked: !allBlocked(tableForModal) }"
              >
                <span class="unlockIcon"></span>
                <p>
                  {{
                    allBlocked(tableForModal) ? "Desbloquear" : " Bloquear"
                  }}
                  mesa
                </p>
              </button>
            </div>
          </section>
        </main>
      </section>
              </portal>
                          <portal selector="#space">
    <header class="headerTitle" id="headerTitleFromTables">
      <h2>Mesas</h2>
    </header>
    </portal>
    <!-- <section class="headerSpace"></section> -->
    <div
      v-for="(zone, indexZone) in tablesInZones"
      :key="'zone5-' + zone.id"
      class="fatherContainer"
      :class="{ selected: zone.id === activeZone }"
    >
      <div
        class="zoneContainer"
        :class="{
          zoneHide: hideSubZones.includes(indexZone),
          showZone: !hideSubZones.includes(indexZone),
        }"
      >
        <label class="zoneTitle" :for="zone + indexZone">
          <div class="zoneTitleElements">
                      <input
            type="checkbox"
            :value="indexZone"
            v-model="hideSubZones"
            :id="zone + indexZone"
          />
          <p>
                      {{
              zone.displayName,
          }}
          </p>

          <span class="arrowIcon"></span>
          </div>

        </label>
        <div class="tablesContainer">
          <div
            class="subZoneContainer"
            v-for="(subzone, indexSubZone) in zone.subZones"
            :key="'subZone3-' + subzone.id"
          >
            <article class="subZoneTitle">
              <div class="subZoneTitleElements">
                              <p>
                              {{
                  subzone.title,

              }}
              </p>
              </div>

            </article>
            <div class="containerFromSubzoneTables">
              <div
                :id="table.id"
                v-for="(table, tableIndex) in subzone.tables"
                :key="'table4-' + table.id"
                class="tableContainer"
                ref="table"
                :class="{ isTableSelected: activeModal === table.id }"
              >
                <div class="iconName">
                  <div
                    class="displayNameIcon"
                    :class="{
                      blackKey: table.preventReservations === 1,
                      lockRed: allBlocked(table),
                      busyRed: someBlocked(table),
                    }"
                  ></div>
                  <p>{{ textFormatterTitle(table.displayName) }}</p>
                </div>
                <button
                  class="plusForModal"
                  @click="openModal(table, indexZone, tableIndex, indexSubZone)"
                >
                  <p class="buttonPlus">+</p>
                </button>
              </div>
            </div>
          </div>
          <div
            :id="table.id"
            class="tableContainer"
            v-for="(table, indexTable) in zone.tables"
            :key="'table5-' + table.id"
            ref="table"
            :class="{ isTableSelected: activeModal === table.id }"
          >
            <div class="iconName">
              <div
                class="displayNameIcon"
                :class="{
                  blackKey: table.preventReservations === 1,
                  lockRed: allBlocked(table),
                  busyRed: someBlocked(table),
                }"
              ></div>
              <p>{{ textFormatterTitle(table.displayName) }}</p>
            </div>
            <button
              class="plusForModal"
              @click="openModal(table, indexZone, indexTable, indexSubZone)"
            >
              <p class="buttonPlus">+</p>
            </button>
          </div>
        </div>
      </div>
      <div v-show="hideSubZones.includes(indexZone)" class="hideTitle">
        <label class="zoneTitle" :for="zone + indexZone">

          <input
            type="checkbox"
            :value="indexZone"
            v-model="hideSubZones"
            :id="zone + indexZone"
          />
          <p>
            {{
              textFormatter(
                zone.displayName,
                setMaxLetterSizeByTableQuantity(
                  zone.tables ? zone.tables.length : 2
                )
              )
            }}
          </p>

          <span>∟</span>
        </label>
      </div>
    </div>
    <div class="fatherContainerExtra" ref="fatherContainerExtra"></div>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import { Portal as portal } from '@linusborg/vue-simple-portal';
export default {
  props: ['zones', 'modalOpenFromMap', 'lastScroll', 'lastScrollY', 'clickMapSwitcher'],
  components: {
    portal
  },
  data () {
    return {
      tablesInfo: [],
      activeModal: '',
      amHours: [],
      pmHours: [],
      showBlockHours: false,
      modalPositionOnTop: false,
      middlePosition: false,
      bottomPosition: false,
      hideSubZones: [],
      tablesOrderByZoneInfo: [],
      tablesInZones: {},
      blockedTemplate: [],
      componentInitialized: false,
      blockedHoursModify: false,
      activeZone: false,
      tableForModal: '',
      selectedtableIndex: '',
      selectedindexSubZone: '',
      selectedindexZone: '',
      positionTopModal: 0,
      scrollOnModalOpen: 0,
      extraRows: 0,
      extraRowsObject: {}
    };
  },
  mounted () {
    const resizeObserver = new ResizeObserver((e) => {
      if (this.$refs.fatherContainerExtra) {
        const heightExtra = e[0].contentRect.height;
        if (heightExtra > 8) {
          const size = window.innerWidth < 600 ? 35 : 50;
          const ExtraSquares = Math.ceil(heightExtra / size);
          const ExtraSemiSquareSize = heightExtra % size;
          let squaresHTML = '';
          this.extraRows = ExtraSquares;
          for (let index = 0; index < ExtraSquares; index++) {
            squaresHTML += '<div style="background-color: white;"></div>';
          }
          const extraRows = [];
          for (let index = 0; index < this.extraRows; index++) {
            if (index === this.extraRows - 1) {
              extraRows.push({ height: ExtraSemiSquareSize, id: index, type: 'extraSquare' });
            } else {
              extraRows.push({ height: size, id: index, type: 'extraSquare' });
            }
          }
          if (this.tablesOrderByZoneInfo[this.tablesOrderByZoneInfo.length - 1]) {
            if (this.tablesOrderByZoneInfo[this.tablesOrderByZoneInfo.length - 1][0].type === 'extraSquare') {
              this.tablesOrderByZoneInfo.pop();
            }
          }

          this.extraRowsObject = extraRows;
          this.$emit('extraRows', this.extraRowsObject);
          this.$refs.fatherContainerExtra.innerHTML = squaresHTML;
        }
      }
    });
    resizeObserver.observe(this.$refs.fatherContainerExtra);
    this.formatHoursInAMAndPm();
  },
  updated () {
    if (
      this.$refs.table &&
      (this.blockedHoursModify || !this.componentInitialized)
    ) {
      for (let index = 0; index < this.tablesInfo.length; index++) {
        const tableComputedHeight = this.$refs.table[index].clientHeight;
        if (tableComputedHeight) {
          this.tablesInfo[index].height = tableComputedHeight + 2;
        }
      }
      this.blockedHoursModify = false;
      this.componentInitialized = true;
      this.$emit('tablesInfo', this.tablesInfo);
      this.$emit('tablesOrderByZoneInfo', this.tablesOrderByZoneInfo);
    }
  },
  methods: {
    out () {
      this.activeModal = '';
    },
    viewWalkIn (section, mesa, id) {
      this.$store.commit(
        reservationTypes.mutations.setSectionSelected,
        section
      );
      this.$store.commit(reservationTypes.mutations.setTableSelected, mesa);
      this.$store.commit(reservationTypes.mutations.setTableSelectedId, id);
      this.$store.commit(reservationTypes.mutations.setFromMap, true);
      this.setAddWalkin();
    },
    viewReservation (section, mesa, id) {
      this.$store.commit(
        reservationTypes.mutations.setSectionSelected,
        section
      );
      this.$store.commit(reservationTypes.mutations.setTableSelected, mesa);
      this.$store.commit(reservationTypes.mutations.setTableSelectedId, id);
      this.$store.commit(reservationTypes.mutations.setFromMap, true);
      this.setAddReservation();
    },
    tableBlocked (id, index, indexZone, indexSubzone) {
      this.$store
        .dispatch({
          type: 'reservation:setBlockedTableSection',
          data: {
            seatingTableId: id,
            vendorId: this.idVendor
          }
        })
        .then(() => {
          if (indexSubzone || indexSubzone === 0) {
            this.$set(
              this.tablesInZones[indexZone].subZones[indexSubzone].tables[
                index
              ],
              'preventReservations',
              1
            );
          } else {
            this.$set(
              this.tablesInZones[indexZone].tables[index],
              'preventReservations',
              1
            );
          }
          this.blockedHoursModify = true;
        });
    },
    unTableBlocked (id, index, indexZone, indexSubzone) {
      this.unblockingTable = true;
      this.$store
        .dispatch({
          type: 'reservation:setUnBlockedTableSection',
          data: {
            seatingTableId: id,
            vendorId: this.idVendor
          }
        })
        .then(() => {
          if (indexSubzone || indexSubzone === 0) {
            this.$set(
              this.tablesInZones[indexZone].subZones[indexSubzone].tables[
                index
              ],
              'preventReservations',
              0
            );
          } else {
            this.$set(
              this.tablesInZones[indexZone].tables[index],
              'preventReservations',
              0
            );
          }
          this.blockedHoursModify = true;
        });
    },
    convertHour (hour) {
      const hor = hour.split(':');
      const hora = hor[0] >= 9 ? '0' + hor[0] : hor[0];
      const minuto = hor[1];
      const hoy = this.$moment(this.dateComplete)
        .hours(parseInt(hora))
        .minutes(parseInt(minuto))
        .format('YYYY-MM-DD HH:mm:ss');
      return hoy;
    },
    formatHoursInAMAndPm () {
      const Hours = this.timesDashboard[this.getDayOfWeek];

      Hours.forEach((element) => {
        const arrayOfTime = element.hora.split(':');
        const date = new Date(this.dateComplete).setHours(
          parseInt(arrayOfTime[0]),
          parseInt(arrayOfTime[1]),
          0,
          0
        );
        this.blockedTemplate.push({ timestamp: date + '', isBlocked: 1 });
        const hour = this.$moment(this.convertHour(element.hora)).format('hh:mm:a');
        const militaryFormat = this.$moment(this.convertHour(element.hora)).format(
          'H:mm:a'
        );
        if (hour.includes('am')) {
          this.amHours = [
            ...this.amHours,
            { ...element, formatedHour: hour, militaryFormat }
          ];
        } else {
          this.pmHours = [
            ...this.pmHours,
            { ...element, formatedHour: hour, militaryFormat }
          ];
        }
      });
    },
    bloquearTable (table, section, indexZone, index, indexSubzone) {
      this.$store
        .dispatch({
          type: 'reservation:blockedDayTable',
          data: {
            seatingTableId: table,
            vendorId: this.idVendor,
            seatingSectionId: section,
            date: this.selectedDate,
            userId: this.user.id
          }
        })
        .then(() => {
          if (indexSubzone || indexSubzone === 0) {
            this.$set(
              this.tablesInZones[indexZone].subZones[indexSubzone].tables[
                index
              ],
              'blocked',
              [...this.blockedTemplate]
            );
          } else {
            this.$set(this.tablesInZones[indexZone].tables[index], 'blocked', [
              ...this.blockedTemplate
            ]);
          }
          this.blockedHoursModify = true;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    desbloquearTable (table, section, indexZone, index, indexSubzone) {
      this.$store
        .dispatch({
          type: 'reservation:unBlockedDayTable',
          data: {
            seatingTableId: table,
            vendorId: this.idVendor,
            seatingSectionId: section,
            date: this.selectedDate,
            userId: this.user.id
          }
        })
        .then(() => {
          if (indexSubzone || indexSubzone === 0) {
            this.$set(
              this.tablesInZones[indexZone].subZones[indexSubzone].tables[
                index
              ],
              'blocked',
              []
            );
          } else {
            this.$set(
              this.tablesInZones[indexZone].tables[index],
              'blocked',
              []
            );
          }
          this.blockedHoursModify = true;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    checkHourIfBlocked (table, hourSelected) {
      const $moment = this.$moment;
      let isBlocked = false;
      for (let index = 0; index < table.blocked.length; index++) {
        const hourBlocked = $moment(
          parseInt(table.blocked[index].timestamp)
        ).format('HH:mm:a');
        if (hourBlocked === hourSelected) {
          isBlocked = true;
          break;
        }
      }
      return isBlocked;
    },
    desbloquear (table, section, timestamp, indexZone, index, indexSubzone) {
      const arrayOfTime = timestamp.split(':');

      const date = new Date(this.dateComplete).setHours(
        parseInt(arrayOfTime[0]),
        parseInt(arrayOfTime[1]),
        0,
        0
      );
      const formattedDate = this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
      this.$store
        .dispatch({
          type: 'reservation:unBlockedHourTable',
          data: {
            seatingTableId: table,
            vendorId: this.idVendor,
            seatingSectionId: section,
            date: formattedDate,
            userId: this.user.id
          }
        })
        .then(() => {
          if (indexSubzone || indexSubzone === 0) {
            const helper = [
              ...this.tablesInZones[indexZone].subZones[indexSubzone].tables[
                index
              ].blocked
            ];
            const indexF = helper.findIndex(
              (hourBlock) => hourBlock.timestamp === date + ''
            );
            helper.splice(indexF, 1);
            this.$set(
              this.tablesInZones[indexZone].subZones[indexSubzone].tables[
                index
              ],
              'blocked',
              helper
            );
          } else {
            const helper = [
              ...this.tablesInZones[indexZone].tables[index].blocked
            ];
            const indexF = helper.findIndex(
              (hourBlock) => hourBlock.timestamp === date + ''
            );
            helper.splice(indexF, 1);
            this.$set(
              this.tablesInZones[indexZone].tables[index],
              'blocked',
              helper
            );
          }
          this.blockedHoursModify = true;
          this.$emit('blockedHours');
        })
        .catch(() => {});
    },
    bloquear (table, section, timestamp, indexZone, index, indexSubzone) {
      const arrayOfTime = timestamp.split(':');
      const date = new Date(this.dateComplete).setHours(
        parseInt(arrayOfTime[0]),
        parseInt(arrayOfTime[1]),
        0,
        0
      );
      const formattedDate = this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
      this.$store
        .dispatch({
          type: 'reservation:blockedHourTable',
          data: {
            seatingTableId: table,
            vendorId: this.idVendor,
            seatingSectionId: section,
            date: formattedDate,
            userId: this.user.id
          }
        })
        .then(() => {
          if (indexSubzone || indexSubzone === 0) {
            const helper = [
              ...this.tablesInZones[indexZone].subZones[indexSubzone].tables[
                index
              ].blocked
            ];
            helper.push({ timestamp: date + '', isBlocked: 1 });
            this.$set(
              this.tablesInZones[indexZone].subZones[indexSubzone].tables[
                index
              ],
              'blocked',
              helper
            );
          } else {
            const helper = [
              ...this.tablesInZones[indexZone].tables[index].blocked
            ];
            helper.push({ timestamp: date + '', isBlocked: 1 });
            this.$set(
              this.tablesInZones[indexZone].tables[index],
              'blocked',
              helper
            );
          }
          this.blockedHoursModify = true;
        })
        .catch(() => {});
      this.$emit('blockedHours');
    },
    allBlocked (table) {
      return table.blocked.length === [...this.amHours, ...this.pmHours].length;
    },
    someBlocked (table) {
      return table.blocked.length > 0 && !this.allBlocked(table);
    },
    textFormatter (word, maxSize) {
      if (word.length > maxSize) {
        return word.slice(0, maxSize) + '';
      }
      return word;
    },
    textFormatterTitle (word) {
      if (window.innerWidth <= 600 && word.length > 4) {
        return word.slice(0, 5) + '..';
      } else if (word.length > 7) {
        return word.slice(0, 7) + '...';
      }
      return word;
    },
    setMaxLetterSizeByTableQuantity (tablesQuantity) {
      if (tablesQuantity > 2) {
        return tablesQuantity * 3;
      } else {
        return 7;
      }
    },
    openModal (table, indexZone, indexTable, indexSubZone) {
      const tableElement = document.getElementById(table.id);
      document.getElementById('Cronology').style.setProperty('--lastScrollY', document.getElementById('mainContainer').scrollTop + 'px');
      this.positionTopModal = tableElement.getBoundingClientRect().top;
      document
        .getElementById('Cronology')
        .style.setProperty('--positionTopModal', this.positionTopModal + 'px');
      this.selectedindexZone = indexZone;
      this.selectedtableIndex = indexTable;
      this.selectedindexSubZone = indexSubZone;
      this.tableForModal = table;
      if (this.activeModal === '') {
        this.activeZone = table.seatingSectionId;
        this.activeModal = table.id;
        const tableQuantity = this.tablesInfo.length;
        let indexOfTables = 0;
        for (let index = 0; index < this.tablesInfo.length; index++) {
          if (this.tablesInfo[index].id === table.id) {
            indexOfTables = index;
            break;
          }
        }
        if (indexOfTables <= Math.round((tableQuantity + 1) / 3)) {
          this.modalPositionOnTop = true;
          this.middlePosition = false;
          this.bottomPosition = false;
        } else if (indexOfTables <= Math.round((tableQuantity + 1) / 3)) {
          this.modalPositionOnTop = false;
          this.middlePosition = true;
          this.bottomPosition = false;
        } else {
          this.middlePosition = false;
          this.modalPositionOnTop = false;
          this.bottomPosition = true;
        }
      } else {
        this.activeModal = '';
      }
    },
    keyClicked (table, indexZone, indexTable, indexSubzone) {
      table.preventReservations
        ? this.unTableBlocked(table.id, indexTable, indexZone, indexSubzone)
        : this.tableBlocked(table.id, indexTable, indexZone, indexSubzone);
    }
  },
  watch: {
    clickMapSwitcher () {
      this.activeModal = '';
    },
    zones () {
      this.hideSubZones = [];
      this.componentInitialized = false;
      this.tablesInZones = [...this.zones];
    },
    activeModal () {
      for (let index = 0; index < this.tablesInfo.length; index++) {
        // this.tablesInfo[index].isActiveModal = false;
        this.$set(this.tablesInfo[index], 'isActiveModal', false);
      }
      if (this.tablesInfo.length > 0) {
        for (let index = 0; index < this.tablesInfo.length; index++) {
          if (this.tablesInfo[index].id === this.activeModal) {
            this.$set(this.tablesInfo[index], 'isActiveModal', true);
            break;
          }
        }
        this.$emit('tablesInfo', this.tablesInfo);
        this.$emit('tableSelected', this.activeModal);
      }
    },
    hideSubZones (value) {
      if (this.tablesInZones.length === value.length) {
        this.hideSubZones.pop();
      }
      this.$emit('hideZone', this.hideSubZones);
    },
    tablesInZones: {
      handler (value) {
        let tables = [];
        const tablesForZone = [];

        value.forEach((zona) => {
          let tablesInSubZones = [];
          let tablesInZone = [];
          if (zona.tables) {
            tablesInZone = [...zona.tables];
            tablesInZone = tablesInZone.map((element) => {
              const tableInfo = { ...element };
              tableInfo.zone = zona.displayName;
              tableInfo.zoneId = zona.id;
              return tableInfo;
            });
          }
          if (zona.subZones) {
            zona.subZones.forEach((subzone) => {
              if (subzone.tables) {
                tablesInSubZones = [...tablesInSubZones, ...subzone.tables].map(
                  (element) => {
                    const tableInfo = { ...element };
                    tableInfo.subZoneId = subzone.id;
                    return tableInfo;
                  }
                );
              }
            });
            tablesInSubZones = tablesInSubZones.map((element) => {
              const tableInfo = { ...element };
              tableInfo.zone = zona.displayName;
              tableInfo.zoneId = zona.id;
              return tableInfo;
            });
          }
          tablesForZone.push([...tablesInSubZones, ...tablesInZone]);
          this.tablesOrderByZoneInfo = tablesForZone;
          tables = [...tables, ...tablesInSubZones, ...tablesInZone];
        });
        this.tablesInfo = tables;
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
  .headerTitle {
    display:flex;
    align-items: center;
    width:155px;
    z-index: 5200;
    background-color: white;
    border-bottom: 1px solid #f3f3f3;
    height: 27px;
    @media screen and (max-width:600px){
      width: 105px;
    }
    @media screen and (max-width:600px) {
      height: 22px;
    }
  }
  header {
    will-change: padding-top;
    pointer-events: none;
    h2 {
      text-align: center;
      height: fit-content;
      background-color: white;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #444b57;
      width: 100%;
      @media screen and (max-width:600px) {
        font-size: 12px;
      }
    }
    .sittingSpace {
      display: flex;
      align-items: center;
      padding-right: 8px;
    }
    .iconChair {
      display: block;
      width: 28px;
      height: 28px;
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../../assets/icons/allTimeLineIcons/chair.svg);
      background-color: transparent;
    }
  }
  #modalForTable {
    will-change: transform;
    position: fixed;
    width: 250px;
    margin: auto;
    z-index: 1000;
    top: calc(var(--positionTopModal) - 86px);
    left: 190px;
    right: unset;
    bottom: unset;
    background-color: white;
    min-width: 250px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.30);
    border-radius: 6px;
    .desactivatedPrivated {
      opacity: 0.6;
    }
    button {
      border: unset;
    }
    @media screen and (min-width: 1100px) {
      top: calc(var(--positionTopModal) - 46px);
    }
    @media screen and (max-width: 600px) {
      top: calc(var(--positionTopModal) - 105px);
      left: 72px;
      right: 0;
      margin: auto;
      min-width: 65%;
      right: 0;
      margin: auto;
      p {
        max-width: unset;
      }
    }
    &::before {
      content: " ";
      display: block;
      width: 15px;
      height: 15px;
      background: #444b57;
      position: absolute;
      left: -7px;
      top: 10px;
      transform: rotate(45deg);
      border-radius: 3px;
      top: unset;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: white;
    }
    &.modalOnTop {
      margin: unset;
      &::before {
        content: " ";
        display: block;
        width: 15px;
        height: 15px;
        background: #444b57;
        position: absolute;
        left: -7px;
        top: 10px;
        transform: rotate(45deg);
        border-radius: 3px;
        bottom: unset;
        @media screen and (max-width: 600px) {
          top: -5px;
          left: 16px;
        }
      }
    }
    &.bottomPosition {
      top: calc(var(--positionTopModal) - 95px);
      @media screen and (min-width: 1100px) {
        top: calc(var(--positionTopModal) - 80px);
      }
      @media screen and (max-width:600px) {
        margin: unset;
        top: calc(var(--positionTopModal) - 240px);
      }
      &::before {
        @media screen and (max-width: 600px) {
          top: calc(var(--positionTopModal) - 245px);
          left: 15px;
          bottom: -5px;
          top: unset;
          left: 17px;
          right: unset;
          background: white;
        }
      }
      &.showBlockHours {
        top: calc(var(--positionTopModal) - 350px);
        @media screen and (min-width: 1100px) {
          top: calc(var(--positionTopModal) - 280px);
        }
        @media screen and (max-width: 600px) {
          top: calc(var(--positionTopModal) - 420px);
        }
        &::before {
          @media screen and (min-width: 600px) {
            content: " ";
            display: block;
            width: 15px;
            height: 15px;
            background: white;
            position: absolute;
            top: unset;
            left: -7px;
            bottom: 15px;
            transform: rotate(45deg);
            border-radius: 3px;
          }
        }
      }
    }
    &.middlePosition {
      margin: auto;
      top: calc(var(--positionTopModal) - 110px);
      @media screen and (min-width: 1100px) {
        top: calc(var(--positionTopModal) - 85px);
      }
      @media screen and (max-width: 600px) {
        left: 15px;
        top: calc(var(--positionTopModal) - 90px);
      }
      &::before {
        bottom: unset;
        top: 45px;
        @media screen and (max-width: 600px) {
          top: -5px;
          left: 17px;
          bottom: unset;
          right: unset;
          background: #444b57;
        }
      }
    }
    header {
      background: #444b57;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      p {
        color: white;
        padding-left: 12px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        @media screen and (max-width:600px) {
          font-size: 15px;
        }
      }
      .sittingSpace{
        p{
          font-size: 17px;
        }
        @media screen and (max-width:600px) {
          font-size: 16px;
        }
      }
    }
    main {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: white;
      min-height: 50px;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      @media screen and (max-width:600px) {
        padding: 12px 0;
      }
      .buttonsContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 0 12px;
        .reservationContainer {
          display: flex;
          justify-content: space-between;
          width: 32.5%;
          @media screen and (max-width:600px) {
            width:29%;
          }
        }
        .availabilityContainer {
          display: flex;
          justify-content: space-between;
          width: 32.5%;
          @media screen and (max-width:600px) {
            width:29%;
          }
        }
      }
      .iconButtons {
        cursor: pointer;
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        padding: 15px;
        &.lock {
          background-image: url(../../assets/icons/allTimeLineIcons/lock.svg);
          background-color: #ff0101;
          &.active {
            background-image: url(../../assets/icons/allTimeLineIcons/cerrar.svg);
            background-color: #afb1b2;
            background-size: unset;
          }
        }
        &.key {
          background-size: 50%;
          background-image: url(../../assets/icons/allTimeLineIcons/keyBlack.svg);
          background-color: #afb1b2;
        }
        &.plus {
          background-size: 40%;
          background-image: url(../../assets/icons/allTimeLineIcons/plus.svg);
          background-color: #00adc6;
        }
        &.plusWalk {
          background-size: 40%;
          background-image: url(../../assets/icons/allTimeLineIcons/plus.svg);
          background-color: #8a51b4;
        }
      }
      .blockHoursContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #a8adb512;
        width: 96%;
        margin: auto;
        h1 {
          background-color: white;
          width: 100%;
          text-align: center;
          padding: 8px 0;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }
        .block {
          padding: 5px 0;
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          margin-bottom: unset;
          h2 {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #444b57;
            padding: unset;
            width: fit-content;
          }
          .HourContainer {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            width: 80%;
            gap: 2px;
            padding: 2px;
            div {
              background-color: white;
              text-align: center;
              cursor: pointer;
              p {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #444b57;
                &.blocked {
                  color: red;
                  background-color: #FBEEEE;
                }
              }
            }
          }
        }
        .blockAllContainer {
          background-color: white;
          width: 100%;
          display: flex;
          justify-content: center;
          padding-top: 10px;
        }
        .blockAllButton {
          background: #54d401;
          border-radius: 6px;
          outline: unset;
          border: unset;
          padding: 8px 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          max-height: 30px;
          gap: 8px;
          min-width: fit-content;
          &.allBlocked {
            background-color: red;
            .unlockIcon {
              display: block;
              width: 14px;
              height: 14px;
              background-size:contain;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url(../../assets/icons/allTimeLineIcons/lock.svg);
              background-color: transparent;
            }
          }
          .unlockIcon {
            display: block;
            width: 14px;
            height: 14px;
            background-size:contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../../assets/icons/allTimeLineIcons/desbloqueado.svg);
            background-color: transparent;
          }
          p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            transform:translateX(-1px);
            color: white;
          }
        }
      }
    }
  }
  #TablesContainer {
    display: flex;
    flex-direction: column;
    overflow-x: visible;
    position: relative;
    --scrollTop: 0;
    min-width: 155px;
    max-width: 155px;
    background-color: white;
    height: 100%;
    box-shadow: -6px -6px 15px 1px rgba(0, 0, 0, 0.381);
    z-index: 14;
    @media screen and (max-width: 600px) {
      min-width: 105px;
      max-width: 105px;
    }
    button {
      border: unset;
    }
    .zoneContainer {
      width: 100%;
      display: flex;
      height: auto;
      background-color: white;
      @media screen and (max-width: 600px) {
        min-width: 100%;
        max-width: 100%;
      }
      .zoneTitle {
        cursor: pointer;
        display: block;
        width: fit-content;
        border-radius: 5px;
        writing-mode: vertical-lr;
        text-align: center;
        transform: rotate(180deg);
        min-height: 100px;
        position: relative;
        font-size: 13px;
        max-width: 16px;
        padding: 1px 0;
        background-color: white;
        @media screen and (max-width:600px) {
          font-size: 11px;
          max-width: 14px;
        }
        input {
          position: absolute;
          visibility: hidden;
        }
        .zoneTitleElements{
          width: 100%;
          color: white;
          background: #444b57;
          padding-top: 30px;
          padding-bottom: 10px;
          border-radius: 5px;
        }
        p{
          font-size: 12.3px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span {
          content: " ";
          display: block;
          color: white;
          position: absolute;
          margin: auto;
          top: 10px;
          left: 0;
          right: 0;
          margin: auto;
          font-size: 13px;
          @media screen and (max-width:600px) {
            font-size: 11px;
          }
        }
      }
      .subZoneContainer {
        display: flex;
        height: auto;
        background-color: white;
        flex: 1;
        max-width: 100%;
        @media screen and (max-width:800px) {
          min-height: fit-content;
        }
        .subZoneTitle {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          padding: 1px 0;
          writing-mode: vertical-lr;
          text-align: center;
          transform: rotate(180deg);
          min-height: 100px;
          position: relative;
          width: 16px;
          .subZoneTitleElements{
            color: white;
            background: #d5d5d5;
            height: 100%;
            width: 100%;
            border-radius: 5px;
          }
          @media screen and (max-width: 600px) {
            min-height: 70px;
            max-width: 14px;
          }
          p{
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            color: #606773;
            @media screen and (max-width:600px) {
              font-size: 10px;
            }
          }
        }
        .containerFromSubzoneTables {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }
    }
    .tablesContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      .tableContainer {
        flex: 1;
        border-bottom: 1px solid #f3f3f3;
        border-top: 1px solid #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 50px;
        padding: 0 10px;
        background-color: white;
        position: relative;
        min-width: 100%;
        max-width: 100%;
        height: 50px;
        @media screen and (max-width: 600px) {
          padding-left: 3px;
          padding-right: 5px;
          height: 35px;
        }
        .displayNameIcon {
          display: block;
          width: 17px;
          height: 17px;
          margin-left: 12px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-color: transparent;
          cursor: help;
          @media screen and (max-width: 600px) {
            width: 11px;
            height: 11px;
          }
          &.busyRed {
            width: 21px;
            height: 21px;
            background-image: url(../../assets/icons/allTimeLineIcons/busyRed.svg);
            @media screen and (max-width:600px) {
              width: 13px;
              height: 13px;
            }
          }
          &.lockRed {
            background-image: url(../../assets/icons/allTimeLineIcons/lockRed.svg);
          }
          &.blackKey {
            background-image: url(../../assets/icons/allTimeLineIcons/keyBlack.svg);
          }
        }
        p {
          color: #444b57;
          font-weight: 600;
          font-size: 14px;
          white-space: nowrap;
          @media screen and (max-width: 600px) {
            &:not(.buttonPlus) {
              font-size: 12px;
              overflow: hidden;
              font-size: 10px;
            }
          }
        }
        .plusForModal {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 13px;
          height: 13px;
          max-width: 13px;
          max-height: 13px;
          font-weight: bold;
          padding: unset;
          border: none;
          cursor: pointer;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
          @media screen and (max-width:600px) {
            overflow: hidden;
            width: 12px;
            min-width: 12px;
            max-width: 12px;
            min-height: 12px;
            max-height: 12px;
          }
          p {
            font-size: 13px;
            height: 13px;
            line-height: 13px;
            @media screen and (min-width:1400px){
              transform: translateY(-1px);
            }
            @media screen and (max-width:1300px){
              transform: translateY(-2px);
            }
            @media screen  and (max-width:600px){
              transform: translateY(-1px);
              font-size: 12px;
              height: 12px;
              line-height: 12px;
            }
          }
        }
      }
    }
    .tablesContainer .isTableSelected {
      border: black solid 1px;
    }
    .containerFromSubzoneTables {
      .tableContainer {
        .displayNameIcon {
          margin-left: 0;
        }
      }
    }
  }
  .IsmodalOpen {
    .zoneTitle,
    .subZoneTitle,
    .tableContainer:not(.isTableSelected),
    .fatherContainerExtra {
      filter: brightness(0.73);
    }
  }
  .IsmodalOpenFromMap {
    .zoneTitle,
    .subZoneTitle,
    .tableContainer:not(.isTableSelected),
    .fatherContainerExtra {
      filter: brightness(0.73);
    }
  }
  .hideTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    border-radius: 4px;
    color: white;
    background: #444b57;
    text-align: start;
    width: 100%;
    position: relative;
    height: fit-content;
    gap: 10px;
    height: 21px;
    flex-direction: row;
    cursor: pointer;
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      p {
        padding-left: 10px;
      }
      span {
        position: relative;
        bottom: 5px;
        transform: rotate(-45deg);
        padding-right: 10px;
      }
    }
    input {
      visibility: hidden;
      position: absolute;
    }
  }
  .zoneHide {
    display: none !important;
  }
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .showZone {
    animation-name: appear;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .fatherContainer {
    @media screen and (max-width: 600px) {
      z-index: 10;
      position: relative;
      min-width:105px;
      max-width:105px;
    }
    &.selected {
      @media screen and (max-width: 600px) {
        z-index: 11;
        position: relative;
      }
    }
  }
  .iconName {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    @media screen and (max-width: 600px) {
      padding-left: 1px;
      gap: 2px;
    }
  }
  .blockContainer {
    width: 100%;
    max-height: 123px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      width: 4px !important;
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      background-color:#444b57 !important;
      border: unset !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .headerSpace{
    display: block;
    height: 25px;
    width: 100%;
    @media screen and (max-width:600px) {
      height: 22px;
    }
  }
  #TablesContainer .tablesContainer .tableContainer.isTableSelected .plusForModal{
    background: #444B57;
    p{
      color: white;
    }
  }
  .arrowIcon{
    width: 100%;
    height: 10px;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/icons/allTimeLineIcons/arrowForZones.svg);
    background-color: transparent;
    transform: rotate(180deg);
  }
  .zoneTitleElements{
    @media screen and (max-width:600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 13px;
      width: 13px;
      p{
        height: min-content;
        font-size: 11px;
        line-height: 11px;
        transform: translateX(1px);
      }
    }
  }
  #headerTitleFromTables{
    min-height: 22px;
  }
  .fatherContainerExtra{
    border-left: solid 2px #f3f3f3;
    width: 100%;
    flex: 1;
    background-color: #f3f3f3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, 49.2px);
    row-gap: 1px;
    border-bottom: 1px solid #f3f3f3;
    @media screen and (max-width:600px) {
      grid-template-rows: repeat(auto-fit, 34.2px);
    }
  }
</style>
