<template>
<div>
  <div id="modalCombineUser" class="modal-unify-users">
    <section class="modal-unify-users__header">
      <h2>Unificar contactos</h2>
      <i class="icons icon_close_modal" @click="$parent.close()"></i>
      <div class="modal-unify-users__header__action">
        <div>
          Puedes unificar uno a uno o si lo prefieres unificarlos todos.<br/>
          <b>Tienes <span id="cantidadPorCombinar">{{ dataUsers.totalRecords }}</span> contactos para unificar.</b>
        </div>
        <b-button @click="actionDataToMerge(1, null)" :disabled="isProcessing">Unificar todos los contactos</b-button>
      </div>
    </section>
    <section class="modal-unify-users__content">
      <div v-for="user in dataUsers.data" :key="user.__id" class="modal-unify-users__content__aux">
        <h2 class="capitalize text-center">{{ user.principal_data.name }}</h2>
        <div class="modal-unify-users__content__info">
          <CombineUserItem :user="user.principal_data" :name="user.principal_data.name" :identityDocument="user.principal_data.identityDocument" :email="user.principal_data.email" :phone="user.principal_data.phone"/>
          <div v-for="otro_user in user.otros_docs" :key="otro_user._id" >
            <CombineUserItem :name="otro_user.name" :identityDocument="otro_user.identityDocument" :email="otro_user.email" :phone="otro_user.phone"/>
          </div>
          <div class="modal-unify-users__content__info__buttons">
            <b-button type="is-dark" @click="actionDataToMerge(0, user._id)" outlined :disabled="isProcessing || dataUsers.length != 0">Descartar</b-button>
            <b-button type="is-dark" @click="actionDataToMerge(1, user._id)" outlined :disabled="isProcessing || dataUsers.length != 0">Combinar</b-button>
          </div>
        </div>
      </div>
      <div ref="loadMore" class="modal-unify-users__content__loading"></div>
    </section>
  </div>
</div>
</template>
<script>
import Axios from 'axios';
import globalTypes from '@/store/types/global';
import serviceImprove from '@/helpers/apis/serviceImprove';
import CombineUserItem from '@/components/users/users/combineUserItem';
import ProgressModal from '@/components/users/users/progressModal';
export default {
  components: {
    CombineUserItem
  },
  name: 'addUserModal',
  props: ['value'],
  data: () => ({
    dataUsers: {
      message: '',
      vendorId: null,
      data: [],
      currentPage: 1,
      totalPages: 1,
      length: 0,
      totalRecords: 0,
      tiempo: 0
    },
    isProcessing: false,
    loading: false,
    error: null,
    checkuserCombine: false,
    processingInstruction: false,
    progress: 0,
    showModal: false,
    menuDataCombine: {},
    modalTags: false,
    currentModal: null
  }),
  mounted () {
    const observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
    observer.observe(this.$refs.loadMore);
  },
  methods: {
    emitirEventos () {
      // Emitir eventos al componente referenciado
      this.$refs.progressmodal.$emit('close-modal');
      this.$refs.progressmodal.$emit('success');
      this.$refs.progressmodal.$emit('error');
    },
    handleIntersection (entries) {
      const entry = entries[0];
      if (entry.isIntersecting && this.dataUsers.currentPage <= this.dataUsers.totalPages) {
        this.fetchDataToMerge(); // Llama a la función para cargar más datos
      }
    },
    openModal () {
      this.loading = true; // Reiniciar el estado de carga al abrir el modal
      // this.error = null; // Reiniciar el error
      this.fetchDataToMerge(); // Llamar a la función para obtener los datos
    },
    fetchDataToMerge () {
      this.loading = true;
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceImprove.get(`/get?vendorId=${this.vendor.id}&page=${this.dataUsers.currentPage}`).then(({ data }) => {
        if (this.dataUsers.currentPage === 1) {
          this.dataUsers.data = [];
        }
        this.dataUsers.data = this.dataUsers.data.concat(data.data);
        this.dataUsers.currentPage += 1;
        this.dataUsers.totalPages = data.totalPages;
        this.dataUsers.totalRecords = data.totalRecords;
        if (data.totalRecords === 0) {
          this.isProcessing = true;
        } else {
          this.loading = false;
        }
      }).finally(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }).catch((error) => {
        this.loading = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        console.log(error);
      });
    },
    openModalProgress () {
      const instance = this;
      const numberOfUsers = this.dataUsers.totalRecords;
      const timeCalculated = numberOfUsers / 0.020;
      // this.progress = 50; // Establece el valor de progreso
      this.currentModal = this.$buefy.modal.open({
        parent: this,
        component: ProgressModal,
        hasModalCard: true,
        canCancel: false,
        events: {
          'close-modal': () => {
            this.$store.commit(globalTypes.mutations.setMenuDataCombine, {});
            this.$parent.close();
          },
          success: () => {
            const valor = { avance: 100, processed: 100, lengthToProcess: 100, tiempo: 0 };
            this.$store.commit(globalTypes.mutations.setMenuDataCombine, valor);
          },
          error: () => {
            this.$store.commit(globalTypes.mutations.setMenuDataCombine, { error: true });
          }
        },
        customClass: 'modalCombineProcess',
        props: {
          progress: instance.progress,
          cant: numberOfUsers,
          time: timeCalculated
        }
      });
    },
    delay (n) {
      return new Promise(function (resolve) {
        setTimeout(resolve, n * 1000);
      });
    },
    deleteNT (type, i) {
      if (type === 'tag') {
        this.userTags.splice(i, 1);
      }
    },
    async actionDataToMerge (processData, id) {
      this.isProcessing = true; // Indica que se está procesando
      if (id == null) {
        this.openModalProgress(); // Abre el modal
      }
      try {
        const params = {
          vendorId: this.vendor.id,
          process: processData,
          id: id
        };
        const response = await Axios.get(process.env.VUE_APP_IMPROVE_DATA + '/set', { params: params });
        if (response.data.lengthToProcess > 0) {
          this.length = response.data.lengthToProcess;
          this.tiempo = response.data.tiempo;
          this.checkuserCombine = true;
          this.$store.commit(globalTypes.mutations.setMenuDataCombine, response.data);
        }
        if (id == null) {
          this.processingInstruction = true; // Indica que se está procesando la instrucción
        } else {
          this.dataUsers.data = this.dataUsers.data.filter(user => user._id !== id); // Filtra el usuario que se ha combinado
        }
      } catch (err) {
        this.error = 'Error al obtener los datos: ' + err.message; // Manejo de errores
      } finally {
        this.isProcessing = false; // Restablece el estado de procesamiento
      }
    },
    closeModal () {
      console.log('Aqui para cerrarlo');
    }
  },
  watch: {
    processingInstruction: {
      handler () {
      }
    }
  }
};
</script>
<style lang="scss">
@import '../../../assets/styles/users/modalCombineUser';
</style>
