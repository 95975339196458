import namespace from '@/helpers/namespace';

export default namespace('metrics', {
  getters: [
    'dataReservation',
    'dataReservationRange',
    'byMonth',
    'byCountry',
    'byCountryPrecompras',
    'byCountryReservations',
    'byCountryWalkIn',
    'byDay',
    'byRange',
    'notEffective',
    'dateFrom',
    'dateTo',
    'year',
    'timeState',
    'finalizedState',
    'global',
    'pse',
    'card',
    'byHour',
    'byHourLastHour',
    'byClientType',
    'byMediaType',
    'byExperience',
    'byExperienceEffective',
    'metricReservations',
    'byGuest',
    'filterTypeDaySection',
    'byRequirements',
    'byEffectiveNotEffective',
    'byAllTotalReservations'
  ],
  actions: [
    'getDataReservation',
    'getDataReservationRange',
    'getDataReservationTime',
    'getDataReservationCompleted',
    'getDataReservationFilter',
    'generateReportMetrics',
    'getMetricReservations',
    'generateReportPrepurchaseMetrics',
    'generateReportWalkIn'
  ],
  mutations: [
    'setDataReservation',
    'setDataReservationRange',
    'setByMonth',
    'setByHour',
    'setByCountry',
    'setByHourLastHour',
    'setByClientType',
    'setByExperience',
    'setByExperienceEffective',
    'setByMediaType',
    'setByDay',
    'setByRange',
    'setNotEffective',
    'setDateFrom',
    'setYear',
    'setDateTo',
    'setTimeState',
    'setFinalizedState',
    'setGlobal',
    'setPse',
    'setCard',
    'setMetricReservations',
    'setByCountryPrecompras',
    'setByCountryReservations',
    'setByCountryWalkIn',
    'setByGuest',
    'setFilterTypeDaySection',
    'setByRequirements',
    'setByEffectiveNotEffective',
    'setByAllTotalReservations'
  ]
});
