<template>
  <div id="chooseAuthVendor">
    <div class="modal-card auth-vendor" v-if="showInfo">
      <div class="modal-card-head auth-vendor" :style="{height: (!loginChoose) ? '74px' : '50px', minHeight: (!loginChoose) ? '74px' : '50px', maxHeight: (!loginChoose) ? '74px' : '50px'}">
        <p class="modal-card-title auth-vendor auth-vendor-text" :class="{ 'login': loginChoose, 'not-login': !loginChoose }">
          <span v-if="!loginChoose">¿Desea cambiar de administrador de restaurante? <br> </span>
          Selecciona el administrador del <br v-if="isMobile && loginChoose"> restaurante al que <br v-if="isMobile && !loginChoose"> deseas ingresar
        </p>
        <div
          v-if="!loginChoose" style="position: relative; left: -16px; cursor: pointer; color: white; font-size: 30px;"
          @click="closeModal">&times;</div>
      </div>
      <div class="modal-card-body auth-vendor" :style="getStyleForUser()">
        <div v-if="user.isAdmin || vendors.length > 6" class="row-auth" style="margin-bottom: -4px; margin-top: 16px;">
          <b-input v-model="vendorSearch"
            expanded icon="magnify"
            class="inputSearchChooseAuth"
            type="search"
            placeholder="Escribe el restaurante que deseas buscar"
            @input="handleInput"
          >
          </b-input>
        </div>
        <div v-if="vendors.length && !loader"
          v-for="(item, idxRow) in Array.from({ length: rows })"
          :key="idxRow"
          class="row-auth"
        >
          <div v-for="(vendor, idx) in subVendors(idxRow)" :key="'subVendor-' + idx"
            class="column-custom-auth"
            @click="vendorSelected = vendor.url"
          >
            <div class="card-auth-vendor" :class="{ p20: vendor.sede }">
              <div class="radio-container">
                <b-radio v-model="vendorSelected"
                  name="name"
                  :native-value="vendor.url"
                  type="custom-dark-blue"
                ></b-radio>
              </div>
              <div class="imageChooseVendorAuth" :class="{mtUnset: vendor.sede}">
                <img v-if="vendor.thumbnailURL" :src="vendor.thumbnailURL" alt="Placeholder image" style="max-width: 116px; max-height: 100%;" @error="imageNotFound">
                <img v-else src="../../assets/icons/loading-icon.png" alt="Placeholder image" style="max-width: 116px; max-height: 100%;">
              </div>
              <div class="vendor-footer-auth" v-if="vendor.sede">
                {{ displaySede(vendor.sede) }}
              </div>
            </div>
          </div>
        </div>
        <div class="row-auth not-results-auth" v-if="loader">
          <b-loading :is-full-page="false" :active="loader" :can-cancel="false"></b-loading>
        </div>
        <div class="row-auth not-results-auth" v-if="!loader && !vendors.length">
          <p class="has-text-centered">No se encontraron resultados</p>
        </div>
      </div>
      <div class="modal-card-footer">
        <b-button
          rounded
          class="custom-button-choose-auth"
          :disabled="!vendorSelected"
          @click="setVendor"
        >
          Seleccionar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import vendorService from '@/helpers/apis/serviceVendor';
export default {
  props: ['active', 'loginChoose'],
  data () {
    return {
      vendors: [],
      vendorSelected: null,
      vendorSearch: '',
      loader: false,
      vendorsLength: 0,
      debounceTimer: null,
      showInfo: false
    };
  },
  mounted () {
    this.$store.dispatch({
      type: 'auth:refreshUser',
      data: {
        userId: localStorage.getItem('_userId')
      }
    }).finally(() => {
      const vendors = localStorage.getItem('_vendors');
      if (!vendors) {
        localStorage.clear();
        window.location.href = '/dashboard/login';
        return;
      }
      this.showInfo = true;
      this.vendors = JSON.parse(atob(vendors));
    });
  },
  methods: {
    subVendors (idx) {
      return this.vendors.slice(idx * this.columns, idx * this.columns + this.columns);
    },
    setVendor () {
      const vendorInfo = this.vendors.find((item) => item.url === this.vendorSelected);
      localStorage.setItem('_id', vendorInfo.vendorId);
      localStorage.setItem('keyWs', vendorInfo.vendorId);
      localStorage.setItem('vendorUrl', this.vendorSelected);
      window.location.href = '/dashboard/reservations';
    },
    displaySede (sede) {
      if (!sede) return '';
      return sede.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    handleInput () {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.searchVendors();
      }, 500);
    },
    async searchVendors () {
      if (!this.vendorSearch || this.vendorSearch.length < 4) return;
      this.loader = true;
      const query = `?filter=${this.vendorSearch}&dashboard=1`;
      const { data } = await vendorService.get('/restaurants' + query);
      this.vendors = data;
      this.loader = false;
      this.vendorsLength = this.vendors.length;
    },
    closeModal () {
      this.$parent.close();
    },
    imageNotFound (e) {
      e.target.src = 'https://images.homify.com/c_fill,f_auto,q_0,w_740/v1526483607/p/photo/image/2561426/3.jpg';
    },
    getStyleForUser () {
      if (this.user.isAdmin) {
        return {
          padding: '0px 0px 20px 0px'
        };
      } else {
        if (this.vendors.length <= 6) {
          return {
            justifyContent: 'center',
            padding: '30px 0px 0px 0px'
          };
        } else {
          return {};
        }
      }
    }
  },
  computed: {
    rows () {
      if (this.vendors.length < 3) return 1;
      if (this.isTabletC || this.isMobile) return Math.ceil(this.vendors.length / 2);
      return Math.ceil(this.vendors.length / 3);
    },
    columns () {
      if (this.isTabletC || this.isMobile || this.vendors.length < 3) return 2;
      return 3;
    },
    isTabletC () {
      if (this.windowWidth < 900 && this.windowWidth > 600) return true;
      return false;
    },
    user () {
      return this.$store.getters['auth:user'];
    }
  },
  watch: {
    vendorSearch (val) {
      if (!val.length && this.loader) this.loader = false;
      if (!val.length) {
        this.vendors = [];
        this.vendorsLength = 0;
      }
    }
  }
};
</script>

<style lang="scss">
#chooseAuthVendor {
  display: flex;
  justify-content: center;
  align-items: center;
  .inputSearchChooseAuth {
    min-width: 315px;
    width: 706px;
    max-width: 706px;
    height: 30px;
    input {
      border-radius: 8px;
      border: 2px solid #B4B7BC;
      background: #FFF;
      height: 30px !important;
      &:hover, &:active, &:focus {
        outline: unset!important;
        border-color: unset!important;
      }
      &::placeholder {
        color: #B4B7BC;
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-style: italic;
        font-weight: 300;
        line-height: normal;
      }
    }
    i {
      color: #B4B7BC;
    }
  }
  .imageChooseVendorAuth {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: calc(100% - 60px);
    img {
      max-width: 116px;
      max-height: 81px;
    }
  }

  .modal-card {
    min-width: 601px;
    max-width: 863px;
    width: 863px;
    max-height: 540px;
    border-radius: 10px;
    background-color: white;
  }

  .modal-card-head {
    padding: 14px 0px 14px 0px;
    max-height: 50px;
    height: 50px;
    min-height: 50px;
  }

  .modal-card-title.auth-vendor.auth-vendor-text {
    color: #FFF!important;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 18px!important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .modal-card-head.auth-vendor  {
    background-color: #444B57 !important;
    text-align: center;
    border-bottom: 0px !important;
  }

  .modal-card-title.auth-vendor  {
    color: white !important;
  }

  .modal-card-body.auth-vendor {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 0px 20px 0px;
    align-items: center;
  }

  .card-auth-vendor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0px !important;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    box-shadow: 1px 1px 10px 1px #c2c2c2;
    cursor: pointer;
    height: 171px;
    width: 222px;
  }

  .p20 {
    padding: 20px !important;
  }

  .mtUnset {
    margin-top: unset !important;
  }

  .row-auth {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .row-auth.not-results {
    height: 100%;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
  }

  .column-custom-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .radio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
  }

  .button.custom-button-choose-auth {
    width: 95px;
    height: 34px !important;
    border-radius: 25px;
    background-color: #444B57 !important;
  padding: 10px 16px !important;
    span {
      color: #fff;
      font-family: 'Roboto';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .modal-card-body {
    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      background: #fff !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #444B57 !important;
      border-radius: 10px !important;
      background: #444B57 !important;
    }
  }

  .modal-card-footer  {
    display: flex;
    background-color: white !important;
    justify-content: center !important;
    align-items: center !important;
    border: 0px !important;
    min-height: 90px;
    max-height: 90px;
    height: 90px;
    margin: 0px !important;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .check.custom-dark-blue {
    border-color: #444B57 !important;
  }

  .b-radio.radio input[type=radio] + .check:before {
    background-color: #444B57 !important;
  }

  .vendor-footer-auth {
    margin-top: unset;
    color: #444B57;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .b-radio.radio input[type=radio]:focus:checked + .check {
    box-shadow: unset !important;
  }

  @media screen and (max-width: 600px) {
    .vendor-footer-auth {
      font-size: 12px;
    }
    .modal-card-title.auth-vendor {
      font-size: 14px !important;
    }
    .modal-card-title.auth-vendor.not-login {
      font-size: 12px !important;
    }
    .modal-card-title.choose-vendor.choose-vendor-text {
      font-size: 14px!important;
    }
    .animation-content {
      max-width: 343px !important;
      width: 343px;
    }
    .modal-card {
      min-width: 343px;
      max-width: 343px;
      max-height: 577px !important;
    }
    .card-auth-vendor {
      width: 150px;
      height: 160px;
    }
    .inputSearchChooseAuth {
      width: 315px;
      height: 30px;
      input {
        &::placeholder {
          font-size: 14px;
        }
      }
    }
    .row-auth {
      gap: 15px;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    .animation-content {
      max-width: 600px !important;
      width: 600px;
    }
    .modal-card {
      min-width: 600px;
      max-width: 600px;
      max-height: 707px !important;
    }
    .inputSearchChooseAuth {
      width: 464px;
      height: 30px;
    }
  }
}
</style>
