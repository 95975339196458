<template>
  <div id="modalNewButton">
    <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
    <div class="container-modal-new-button">
      <div class="container-modal-new-button__header">
        <div class="container-modal-new-button__header__order">
          <div>Orden</div>
          <div class="container-modal-new-button__header__order__select-container">
            <b-select v-model="infoLocal.order" class="container-modal-new-button__header__order__select-container__select" placeholder="Select a character" expanded>
              <option :value="0">0</option>
              <option :value="option" :key="option" v-for="option in 50">{{option}}</option>
            </b-select>
          </div>
        </div>
        <h2>{{id === null ? 'Crear nuevo' : 'Editar'}} botón</h2>
        <div class="container-modal-new-button__header--switch">
          <div>Programar el botón</div>
          <b-field>
            <b-switch v-model="isProgrammingButton"></b-switch>
          </b-field>
        </div>
      </div>
      <div class="container-modal-new-button__programming" v-if="isProgrammingButton">
        <div class="container-modal-new-button__programming__container">
          <div class="container-modal-new-button__programming__container__times">
            <div>
              <label>Hora inicio</label>
              <b-select v-model="startTime" class="" placeholder="00:00" expanded>
                <option :value="time" :key="time" v-for="time in times">{{time}}</option>
              </b-select>
            </div>
            <div>
              <label>Hora fin</label>
              <b-select v-model="finalTime" class="" placeholder="00:00" expanded>
                <option :value="time" :key="time" v-for="time in times">{{time}}</option>
              </b-select>
            </div>
          </div>
          <div class="container-modal-new-button__programming__container__days">
            <!-- {{checkboxGroupDays.join(', ')}} -->
            <label>Días de la semana</label>
            <div class="container-modal-new-button__programming__container__days__container">
              <b-checkbox-button v-for="(day, index) in daysOfTheWeek" :key="index" v-model="checkboxGroupDays"
                :native-value="day.value"
              >
                <span>{{day.label}}</span>
              </b-checkbox-button>
            </div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="container-modal-new-button__content">
        <div class="container-modal-new-button__content__label" :class="{'container-modal-new-button__content__label--selected' : localTypeLanguage === 'spanish'}" @click="changeTypeLanguage('spanish')">
          <h3>Botón Español</h3>
          <div class="container-modal-new-button__content__label__card">
            <img class="container-modal-new-button__content__label__card__image" v-if="infoLocal.image" :src="infoLocal.image" alt="">
            <img class="container-modal-new-button__content__label__card__no-image" v-if="!infoLocal.image" src="@/assets/icons/menuQr/no_image.svg" alt="">
            <div class="container-modal-new-button__content__label__card__name-button">
              <img src="@/assets/icons/menuQr/edit.svg" alt="">
              <!-- <h4></h4> -->
              <b-input class="container-modal-new-button__content__label__card__name-button__input" placeholder="Nombre del botón" v-model="infoLocal.title" />
            </div>
            <b-upload class="container-modal-new-button__content__label__card--action" v-model="fileImageBtnSpanish">
              <img src="@/assets/icons/menuQr/photo.svg" alt="">
              <span>Clic para cambiar</span>
            </b-upload>
          </div>
        </div>
        <div class="container-modal-new-button__content__label" :class="{'container-modal-new-button__content__label--selected' : localTypeLanguage === 'english'}" @click="changeTypeLanguage('english')">
          <h3>Botón Inglés</h3>
          <div class="container-modal-new-button__content__label__card">
            <img class="container-modal-new-button__content__label__card__image" v-if="infoLocal.imageEnglish" :src="infoLocal.imageEnglish" alt="">
            <img class="container-modal-new-button__content__label__card__no-image" v-if="!infoLocal.imageEnglish" src="@/assets/icons/menuQr/no_image.svg" alt="">
            <div class="container-modal-new-button__content__label__card__name-button">
              <img src="@/assets/icons/menuQr/edit.svg" alt="">
              <b-input class="container-modal-new-button__content__label__card__name-button__input" placeholder="Nombre del botón" v-model="infoLocal.titleEnglish" />
            </div>
            <b-upload class="container-modal-new-button__content__label__card--action" v-model="fileImageBtnEnglish">
              <img src="@/assets/icons/menuQr/photo.svg" alt="">
              <span>Clic para cambiar</span>
            </b-upload>
          </div>
        </div>
        <div class="container-modal-new-button__content__label" :class="{'container-modal-new-button__content__label--selected' : localTypeLanguage === 'french'}" @click="changeTypeLanguage('french')">
          <h3>Botón Francés</h3>
          <div class="container-modal-new-button__content__label__card">
            <img class="container-modal-new-button__content__label__card__image" v-if="infoLocal.imageFrench" :src="infoLocal.imageFrench" alt="">
            <img class="container-modal-new-button__content__label__card__no-image" v-if="!infoLocal.imageFrench" src="@/assets/icons/menuQr/no_image.svg" alt="">
            <div class="container-modal-new-button__content__label__card__name-button">
              <img src="@/assets/icons/menuQr/edit.svg" alt="">
              <b-input class="container-modal-new-button__content__label__card__name-button__input" placeholder="Nombre del botón" v-model="infoLocal.titleFrench" />
            </div>
            <b-upload class="container-modal-new-button__content__label__card--action" v-model="fileImageBtnFrench">
              <img src="@/assets/icons/menuQr/photo.svg" alt="">
              <span>Clic para cambiar</span>
            </b-upload>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="container-modal-new-button__content">
        <div class="container-modal-new-button__content__menu-types">
          <div class="container-modal-new-button__content__menu-types__title-lang">
            <h2>
              Elige un tipo de menú
            </h2>
            <span>(Botón {{getLangBtn}})</span>
          </div>
          <div class="container-modal-new-button__content__menu-types__options">
            <b-upload
              v-if="(items.length === 0 || ((items.length > 0 && items[0].image === null)) && imagesSend.length === 0)"
              multiple
              v-model="selectedImages"
              @input="handleFileInput"
            >
              <div class="container-modal-new-button__content__menu-types__options--option" :class="{'container-modal-new-button__content__menu-types__options--option-selected' : typeMenuItem === 'image'} ">
                <div>
                  <i class="icon-mks icon_file_img"></i>
                  <span>Imagen</span>
                </div>
              </div>
            </b-upload>
            <div v-else class="container-modal-new-button__content__menu-types__options--option" :class="{'container-modal-new-button__content__menu-types__options--option-selected' : typeMenuItem === 'image'} " @click="changeTypeMenu('image')">
              <div>
                <i class="icon-mks icon_file_img"></i>
                <span>Imagen</span>
              </div>
            </div>
            <div class="container-modal-new-button__content__menu-types__options--option" :class="{'container-modal-new-button__content__menu-types__options--option-selected' : typeMenuItem === 'custom'}" @click="changeTypeMenu('custom')">
              <div>
                <i class="icon-mks icon_interactive"></i>
                <span>Interactivo</span>
              </div>
            </div>
            <div class="container-modal-new-button__content__menu-types__options--option" :class="{'container-modal-new-button__content__menu-types__options--option-selected' : typeMenuItem === 'url'}" @click="changeTypeMenu('url')">
              <div>
                <i class="icon-mks icon_link"></i>
                <span>Link</span>
              </div>
            </div>
          </div>
          <div class="container-modal-new-button__content__menu-types__image" v-if="typeMenuItem === 'image'">
            <div class="container-modal-new-button__content__menu-types__image__container">
              <CardMenuImage
                :auxDataMenu="auxDataMenu"
                v-for="(image, index) in itemsByLang"
                :isEdit="true"
                :menu="image"
                :key="`service-${index}`"
                :typeMenu="'multimenu'"
                @deleteImg="deleteImg(image.id, 'service')"
                @getItems="getItems"
                @openModalPreviewImage="openModalPreviewImage(image, 'multimenu', index, 'service')"
                @updateImage="updateImage(index, $event, 'service')"
              />
              <CardMenuImage
                :auxDataMenu="auxDataMenu"
                v-for="(imageLocal, index) in imagesSendByLang"
                :isEdit="false" :menu="imageLocal"
                :key="`local-${index}`"
                :typeMenu="'multimenu'"
                @deleteImg="deleteImg(imageLocal.id, 'local')"
                @openModalPreviewImage="openModalPreviewImage(imageLocal, 'multimenu', index, 'local')"
                @updateImage="updateImage(index, $event, 'local')" :index="index"
              />
            </div>
            <div class="container-modal-new-button__content__menu-types__image__copy" v-if="items.length > 0 || imagesSend.length > 0">
              <div>
                *Las imágenes que debes usar son en formato jpg y no deben superar un tamaño de 2MB, si acepta, se optimizarán las imagenes al subirlas.
              </div>
              <b-upload
                multiple
                v-model="selectedImages"
                @input="processFiles"
              >
                <div class="container-modal-new-button__content__menu-types__image__copy--action">
                  <div>
                    <img src="@/assets/icons/menuQr/plus_alternative.svg" alt="bg image">
                    <span>Agregar imagen</span>
                  </div>
                </div>
              </b-upload>
            </div>
          </div>
          <div class="container-modal-new-button__content__menu-types__interactive" v-if="typeMenuItem === 'custom'" >
            <div class="container-modal-new-button__content__menu-types__interactive__title">Selecciona el menú al que pertenece</div>
            <div
              class="container-modal-new-button__content__menu-types__interactive__select"
            >
              <b-select class="container-modal-new-button__content__menu-types__interactive__select__container" v-model="categoryMenu" placeholder="Selecciona el menú al que pertenece" expanded>
                <option :value="option.id" :key="option.id" v-for="option in selectCategories">{{option.label}}</option>
              </b-select>
            </div>
          </div>
          <div class="container-modal-new-button__content__menu-types__link" v-if="typeMenuItem === 'url'">
            <div class="container-modal-new-button__content__menu-types__link__title">Escribe la URL</div>
            <div class="container-modal-new-button__content__menu-types__link__input">
              <b-input type="text" v-model="urlMenu"></b-input>
            </div>
          </div>
        </div>
      </div>
      <div class="container-modal-new-button__footer">
        <button :disabled="validateAction" class="container-modal-new-button__footer--action container-modal-new-button__footer--action__save" @click="actionMenuMultiple">
          <img src="@/assets/icons/menuQr/save.svg" alt="" srcset="">
          <div>{{id === null ? 'Guardar' : 'Actualizar'}}</div>
        </button>
        <button class="container-modal-new-button__footer--action container-modal-new-button__footer--action__close" @click="$parent.close()">
          <img src="@/assets/icons/menuQr/close.svg" alt="" srcset="">
          <div>Cerrar</div>
        </button>
      </div>
    </div>
    <b-modal :active.sync="isImageModalActive" class="image-modal-preview" :can-cancel="false">
      <div class="image-modal-preview__background" v-if="auxDataMenu" :style="{'background-image': `url('${typeMenuAux === 'multimenu' ? auxDataMenu.image: auxDataMenu.url}')`, backgroundSize: 'cover', backgroundPosition: 'center'}">
        <div class="image-modal-preview__close" @click="() => isImageModalActive = false">
          <img src="@/assets/icons/menuQr/close.svg">
        </div>
        <div class="image-modal-preview__background--actions">
          <div class="image-modal-preview__background--actions__order">
            <input type="text" v-model="auxDataMenu.order"/>
          </div>
          <div class="image-modal-preview__background--actions__delete" @click="modeDeleteGlobal">
            <img src="@/assets/icons/menuQr/trash.svg" alt="delete">
          </div>
          <div class="image-modal-preview__background--actions__check">
            <b-checkbox v-model="auxDataMenu.isActive" :true-value="1" :false-value="0"></b-checkbox>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import serviceQr from '@/helpers/apis/serviceQr.js';
import CardMenuImage from '@/components/schedules/menu-qr/complements/CardMenuImage.vue';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import menuQrTypes from '@/store/types/menuQr';

export default {
  props: ['info', 'id', 'restaurantData', 'restaurant'],
  components: {
    CardMenuImage
  },
  data () {
    return {
      infoLocal: { ...this.info },
      fileImageBtnSpanish: null,
      fileImageBtnEnglish: null,
      fileImageBtnFrench: null,
      imageSpanish: null,
      imageEnglish: null,
      imageFrench: null,
      isLoading: false,
      validateAction: true,
      typeMenuItem: '',
      imagesSend: [],
      selectedImages: null,
      items: [],
      selectMenu: [],
      typeMenuItemArray: [],
      listTypeLanguage: [],
      urlMenu: null,
      categoryMenu: null,
      categoryMenuSelectedLabel: 'Elige una categoría',
      selectCategories: [],
      showOptions: false,
      getMenuService: false,
      isProgrammingButton: false,
      daysOfTheWeek: [
        {
          label: 'Lun',
          value: 1
        },
        {
          label: 'Mar',
          value: 2
        },
        {
          label: 'Mier',
          value: 3
        },
        {
          label: 'Jue',
          value: 4
        },
        {
          label: 'Vie',
          value: 5
        },
        {
          label: 'Sab',
          value: 6
        },
        {
          label: 'Dom',
          value: 7
        }
      ],
      checkboxGroupDays: [],
      startTime: null,
      finalTime: null,
      totalSizeForAllImages: 10,
      times: [],
      auxDataMenu: null,
      isImageModalActive: false,
      typeMenuAux: '',
      positionForDelete: null,
      typeDelete: 'local',
      localTypeLanguage: ''
    };
  },
  mounted () {
    this.localTypeLanguage = this.typeLanguage;
    this.validateFields();
    this.generateTimes();
    if (this.id !== null) {
      if (this.infoLocal.dayOfWeek !== null) {
        this.isProgrammingButton = true;
        this.checkboxGroupDays = this.infoLocal.dayOfWeek;
      }
      this.getItems();
      this.finalTime = this.infoLocal.finishPrograming;
      this.startTime = this.infoLocal.initPrograming;
    }
  },
  computed: {
    ...mapGetters({
      typeLanguage: menuQrTypes.getters.typeLanguage
    }),
    itemsByLang () {
      const filteredArray = [...this.items];
      return filteredArray.filter((item) => item.typeLanguage === this.localTypeLanguage);
    },
    imagesSendByLang () {
      const filteredArray = [...this.imagesSend];
      return filteredArray.filter((item) => item.typeLanguage === this.localTypeLanguage);
    },
    getLangBtn () {
      const langMap = {
        spanish: 'español',
        english: 'inglés',
        french: 'francés'
      };
      return langMap[this.localTypeLanguage] || 'español';
    }
  },
  methods: {
    processFiles (files) {
      this.selectedImages = Array.from(files).map((file) => {
        const uniqueName = this.generateUniqueFileName(file.name);
        return new File([file], uniqueName, { type: file.type });
      });
    },
    generateUniqueFileName (originalName) {
      const timestamp = Date.now();
      const randomId = Math.random().toString(36).substring(2, 7);
      const fileExtension = originalName.split('.').pop();
      return `${timestamp}_${randomId}.${fileExtension}`;
    },
    modeDeleteGlobal () {
      // si el menu está en modo de editar, borra el menu por api, si no, borra localmente el menu
      if (this.typeMenuAux === 'multimenu') {
        this.deleteImg(this.positionForDelete, this.typeDelete);
        this.openModalPreviewImage();
      } else {
        if (this.isEdit) {
          this.deleteItem(this.menu.id);
        } else {
          this.$emit('deleteMenuLocal');
        }
      }
    },
    openModalPreviewImage (data = null, type = '', index = null, typeDelete = 'local') {
      this.isImageModalActive = !this.isImageModalActive;
      this.typeMenuAux = type;
      if (this.isImageModalActive) {
        data.index = index;
        this.auxDataMenu = data;
      }
      if (index) {
        this.typeDelete = typeDelete;
        this.positionForDelete = data.id;
      }
    },
    generateTimes () {
      const times = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minutes = 0; minutes < 60; minutes += 30) {
          const formattedHour = String(hour).padStart(2, '0');
          const formattedMinutes = String(minutes).padStart(2, '0');
          times.push(`${formattedHour}:${formattedMinutes}`);
        }
      }
      this.times = times;
    },
    selectOptionCategory (category, label) {
      this.categoryMenu = category;
      this.categoryMenuSelectedLabel = label;
      this.validateFields();
      this.hideOptions();
    },
    toggleOptions () {
      this.showOptions = !this.showOptions;
    },
    hideOptions () {
      this.showOptions = !this.showOptions;
    },
    getMenusCategory () {
      serviceQr.get(`/menuCustom/listMenu/${this.restaurant.vendorId}`).then(({ data }) => {
        const arrayList = [];
        data.data.forEach(element => {
          arrayList.push({ id: element._id, label: element.name });
        });
        this.selectCategories = arrayList;
      }).catch(() => {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error al traer el listado de categorías',
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    validateFields () {
      // Función para validar si al menos una imagen existe
      const hasValidImage = () => {
        return (
          this.infoLocal.image !== null ||
          this.infoLocal.imageEnglish !== null ||
          this.infoLocal.imageFrench !== null
        );
      };

      // Función para validar si al menos un título existe
      const hasValidTitle = () => {
        return (
          (this.infoLocal.title !== null && this.infoLocal.title.trim() !== '') ||
          (this.infoLocal.titleEnglish !== null && this.infoLocal.titleEnglish.trim() !== '') ||
          (this.infoLocal.titleFrench !== null && this.infoLocal.titleFrench.trim() !== '')
        );
      };

      // Función para validar los ítems según el tipo de menú
      const validateItems = () => {
        switch (this.typeMenuItem) {
          case 'image':
            return this.items.length > 0 || (this.items.length === 0 && this.imagesSend.length > 0);
          case 'custom':
            return this.categoryMenu !== null;
          case 'url':
            return this.urlMenu !== null && this.urlMenu !== '';
          default:
            return false;
        }
      };

      // Validación para el botón de programación
      if (this.isProgrammingButton) {
        const areDaysSelected = this.checkboxGroupDays.length > 0;
        const areTimesValid = this.finalTime !== null && this.startTime !== null;

        this.validateAction = !(areDaysSelected && areTimesValid);
        if (this.validateAction) return; // Si la validación falla, salir de la función
      }

      // Validación para la información del local
      const isImageValid = hasValidImage();
      const isTitleValid = hasValidTitle();
      const isTypeMenuValid = this.typeMenu !== '';
      const isItemsValid = validateItems();

      // Asignar el resultado final de la validación
      this.validateAction = !((isImageValid || isTitleValid) && isTypeMenuValid && isItemsValid);
    },
    getItems () {
      this.isLoading = true;
      serviceQr.get('/menudigital/multimenu/items/' + this.id).then(({ data }) => {
        data.data.forEach((e, i) => {
          this.selectMenu[i] = e.vendorCustomId;
          this.typeMenuItemArray[i] = e.type;
          this.listTypeLanguage[i] = e.typeLanguage;
        });
        if (data.data.length > 0 && data.data[0].type !== 'image') {
          this.items = [];
        } else {
          this.items = data.data;
        }
        this.isLoading = false;
        if (data.data.length > 0) {
          this.changeTypeMenu(data.data[0].type);
          this.categoryMenu = data.data[0].vendorCustomId;
          this.urlMenu = data.data[0].imageExternalUrl;
        }
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    actionMenuMultiple () {
      if (this.id === null) {
        this.saveMultipleMenu();
      } else {
        this.updateMultipleMenu();
      }
    },
    saveMultipleMenu () {
      let data = { ...this.infoLocal, userId: this.user.id };
      if (this.isProgrammingButton) {
        data = {
          ...data,
          programing: 1,
          dayOfWeek: this.checkboxGroupDays.join(','),
          finishPrograming: this.finalTime,
          initPrograming: this.startTime
        };
      } else {
        data = {
          ...data,
          programing: 0,
          dayOfWeek: null,
          finishPrograming: null,
          initPrograming: null
        };
      }
      this.isLoading = true;
      serviceQr.post('/menudigital/multimenu', data).then(({ data }) => {
        if (this.typeMenuItem === 'image') {
          this.setMenuImgMultiple(data.menu_id);
        } else {
          this.setMenuMultipleInteractiveLink(data.menu_id);
        }
        this.infoLocal = { ...this.info };
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    updateMultipleMenu () {
      let data = { ...this.infoLocal, userId: this.user.id };
      if (this.isProgrammingButton) {
        data = {
          ...data,
          programing: 1,
          dayOfWeek: this.checkboxGroupDays.join(','),
          finishPrograming: this.finalTime,
          initPrograming: this.startTime
        };
      } else {
        data = {
          ...data,
          programing: 0,
          dayOfWeek: null,
          finishPrograming: null,
          initPrograming: null
        };
      }
      this.isLoading = true;
      serviceQr.put('/menudigital/multimenu/' + this.id, data).then(({ data }) => {
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        if (this.typeMenuItem === 'image') {
          this.setMenuImgMultiple();
        } else {
          this.setMenuMultipleInteractiveLink(this.id);
        }
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    uploadImage (image, name, language, optimize = 0) {
      this.isLoading = true;
      this.uploadImageGlobalCloud(
        image,
        `multiple/${language}/${name}`,
        null,
        this.restaurantData.vendor,
        'menu',
        optimize,
        name,
        `multiple/${language}/`
      ).then(({ data, code }) => {
        if (code === 200) {
          if (language === 'spanish') {
            this.imageSpanish = data.data;
            this.infoLocal.image = data.data;
          }
          if (language === 'french') {
            this.imageFrench = data.data;
            this.infoLocal.imageFrench = data.data;
          }
          if (language === 'english') {
            this.imageEnglish = data.data;
            this.infoLocal.imageEnglish = data.data;
          }
          this.validateFields();
        } else {
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
            type: 'is-danger'
          });
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return { size: Math.round(bytes / Math.pow(1024, i), 2), format: sizes[i] };
    },
    changeTypeMenu (type) {
      this.typeMenuItem = type;
      this.validateFields();
      if (type === 'custom') {
        this.getMenusCategory();
      }
    },
    uploadImagesMenuImage () {
      this.isLoading = true;
      const imgsForUpload = [...this.selectedImages];
      const sortedArray = imgsForUpload.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      let totalSize = 0;
      sortedArray.forEach((file) => {
        file.realSize = this.bytesToSize(file.size);
        totalSize += file.size;
      });
      const totalSizeConverted = totalSize / (1024 * 1024);
      const pass = sortedArray.filter(sa => (sa.realSize.format === 'MB' && sa.realSize.size >= 2));
      const instance = this;
      if (totalSizeConverted > this.totalSizeForAllImages) {
        this.isLoading = false;
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete alert-max-size',
          props: {
            idMenu: null,
            type: 'file_size',
            title: '¡ATENCIÓN!',
            subtitle: `
              <div style="margin-bottom: 10px;">Una o varias imágenes que estas usando exceden el peso máximo permitido.</div>
              <div>El peso actual total de las imagenes es de <b>${totalSizeConverted.toFixed(1)}MB</b></div>
              `,
            typeDelete: ''
          },
          events: {
            onCancel () {
              instance.isLoading = false;
              instance.selectedImages = null;
            }
          }
        });
      } else {
        if (pass.length > 0) {
          this.$buefy.modal.open({
            parent: this,
            component: AlertConfirmDelete,
            hasModalCard: true,
            canCancel: false,
            customClass: 'alert-confirm-delete alert-capacity',
            props: {
              idMenu: null,
              type: 'optimize',
              title: 'Imagen muy pesada.',
              subtitle: `
                <div style='margin-bottom:10px;'>Una o varias imagenes tienen un peso superior al recomendado, se recomienda subir imágenes con un tamaño inferior a 2 MB.</div>
                <div>¿Desea que optimicemos las imagenes que estas cargando?</br></div>
                `,
              typeDelete: ''
            },
            events: {
              onConfirm () {
                instance.serviceForUploadImagesMultiple(sortedArray);
              },
              onCancel () {
                instance.isLoading = false;
                instance.selectedImages = null;
              }
            }
          });
        } else {
          this.serviceForUploadImagesMultiple(sortedArray);
        }
      }
    },
    serviceForUploadImagesMultiple (sortedArray) {
      this.uploadImageMultiple(sortedArray, this.restaurantData.vendor, 'menu', 'multiple/options/').then(({ data, code }) => {
        if (code === 200) {
          data.data.forEach((url, index) => {
            this.imagesSend.push({
              image: url,
              order: index + 1,
              menuId: this.id,
              type: 'image',
              vendorCustomId: null,
              typeLanguage: this.localTypeLanguage,
              activeUrlImage: 0,
              imageExternalUrl: null,
              isActive: 1,
              id: `local-${Date.now() + index}`
            });
          });
        }
        this.selectedImages = null;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    deleteImg (id, mode = 'local') {
      var instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: AlertConfirmDelete,
        hasModalCard: true,
        canCancel: false,
        customClass: 'alert-confirm-delete',
        props: {
          idMenu: null,
          type: 'delete',
          title: 'Confirmar',
          subtitle: '¿Estás seguro(a) que quieres eliminar esta imagen?',
          typeDelete: 'multimenu_images'
        },
        events: {
          changeStatusLoader (status) {
            instance.$emit('changeStatusLoader', status);
          },
          deleteImagenMultimenu () {
            if (mode === 'service') {
              instance.items = instance.items.filter((item) => item.id !== id);
            }
            if (mode === 'local') {
              instance.imagesSend = instance.imagesSend.filter((item) => item.id !== id);
            }
          }
        }
      });
    },
    setMenuImgMultiple (idFromCreation = null) {
      // guarda el item con la cantidad de imagenes agregadas, si se agregaron nuevas, se concatenan con las que ya se habían subido, si no, solo se suben las nuevas
      const data = {};
      // si se está creando un menú multiple, se pasa a esta funcion el id del multimenu creado y se reemplaza en el data.menuId para poder agregarle items(tipo de menú: imagen, interactivo o link)
      if (idFromCreation !== null) {
        data.menuId = idFromCreation;
      } else {
        data.menuId = this.id;
      }
      if (this.items.length > 0) {
        data.items = this.imagesSend.concat(this.items);
      } else {
        if (this.imagesSend.length > 0) {
          data.items = this.imagesSend;
        } else {
          data.items = [];
        }
      }
      data.userId = this.user.id;
      data.vendorId = this.restaurant.vendorId;
      const idAux = idFromCreation !== null ? idFromCreation : this.id;
      serviceQr.post('/menudigital/multimenu/items/' + idAux, data).then(({ data }) => {
        this.isLoading = false;
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete alert-success',
          props: {
            idMenu: null,
            type: 'success',
            title: 'Botón creado con éxito',
            subtitle: '',
            typeDelete: ''
          }
        });
        this.$emit('getMultiMenu');
        setTimeout(() => {
          this.$parent.close();
        }, 500);
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    setMenuMultipleInteractiveLink (menuId) {
      this.isLoading = false;
      const data = {};
      data.items = [
        {
          activeUrlImage: 0,
          image: null,
          imageExternalUrl: this.typeMenuItem === 'url' ? this.urlMenu : null,
          type: this.typeMenuItem,
          typeLanguage: this.localTypeLanguage,
          vendorCustomId: this.typeMenuItem === 'custom' ? this.categoryMenu : null,
          menuId: menuId,
          order: 0
        }
      ];
      data.menuId = menuId;
      data.userId = this.user.id;
      data.vendorId = this.restaurant.vendorId;
      serviceQr.post('/menudigital/multimenu/items/' + menuId, data).then(({ data }) => {
        this.isLoading = false;
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete alert-success',
          props: {
            idMenu: null,
            type: 'success',
            title: 'Botón editado con éxito',
            subtitle: '',
            typeDelete: ''
          }
        });
        this.$emit('getMultiMenu');
        setTimeout(() => {
          this.$parent.close();
        }, 500);
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    handleFileInput (newFiles) {
      if (newFiles.length === 0) {
      } else {
        this.changeTypeMenu('image');
      }
    },
    changeTypeLanguage (lang) {
      this.localTypeLanguage = lang;
      /* this.$store.commit(menuQrTypes.mutations.setTypeLanguage, lang); */
    },
    updateImage (index, updatedMenu, type = 'service') {
      if (type === 'service') {
        console.log(this.items);
        const index = this.items.findIndex(item => item.id === updatedMenu.id);
        this.items[index] = updatedMenu;
      } else {
        const index = this.imagesSend.findIndex(item => item.id === updatedMenu.id);
        this.imagesSend[index] = updatedMenu;
      }
    }
  },
  watch: {
    fileImageBtnSpanish (value) {
      if (value == null) {
        return;
      }
      const fileInfo = this.bytesToSize(value.size);
      if (fileInfo.format === 'MB' && fileInfo.size > 2) {
        var instance = this;
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete alert-capacity',
          props: {
            idMenu: null,
            type: 'optimize',
            title: 'Imagen muy pesada.',
            subtitle: `
              <div style='margin-bottom:10px;'>Se recomienda subir imágenes con un tamaño inferior a 2 MB.</div>
              <div>¿Desea que optimicemos la imagen que esta cargando? </br> El peso actual de su imagen es de <b>${fileInfo.size} ${fileInfo.format}</b></div>
              `,
            typeDelete: ''
          },
          events: {
            onConfirm () {
              const name = value.name.split('.');
              instance.uploadImage(value, name[0], 'spanish', 1);
            },
            onCancel () {
              instance.fileImageBtnSpanish = null;
            }
          }
        });
        return;
      }
      const name = value.name.split('.');
      this.uploadImage(value, name[0], 'spanish');
    },
    fileImageBtnEnglish (value) {
      if (value == null) {
        return;
      }
      const fileInfo = this.bytesToSize(value.size);
      if (fileInfo.format === 'MB' && fileInfo.size > 2) {
        var instance = this;
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete alert-capacity',
          props: {
            idMenu: null,
            type: 'optimize',
            title: 'Imagen muy pesada.',
            subtitle: `
              <div style='margin-bottom:10px;'>Se recomienda subir imágenes con un tamaño inferior a 2 MB.</div>
              <div>¿Desea que optimicemos la imagen que esta cargando? </br> El peso actual de su imagen es de <b>${fileInfo.size} ${fileInfo.format}</b></div>
              `,
            typeDelete: ''
          },
          events: {
            onConfirm () {
              const name = value.name.split('.');
              instance.uploadImage(value, name[0], 'english', 1);
            },
            onCancel () {
              instance.fileImageBtnEnglish = null;
            }
          }
        });
        return;
      }
      const name = value.name.split('.');
      this.uploadImage(value, name[0], 'english');
    },
    fileImageBtnFrench (value) {
      if (value == null) {
        return;
      }
      const fileInfo = this.bytesToSize(value.size);
      if (fileInfo.format === 'MB' && fileInfo.size > 2) {
        var instance = this;
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete alert-capacity',
          props: {
            idMenu: null,
            type: 'optimize',
            title: 'Imagen muy pesada.',
            subtitle: `
              <div style='margin-bottom:10px;'>Se recomienda subir imágenes con un tamaño inferior a 2 MB.</div>
              <div>¿Desea que optimicemos la imagen que esta cargando? </br> El peso actual de su imagen es de <b>${fileInfo.size} ${fileInfo.format}</b></div>
              `,
            typeDelete: ''
          },
          events: {
            onConfirm () {
              const name = value.name.split('.');
              instance.uploadImage(value, name[0], 'french', 1);
            },
            onCancel () {
              instance.fileImageBtnFrench = null;
            }
          }
        });
        return;
      }
      const name = value.name.split('.');
      this.uploadImage(value, name[0], 'french');
    },
    selectedImages (value) {
      this.validateFields();
      if (value !== null) {
        this.uploadImagesMenuImage();
      }
    },
    items (value) {
      this.validateFields();
      if (this.id !== null) {
        if (value.length > 0) {
          switch (value[0].type) {
            case 'image':
              this.changeTypeMenu('image');
              break;
            case 'custom':
              this.changeTypeMenu('custom');
              break;
            case 'url':
              this.changeTypeMenu('link');
              break;
            default:
              break;
          }
        }
      }
    },
    imagesSend () {
      this.validateFields();
    },
    selectCategories () {
      if (this.id !== null) {
        const findLabelCategory = this.selectCategories.find((category) => category.id === this.categoryMenu);
        if (findLabelCategory) {
          this.categoryMenuSelectedLabel = findLabelCategory.label;
        }
      }
    },
    urlMenu () {
      this.validateFields();
    },
    isProgrammingButton () {
      this.validateFields();
    },
    checkboxGroupDays () {
      this.validateFields();
    },
    startTime () {
      this.validateFields();
    },
    finalTime () {
      this.validateFields();
    },
    categoryMenu (value) {
      this.validateFields();
    },
    infoLocal: {
      handler (newValue) {
        // Verifica si alguna de las propiedades relevantes ha cambiado
        if (
          newValue.title !== this.oldInfoLocal?.title ||
          newValue.titleEnglish !== this.oldInfoLocal?.titleEnglish ||
          newValue.titleFrench !== this.oldInfoLocal?.titleFrench
        ) {
          this.validateFields();
        }
        // Actualiza el valor antiguo para la próxima comparación
        this.oldInfoLocal = { ...newValue };
      },
      deep: true, // Observa cambios profundos en el objeto
      immediate: true // Ejecuta el handler inmediatamente al montar el componente
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/menuQr/_modal_new_button.scss";
</style>
