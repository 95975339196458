<template>
  <div class="container-menu-qr-config__categories__card" :class="{'container-menu-qr-config__sub-categories__card' : isSubCategory}">
    <div class="container-menu-qr-config__categories__card__disabled-container" v-if="localCategory.active === 0">
    </div>
    <div class="container-menu-qr-config__categories__card__category-description">
      <div class="container-menu-qr-config__categories__card__category-description__lang">
        <div class="container-menu-qr-config__categories__card__category-description__lang__img-category-container" >
          <div class="container-menu-qr-config__categories__card__category-description__lang__img-category-container__title-menu" v-if="globalCategories">Menú: <b v-if="(isSubCategory && localCategory.category.vendorCustomName) || (!isSubCategory && localCategory.vendorCustomName)">{{isSubCategory && localCategory.category ? localCategory.category.vendorCustomName : localCategory.vendorCustomName}}</b><span v-else>Sin menú</span></div>
          <div class="container-menu-qr-config__categories__card__category-description__lang__img-category-container__title-menu" v-if="isSubCategory && localCategory.category">Categoría: <b>{{getNameDescriptionCategoryByLangForSubCategory()}}</b></div>
          <div
            v-if="localCategory.imageCategory && !isSubCategory"
            class="container-menu-qr-config__categories__card__category-description__lang__img-category-container__img"
            :style="`background-image: url('${localCategory.imageCategory}')`"
          ></div>
        </div>
        <SelectLanguages type="" @changeTypeLanguage="changeTypeLanguage" :notGlobal="true"/>
      </div>
      <div class="grid">
        <label for="">{{!isSubCategory ? 'Categoría' : 'Sub Categoría'}}</label>
        <b-input disabled type="text" :value="getNameDescriptionCategoryByLang('name')" class="input-grid-aux" />
      </div>
      <div class="grid">
        <label for="">Descripción</label>
        <b-input disabled type="textarea" :value="getNameDescriptionCategoryByLang('description')"></b-input>
      </div>
    </div>
    <div class="container-menu-qr-config__categories__card__options">
      <div class="" v-if="!isSubCategory">
        <div>
          <label for="">Subcategoría</label>
          <b>{{localCategory.children && localCategory.children.length > 0 ? 'Sí' : 'No'}}</b>
        </div>
        <div>
          <label for="">Productos</label>
          <b>{{localCategory.products}}</b>
        </div>
      </div>
      <div class="">
        <div v-if="!isSubCategory">
          <label for="">Orden</label>
          <b-input v-model="localCategory.order" @input="editCategory" />
        </div>
        <div v-else>
          <label for="">Productos</label>
          <b>{{localCategory.products}}</b>
        </div>
        <!-- <div v-if="isSubCategory">
          <label for="">Orden</label>
          <b-input v-model="category.order" @input="editCategory" />
        </div> -->
        <div>
          <label for="">{{localCategory.active === 1 ? 'Activa': 'Inactiva'}}</label>
          <b-checkbox v-model="localCategory.active" :true-value="1" :false-value="0" @input="editCategory" />
        </div>
      </div>
      <div class="" v-if="!isSubCategory">
        <div>
          <label for="">Recomendado</label>
          <b-checkbox v-model="localCategory.recommended" :true-value="1" :false-value="0" @input="editCategory"/>
        </div>
        <div>
          <label for="">Dos precios</label>
          <b-checkbox v-model="localCategory.twoPrices" :true-value="1" :false-value="0" disabled />
        </div>
      </div>
    </div>
    <div class="container-menu-qr-config__categories__card--actions">
      <b-button class="container-menu-qr-config__categories__card--actions__go-to-category" @click="$emit('setCategoryDetail', (isSubCategory ? category.category : category), category._id)">Ir a la {{isSubCategory ? 'sub' : ''}} categoría</b-button>
      <b-button class="container-menu-qr-config__categories__card--actions__delete" @click="deleteCategory">
        <img src="@/assets/icons/menuQr/trash.svg" alt="">
      </b-button>
    </div>
  </div>
</template>

<script>
import SelectLanguages from '@/components/schedules/menu-qr/complements/SelectLanguages.vue';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['category', 'restaurant', 'idMenuMultipleForCategoriesProducts', 'globalCategories', 'isSubCategory'],
  components: {
    SelectLanguages
  },
  data () {
    return {
      active: false,
      typeLang: 'spanish',
      localCategory: { ...this.category }
    };
  },
  computed: {
    getNameDescriptionCategoryByLangForSubCategory () {
      return () => {
        if (this.typeLang === 'spanish') {
          return this.category.category.name;
        }
        if (this.typeLang === 'english') {
          return this.category.category.nameEnglish;
        }
        if (this.typeLang === 'french') {
          return this.category.category.nameFrench;
        }
        return null;
      };
    },
    getNameDescriptionCategoryByLang () {
      return (type = 'name') => {
        if (type === 'name') {
          if (this.typeLang === 'spanish') {
            return this.localCategory.name;
          }
          if (this.typeLang === 'english') {
            return this.localCategory.nameEnglish;
          }
          if (this.typeLang === 'french') {
            return this.localCategory.nameFrench;
          }
        } else {
          if (this.typeLang === 'spanish') {
            return this.localCategory.description;
          }
          if (this.typeLang === 'english') {
            return this.localCategory.descriptionEnglish;
          }
          if (this.typeLang === 'french') {
            return this.localCategory.descriptionFrench;
          }
        }
        return null;
      };
    }
  },
  mounted () {
  },
  methods: {
    changeTypeLanguage (typeLang = 'spanish') {
      this.typeLang = typeLang;
    },
    deleteCategory () {
      var instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: AlertConfirmDelete,
        hasModalCard: true,
        canCancel: false,
        customClass: 'alert-confirm-delete alert-categories',
        props: {
          idMenu: null,
          type: 'delete',
          title: 'Confirmar',
          subtitle: `
            <div style="margin-bottom: 10px;">¿Estás seguro(a) que quieres eliminar esta ${this.isSubCategory ? 'sub' : ''}categoría?</div>
            <div style="font-size: 12px; padding: 0 10px"><b>Nota:</b> los productos que están asignados a esta ${this.isSubCategory ? 'sub' : ''}categoría, los encontrarás en la sección de productos, sin ${this.isSubCategory ? 'sub' : ''}categoría.</div>
          `,
          typeDelete: 'category'
        },
        events: {
          changeStatusLoader (status) {
            instance.$emit('changeStatusLoader', status);
          },
          deleteCategory () {
            instance.serviceDeleteCategory();
          }
        }
      });
    },
    serviceDeleteCategory () {
      this.$emit('changeStatusLoader', true);
      this.$emit('updateCategories', this.localCategory);
      serviceQr.delete(`/menuCustom/deleteCategory/${this.localCategory._id}/${this.restaurant.vendorId}/${this.user.id}`).then(() => {
        this.$emit('clearListCategories');
        this.$emit('getListCategories');
        /* this.$emit('changeStatusLoader', false); */
      }).catch(() => {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error',
          position: 'is-top',
          type: 'is-danger'
        });
        this.$emit('changeStatusLoader', false);
      });
    },
    editCategory () {
      this.$emit('updateCategories', this.localCategory);
      serviceQr.put(`/menuCustom/editCategory/${this.localCategory._id}`, this.localCategory).then((resp) => {
        if (resp.data.code === 200) {
          /* this.$emit('getListCategories'); */
          this.$buefy.toast.open({
            duration: 2000,
            message: `${this.isSubCategory ? 'Sub' : ''} Categoría actualizada`,
            position: 'is-top',
            type: 'is-success'
          });
        } else {
          this.$buefy.toast.open({
            duration: 2000,
            message: 'Ha ocurrido un error',
            position: 'is-top',
            type: 'is-danger'
          });
        }
      }).catch(() => {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error',
          position: 'is-top',
          type: 'is-danger'
        });
      });
    }
  },
  watch: {
    category () {
      this.localCategory = { ...this.category };
    },
    typeLang () {
      this.localCategory.typeLanguage = 'both';
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/menuQr/categories/_list_categories.scss";
</style>
