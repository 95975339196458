<template>
  <div id="modalCreateSubCategory">
    <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
    <div class="section-create-subcategory">
      <div class="section-create-subcategory__header">
        <h3>Crear Subcategoría</h3>
      </div>
      <div class="section-create-subcategory__second-info__subcategory">
        <h4>Estas creando una subcategoría en la categoría: <b>{{nameCategoryFromSectionCategorySubcategory}}</b></h4>
        <div class="section-create-subcategory__second-info__subcategory__details">
          <div class="section-create-subcategory__second-info__subcategory__details__status-subcategory">
            <label for="">Subcategoría activa</label>
            <b-checkbox v-model="dataFormSubCategory.active" :true-value="1" :false-value="0" />
          </div>
          <div class="section-create-subcategory__second-info__field">
            <label for="">Nombre de la subcategoría</label>
            <b-input placeholder="Escribe el nombre de la subcategoría" v-model="dataFormSubCategory.name" v-show="typeLang === 'spanish'" class="general-inputs" type="text" />
            <b-input placeholder="Escribe el nombre de la subcategoría" v-model="dataFormSubCategory.nameEnglish" v-show="typeLang === 'english'" class="general-inputs" type="text" />
            <b-input placeholder="Escribe el nombre de la subcategoría" v-model="dataFormSubCategory.nameFrench" v-show="typeLang === 'french'" class="general-inputs" type="text" />
          </div>
          <div class="section-create-subcategory__second-info__field">
            <label for="">Descripción de la categoría</label>
            <textarea placeholder="Escribe la descripción de la subcategoría" v-model="dataFormSubCategory.description" v-show="typeLang === 'spanish'"></textarea>
            <textarea placeholder="Escribe la descripción de la subcategoría" v-model="dataFormSubCategory.descriptionEnglish" v-show="typeLang === 'english'"></textarea>
            <textarea placeholder="Escribe la descripción de la subcategoría" v-model="dataFormSubCategory.descriptionFrench" v-show="typeLang === 'french'"></textarea>
          </div>
          <div class="section-create-subcategory__second-info__field section-create-subcategory__second-info__list-products">
            <label for="">Productos de la subcategoría</label>
            <div class="section-create-subcategory__second-info__list-products__container">
              <div class="section-create-subcategory__second-info__list-products__container__searcher">
                <div class="section-create-subcategory__second-info__list-products__container__searcher--check-massive">
                  <label for="">Activar</label>
                  <b-checkbox v-model="checkboxProductsSubCategoryMassive" @input="executeAlertAux('all-products')"></b-checkbox>
                </div>
                <div class="section-create-subcategory__second-info__list-products__container__searcher--search">
                  <i class="icon-mks icon_search_alternative"></i>
                  <b-input placeholder="Buscar por nombre" v-model="searchProductSubCategories"/>
                </div>
              </div>
              <div class="section-create-subcategory__second-info__list-products__container__list">
                <div class="section-create-subcategory__second-info__list-products__container__list__product" v-for="(product, index) in productsSubCategory" :key="index">
                  <b-checkbox v-model="checkboxGroupProductsSubCategory" :native-value="product._id" @input="(value)=>setProductsWithStructure(value, product._id)">
                    {{getProductNameByLang(product)}}
                  </b-checkbox>
                  <i class="icon-mks icon_trash_alternative" @click="executeAlertAux('subcategory', product._id)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-create-subcategory__second-info__actions">
          <button @click="executeModalCreateProducts()">
            <i class="icon-mks icon_plus_alternative"></i>
            <div>Crear productos</div>
          </button>
          <button :disabled="checkboxGroupProductsSubCategory.length === 0" @click="executeAlertAux('subcategory')">
            <i class="icon-mks icon_trash_alternative"></i>
            <div>Eliminar producto</div>
          </button>
        </div>
      </div>
      <div class="section-create-subcategory__main-actions">
        <b-button class="section-create-subcategory__main-actions__save" :disabled="!formIsValid" @click="createSubCategory()">Crear subcategoría</b-button>
        <b-button class="section-create-subcategory__main-actions__close" @click="$parent.close()">Cerrar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import CreateEditProduct from '@/components/schedules/menu-qr/complements/products/CreateEditProduct.vue';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: [
    'restaurant',
    'typeLang',
    'parentId',
    'idMenuMultipleForCategoriesProducts',
    'typeLangFromSectionCategorySubcategory',
    'nameCategoryFromSectionCategorySubcategory',
    'nameSubCategoryFromSectionCategorySubcategory'
  ],
  data () {
    return {
      isLoading: false,
      checkboxProductsSubCategoryMassive: [],
      searchProductSubCategories: '',
      checkboxGroupProductsSubCategory: [],
      checkboxGroupProductsSubCategoryStructured: [],
      productsSubCategory: [],
      dataFormSubCategory: {
        _id: null,
        name: null,
        nameEnglish: null,
        nameFrench: null,
        description: null,
        descriptionEnglish: null,
        descriptionFrench: null,
        active: 1,
        typeLanguage: 'both',
        order: 1,
        vendorId: this.restaurant.vendorId,
        parentId: this.parentId
      },
      timeoutSearcher: null
    };
  },
  computed: {
    formIsValid () {
      const hasValidNameAndDescription = (this.dataFormSubCategory.name !== null && this.dataFormSubCategory.name !== '');
      return hasValidNameAndDescription;
    },
    getProductNameByLang () {
      return (product) => {
        if (this.typeLang === 'spanish') {
          return product.name ? product.name : `(ES) ${product.nameEnglish ? product.nameEnglish : ''}`;
        }
        if (this.typeLang === 'english') {
          return product.nameEnglish ? product.nameEnglish : `(IN) ${product.name ? product.name : ''}`;
        }
        if (this.typeLang === 'french') {
          return product.nameFrench ? product.nameFrench : `(FR) ${product.name ? product.name : ''}`;
        }
        return '';
      };
    }
  },
  mounted () {
    this.listProductsSubcategory();
  },
  methods: {
    createSubCategory () {
      this.isLoading = true;
      this.dataFormSubCategory.userId = this.user.id;
      /*  let filteredProducts = [...this.checkboxGroupProductsSubCategoryStructured];
      filteredProducts = filteredProducts.filter((product) => product.isrelate === 1); */
      serviceQr.post('/menuCustom/category', this.dataFormSubCategory).then((resp) => {
        if (resp.data.code === 200) {
          this.$buefy.modal.open({
            parent: this,
            component: AlertConfirmDelete,
            hasModalCard: true,
            canCancel: false,
            customClass: 'alert-confirm-delete alert-success',
            props: {
              idMenu: null,
              type: 'success',
              title: '',
              subtitle: `
                La sucategoría: <br>
                <b>${this.dataFormSubCategory.name}</b><br>
                fue creada con éxito en la categoría ${resp.data.categoryName || ''}.<br>
                La puedes encontrar en el listado de subcategorías.
              `,
              typeDelete: ''
            }
          });
          if (this.checkboxGroupProductsSubCategoryStructured.length > 0) {
            const data = {
              productIds: this.checkboxGroupProductsSubCategoryStructured,
              categoryId: resp.data.data._id,
              menuId: this.idMenuMultipleForCategoriesProducts
            };
            serviceQr.post('/menuCustom/products/addCategory', data).then((response) => {
              if (response.status === 200) {
                this.isLoading = false;
                this.$emit('getListCategories');
                this.refreshListSubCategories(resp.data.data);
                setTimeout(() => {
                  this.$parent.close();
                }, 300);
              }
            });
          } else {
            this.isLoading = false;
            this.$parent.close();
          }
        } else {
          this.isLoading = false;
        }
      });
    },
    listProductsSubcategory () {
      this.isLoading = true;
      const data = {
        menuId: this.idMenuMultipleForCategoriesProducts,
        categoryId: null,
        q: this.searchProductSubCategories
      };
      serviceQr.post('/menuCustom/categories/list/products', data).then((resp) => {
        if (resp.status === 200) {
          this.isLoading = false;
          this.productsSubCategory = resp.data.data;
          this.setProductsWithStructureDefault(resp.data.data);
        }
      });
    },
    setProductsWithStructureDefault (data) {
      data.forEach(product => {
        this.checkboxGroupProductsSubCategoryStructured.push({
          id: product._id,
          isrelate: 0
        });
      });
    },
    setProductsWithStructure (products, id) {
      const existInChecksProducts = products.includes(id);
      const active = existInChecksProducts ? 1 : 0;
      const changeIsRelate = (structuredProducts) => {
        const productIndex = structuredProducts.findIndex((product) => product.id === id);
        if (productIndex !== -1) {
          structuredProducts[productIndex].isrelate = active;
        } else if (existInChecksProducts) {
          structuredProducts.push({ id, isrelate: active });
        }
      };
      changeIsRelate(this.checkboxGroupProductsSubCategoryStructured);
    },
    refreshListSubCategories (data) {
      const newSubCategory = {
        active: data.active,
        name: data.name,
        _id: data._id
      };
      this.$emit('pushNewSubcategoryToTheList', newSubCategory);
    },
    executeModalCreateProducts () {
      const instance = this;
      const props = {
        restaurant: instance.restaurant,
        product: null,
        type: 'create',
        idMenuMultipleForCategoriesProducts: instance.idMenuMultipleForCategoriesProducts,
        createProductGlobal: true,
        idCategoryFromCreateCategory: null,
        idSubcategoryFromCreateCategory: null,
        typeLangFromSectionCategorySubcategory: instance.typeLangFromSectionCategorySubcategory,
        nameCategoryFromSectionCategorySubcategory: instance.nameCategoryFromSectionCategorySubcategory,
        nameSubCategoryFromSectionCategorySubcategory: instance.dataFormSubCategory.name
      };
      this.$buefy.modal.open({
        parent: this,
        component: CreateEditProduct,
        hasModalCard: true,
        canCancel: false,
        customClass: 'modal-create-edit-products modal-create-edit-products-for-titles-category-subcategory',
        props: props,
        events: {
          listProductsCategorySubcategory () {
            instance.listProductsSubcategory();
          }
        }
      });
    },
    selectAllProducts (type = 'subcategory', active) {
      if (type === 'subcategory') {
        const products = this.productsSubCategory;
        const checkboxGroupProducts = this.checkboxGroupProductsSubCategory;
        const checkboxGroupProductsStructured = this.checkboxGroupProductsSubCategoryStructured;
        if (active) {
          products.forEach((product) => {
            if (!checkboxGroupProducts.includes(product._id)) {
              checkboxGroupProducts.push(product._id);
            }
            const productStruct = checkboxGroupProductsStructured.find((productS) => productS.id === product._id);
            if (productStruct) {
              productStruct.isrelate = 1;
            } else {
              checkboxGroupProductsStructured.push({
                id: product._id,
                isrelate: 1
              });
            }
          });
        } else {
          checkboxGroupProducts.length = 0;
          checkboxGroupProductsStructured.forEach((product) => {
            product.isrelate = 0;
          });
        }
      }
    },
    deleteProducts (id) {
      this.isLoading = true;
      let products = [id];
      if (id === null) {
        products = this.checkboxGroupProductsSubCategory;
      }
      const data = {
        productIds: products,
        vendorId: this.vendor.id,
        userId: this.user.id
      };
      serviceQr.post('/menuCustom/products/delete', data).then((response) => {
        if (response.status === 200) {
          this.listProductsSubcategory();
        }
        this.isLoading = false;
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Erro con la petición',
          type: 'is-danger'
        });
        this.isLoading = false;
      });
    },
    executeAlertAux (type, id = null) {
      const instance = this;
      let text = null;
      if (type === 'all-products') {
        text = '¿Estás seguro(a) que deseas activar <br> todos los productos?';
      } else {
        text = id === null
          ? `<div style="margin-bottom: 10px;">¿Estás seguro(a) que quieres eliminar los productos seleccionados?</div>
          <div><b>Nota:</b> al eliminar estos productos desaparecerán de la categoría y de la lista de productos.</div>`
          : `<div style="margin-bottom: 10px;">¿Estás seguro(a) que quieres eliminar este producto?</div>
          <div><b>Nota:</b> al eliminar este producto desaparecerá de la categoría y de la lista de productos.</div>`;
      }
      if ((type === 'all-products' && this.checkboxProductsSubCategoryMassive) || type !== 'all-products') {
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete',
          props: {
            idMenu: null,
            type: type === 'all-products' ? type : 'delete',
            title: type === 'all-products' ? 'ESTAS ACTIVANDO TODOS LOS PRODUCTOS' : 'CONFIRMAR',
            subtitle: text,
            typeDelete: type !== 'all-products' ? 'product' : ''
          },
          events: {
            checkProducts () {
              instance.checkboxProductsSubCategoryMassive = true;
              instance.selectAllProducts('subcategory', true);
            },
            inactiveCheckbox () {
              /* instance.selectAllProducts('subcategory', false); */
              instance.checkboxProductsSubCategoryMassive = false;
            },
            deleteProducts () {
              instance.deleteProducts(id);
            }
          }
        });
      }
    }
  },
  watch: {
    searchProductSubCategories () {
      if (this.timeoutSearcher) {
        clearTimeout(this.timeoutSearcher);
      }
      this.timeoutSearcher = setTimeout(() => {
        this.listProductsSubcategory();
      }, 1000);
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/menuQr/categories/_modal_create_subcategories.scss";
</style>
