<template>
  <div id="HoursC">
    <div class="mobile-timebar">
      <a @click="open = !open" class="btn">
        <i class="icon-mks white icon_filters"></i>
      </a>
      <ul :class="{open:open}" class="list-open" v-if="(isMap && isShowFilter) || (!isMap && !loading && isShowFilter)">
        <li
          :class="{ active: active == 1 }"
          @click.prevent.stop="setTimes('desayuno')"
          class="hour-btn-range"
          v-if="validateButtons('desayuno')"
        >Desayuno</li>
        <li
          :class="{ active: active == 2 }"
          @click.prevent.stop="setTimes('almuerzo')"
          class="hour-btn-range"
          v-if="validateButtons('almuerzo')"
        >Almuerzo</li>
        <li
          :class="{ active: active == 3 }"
          @click.prevent.stop="setTimes('cena')"
          class="hour-btn-range"
          v-if="validateButtons('cena')"
        >Cena</li>
      </ul>
    </div>
    <div class="big-content-time">
      <div class="hour-box-range" v-if="(isMap && isShowFilter) || (!isMap && !loading && isShowFilter)">
        <button
          :class="{ active: active == 1 }"
          @click.prevent.stop="setTimes('desayuno')"
          class="hour-btn-range"
          :style="{marginRight: (validateButtons('desayuno')) ? '2px' : 'unset'}"
          v-if="validateButtons('desayuno')"
        >Desayuno</button>
        <button
          :class="{ active: active == 2 }"
          @click.prevent.stop="setTimes('almuerzo')"
          class="hour-btn-range"
          :style="{marginRight: (validateButtons('almuerzo')) ? '2px' : 'unset'}"
          v-if="validateButtons('almuerzo')"
        >Almuerzo</button>
        <button
          :class="{ active: active == 3 }"
          @click.prevent.stop="setTimes('cena')"
          class="hour-btn-range"
          v-if="validateButtons('cena')"
        >Cena</button>
      </div>
      <div :class="{'hour-box': dates.length > 0}" class="columns mt-6" v-show="(!isMap && dates.length && !loading)">
        <div class="Nohay box-loading" v-show="loading">
          <i class="icon-mks icon_reservations loading rotating"></i>
          <p>Buscando horas ...</p>
        </div>
        <button
          :key="'hour-list#-' + index"
          :style="{ background: (isBlocked(date.dateComplete) && !isCheck(date.dateComplete)) ? '#FDDAE0' : (isBlocked(date.dateComplete) && isCheck(date.dateComplete)) ? '#444b57' : (!isBlocked(date.dateComplete) && isCheck(date.dateComplete)) ? vendorParams.colorDashboard : '#fff', color: (isBlocked(date.dateComplete) && isCheck(date.dateComplete)) ? '#fff' : (!isBlocked(date.dateComplete) && isCheck(date.dateComplete)) ? '#fff' : ''}"
          @click.prevent.stop="toggleSet(date.dateComplete)"
          class="hour-btn column"
          v-for="(date, index) in dates"
        >
          <div
            :style="{background: date.status == null ? '#ffbe2b':date.status == true ? '#47CC27' : '#F83037'}"
            class="dot"
          ></div>
          <div></div>
          <div>{{date.dateComplete | moment('hh:mm a')}}</div>
        </button>
      </div>
      <div class="columns is-centered " v-show="!isMap && dates.length && !loading" >
        <div class="m-hours">
          <span
            :key="'info-hours-' + index"
            class="font-size-readerHour paddingr-2"
            v-for="(lector,index) in readerHours"
          >
            <i :style="{color:lector.color}" class="fa fa-circle font-size-readerHour"></i>
            {{ lector.text }}
          </span>
        </div>
      </div>
      <div v-show="loading && !isMap" class="Nohay box-loading">
        <i class="icon-mks icon_reservations loading rotating"></i>
        <p>Buscando horas ...</p>
      </div>
      <div class="noReservations" v-if="!dates.length && !loading && !isMap">
        <i class="icon_clockAwaiting"></i>
        <p>No hay horas disponibles</p>
      </div>
    </div>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'TimeHours',
  data () {
    return {
      blockedListItems: [],
      loading: false,
      dates: [],
      active: 0,
      dayOfWeek: null,
      m: null,
      y: null,
      d: null,
      open: false,
      isShowFilter: false,
      readerHours: [
        { color: '#f83037', text: 'Ocupado' },
        { color: '#47cc27', text: 'Disponible' },
        { color: '#ffbe2b', text: 'Disponible para restaurante' }
      ]
    };
  },
  props: ['isMap', 'theActivate'],
  computed: {
    ...mapGetters({
      turns: reservationTypes.getters.theTurns,
      mealTypeDay: reservationTypes.getters.mealTypeDay
    })
  },
  mounted () {
    this.getTimesAvailabilityList();
    this.active = this.theActivate;
    this.isShowFilterButtons();
  },
  methods: {
    getTimesAvailabilityList () {
      this.loading = true;
      this.dates = [];
      if (this.selectedDate >= this.$moment().format('YYYY-MM-DD')) {
        this.dates = {};
        const timeHour = this.$moment(this.dateComplete).format('YYYY-MM-DD');
        let statusHours = [];
        Promise.all([
          this.$store.dispatch({ type: 'reservation:blockedHours', data: { vendorId: this.idVendor, date: this.selectedDate } })
            .then((response) => {
              this.blockedListItems = response.data.data;
            }).catch(() => {
              this.loading = false;
            }),
          this.$store.dispatch({
            type: 'reservation:availabilityDate',
            data: {
              vendorId: this.vendor.id,
              date: timeHour,
              isDashboard: 1,
              source: 'Hours'
            }
          }).then(({ data }) => {
            if (data.data.length !== 0) {
              statusHours = data.data;
            }
          }).catch(() => {
            this.loading = false;
          })
        ]).then(() => {
          const datesFinal = this.processTimeLocal();
          const hoursFinal = [];
          if (statusHours.length > 0) {
            statusHours.forEach((e) => {
              datesFinal.forEach((el) => {
                if (e.dateTime.includes(el)) {
                  hoursFinal.push({ dateComplete: el, status: e.status });
                }
              });
            });
          } else {
            // eslint-disable-next-line
            datesFinal.forEach((el) => {
              // eslint-disable-next-line
              if (e.dateTime.includes(el)) {
                // eslint-disable-next-line
                hoursFinal.push({ dateComplete: el, status: null })
              }
            });
          }
          this.dates = hoursFinal;
          if (this.active === 1) {
            this.setTimesDay('desayuno');
          } else if (this.active === 2) {
            this.setTimesDay('almuerzo');
          } else if (this.active === 3) {
            this.setTimesDay('cena');
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      } else {
        const datesFinal = this.processTimeLocal();
        const hoursFinal = [];
        datesFinal.forEach((el) => {
          hoursFinal.push({ dateComplete: el, status: null });
        });
        this.dates = hoursFinal;
        if (this.active === 1) {
          this.setTimesDay('desayuno');
        } else if (this.active === 2) {
          this.setTimesDay('almuerzo');
        } else if (this.active === 3) {
          this.setTimesDay('cena');
        }
        this.loading = false;
      }
    },
    processTimeLocal () {
      const datesFinal = [];
      const m = this.$moment(this.dateComplete).format('MM');
      this.m = this.$moment(this.dateComplete).format('MM');
      const y = this.$moment(this.dateComplete).format('YYYY');
      this.y = this.$moment(this.dateComplete).format('YYYY');
      const d = this.$moment(this.dateComplete).format('DD');
      this.d = this.$moment(this.dateComplete).format('DD');
      const dayOfWeek = this.$moment(this.dateComplete).format('d');
      this.dayOfWeek = this.$moment(this.dateComplete).format('d');
      if (Object.keys(this.timesDashboard.extraPublic).length > 0) {
        if (this.timesDashboard.extraPublic[this.selectedDate] !== undefined) {
          this.timesDashboard.extraPublic[this.selectedDate].forEach(function (element) {
            const hora = element.hora;
            const horas = hora.split(':');
            const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
            datesFinal.push(newDate);
          });
        } else if (this.timesDashboard[dayOfWeek].length > 0) {
          this.timesDashboard[dayOfWeek].forEach(function (element) {
            const hora = element.hora;
            const horas = hora.split(':');
            const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
            datesFinal.push(newDate);
          });
        }
      } else if (this.timesDashboard[dayOfWeek].length > 0) {
        this.timesDashboard[dayOfWeek].forEach(function (element) {
          const hora = element.hora;
          const horas = hora.split(':');
          const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
          datesFinal.push(newDate);
        });
      }
      return datesFinal;
    },
    isBlocked (timestamp) {
      return _.includes(this.blockedListItems, timestamp);
    },
    isCheck (timestamp) {
      return _.includes(this.dateFilterSelect, timestamp);
    },
    toggleSet (timestamp) {
      if (_.includes(this.dateFilterSelect, timestamp)) {
        const i = this.dateFilterSelect.indexOf(timestamp);
        this.dateFilterSelect.splice(i, 1);
      } else {
        this.$store.commit(reservationTypes.mutations.setDateFilterSelect, timestamp);
      }
    },
    validateButtons (tipo) {
      if (this.turns) {
        if (this.turns[tipo]) {
          if (this.turns[tipo][this.selectedDate] !== undefined) {
            return true;
          } else if (this.turns[tipo][this.dayOfWeek] !== undefined) {
            return true;
          }
        }
      }
      return false;
    },
    setTimes (tipo) {
      this.clear();
      const dayOfWeek = this.$moment(this.dateComplete).format('d');
      if (this.turns) {
        if (this.turns[tipo]) {
          if (this.turns[tipo][this.selectedDate] !== undefined) {
            this.turns[tipo][this.selectedDate].forEach((item) => {
              item.times.forEach((time) => {
                const hora = time.split(':');
                const newDate = `${this.y}-${this.m}-${this.d} ${hora[0]}:${hora[1]}:00`;
                this.toggleSet(newDate);
              });
            });
          } else if (this.turns[tipo][dayOfWeek] !== undefined) {
            this.turns[tipo][dayOfWeek].forEach((item) => {
              item.times.forEach((time) => {
                const hora = time.split(':');
                const newDate = `${this.y}-${this.m}-${this.d} ${hora[0]}:${hora[1]}:00`;
                this.toggleSet(newDate);
              });
            });
          }
        }
      }
      if (tipo === 'desayuno') { this.whrActiv(1); }
      if (tipo === 'almuerzo') { this.whrActiv(2); }
      if (tipo === 'cena') { this.whrActiv(3); }
      if (this.mealTypeDay !== tipo) this.$store.commit(reservationTypes.mutations.setMealTypeDay, tipo);
      else this.$store.commit(reservationTypes.mutations.setMealTypeDay, '');
    },
    setTimesDay (tipo) {
      if (this.dates.length > 0) {
        this.clear();
      }
      const dayOfWeek = this.$moment(this.dateComplete).format('d');
      if (this.turns) {
        if (this.turns[tipo]) {
          if (this.turns[tipo][this.selectedDate] !== undefined) {
            this.turns[tipo][this.selectedDate].forEach((item) => {
              item.times.forEach((time) => {
                const hora = time.split(':');
                const newDate = `${this.y}-${this.m}-${this.d} ${hora[0]}:${hora[1]}:00`;
                this.toggleSet(newDate);
              });
            });
          } else if (this.turns[tipo][dayOfWeek] !== undefined) {
            this.turns[tipo][dayOfWeek].forEach((item) => {
              item.times.forEach((time) => {
                const hora = time.split(':');
                const newDate = `${this.y}-${this.m}-${this.d} ${hora[0]}:${hora[1]}:00`;
                this.toggleSet(newDate);
              });
            });
          }
        }
      }
      if (tipo === 'desayuno') {
        this.active = 1;
        this.$emit('activatefilter', 1);
      }
      if (tipo === 'almuerzo') {
        this.active = 2;
        this.$emit('activatefilter', 2);
      }
      if (tipo === 'cena') {
        this.active = 3;
        this.$emit('activatefilter', 3);
      }
    },
    whrActiv (num) {
      if (this.active === num) {
        this.deSelAlmCen();
        this.$emit('activatefilter', 0);
      } else {
        this.active = num;
        this.$emit('activatefilter', num);
      }
    },
    deSelAlmCen () {
      this.active = 0;
      this.clear();
    },
    clear () {
      if (this.dates.length > 0) {
        this.dates.forEach((date) => {
          this.dateFilterSelect.splice(date, 1);
        });
      }
    },
    isShowFilterButtons () {
      const dayOfWeek = this.$moment(this.dateComplete).format('d');
      let hay = 0;
      if (this.turns) {
        const tipo = ['desayuno', 'almuerzo', 'cena'];
        tipo.forEach((type) => {
          if (this.turns[type] !== undefined) {
            if (this.turns[type][this.selectedDate] !== undefined) {
              hay++;
            } else if (this.turns[type][dayOfWeek] !== undefined) {
              hay++;
            }
          }
        });
      }
      if (hay > 0) {
        this.isShowFilter = true;
      } else {
        this.isShowFilter = false;
      }
    }
  },
  watch: {
    selectedDate () {
      this.active = 0;
      this.$emit('activatefilter', 0);
      this.$store.commit(reservationTypes.mutations.setResetDateFilterSelect);
      this.$store.commit(reservationTypes.mutations.setResetSectionFilterSelect);
      this.getTimesAvailabilityList();
      this.isShowFilterButtons();
    }
  }
};
</script>
<style lang="scss" scope>
#HoursC{
  height: 100%;
  .mt {
    margin: 6px 10px 6px 11px!important;
  }
}
.noReservations{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: 7px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #444B57;
  padding: 20px 0px;
  @media screen and (min-width:600px) {
    padding: 30px 0px;
  }
  i{
    width:80px ;
    height: 80px;
    display: block;
    background-color: #C6C8CC;
  }
}
.font-size-readerHour {
  font-size: 10px;
}
.paddingr-2 {
  padding-right: 4px;
}
.m-hours {
  display: flex;
  gap: 12px;
  margin: 10px 0px -7px;
  justify-content: center;
  span i{
    margin-right: 4px;
  }
}
.big-content-time{
  padding: 0 11px;
  @media screen and (max-width:768px) {
    padding: 0;
  }
}
.hour-box-range {
  border-radius: 4px;
  background: #ccc;
  width: calc(100%);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2px 2px;
  margin: 6px 0px 6px 0px;
  @media screen and (max-width:1024px){
    width: calc(100% - -3px);
  }
  @media screen and (max-width:1000px) {
    display: none;
  }
  .hour-btn-range {
    cursor: pointer;
    padding: 8px 20px;
    border: none;
    border-left: 0;
    background: #fff;
    border-radius: 4px;
    flex: 1;
    color: #606773;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 32px;
  }
  .active {
    border-radius: 4px;
    background: #444b57;
    color: #fff;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.mt-6 {
  margin-top: 6px !important;
}
#HoursC .hour-box {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  width: 100%;
  overflow-y: auto;
  margin-top: 10px!important;
  height: auto;
  border: 1px solid #ccc;
  margin: 5px;
  border-radius: 6px;
  background: rgba(68, 75, 87, 0.03);
  @media screen and (max-width:1100px) {
    grid-template-columns: repeat(3,1fr);
  }
  @media screen and (max-width:800px) {
    grid-template-columns: repeat(8,1fr);
  }
  @media screen and (max-width:600px) {
    grid-template-columns: repeat(4,1fr);
  }
  .hour-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 10px 8px;
    cursor: pointer;
    white-space: nowrap;
    height: 30px;
    font-size: 12px;
    .dot {
      width: 7px;
      height: 7px;
      border-radius: 100%;
      margin-right: 5px;
    }
  }
}
.mobile-timebar {
  display: none;
}
.for-mobile-times {
  .list-open {
    display: none;
  }
  .open {
    display: block;
  }
  .icon_filters {
    margin-right: -5px;
    width: 16px;
  }
  .mobile-timebar {
    position: fixed;
    top: 60px;
    right: 8px;
    padding: 5px;
    display: block;
    .btn {
      background: transparent;
      padding: 5px;
      right: 0;
      top: 0;
      position: absolute;
      border: 0;
      box-shadow: none;
    }
    .list-open {
      background: #fff;
      border-radius: 5px;
      top: 27px;
      position: absolute;
      right: 0;
      border: 1px solid #00000029;
      width: 131px;
      overflow: hidden;
      li {
        padding: 7px 10px;
        font-size: 14px;
        border-top: 1px solid #00000029;
        text-align: center;
        &.active {
          background: #444b57;
          color: #fff;
        }
        &:first-child {
          border-top: none;
        }
      }
    }
  }
}
.Nohay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &.box-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.calendar-bg {
      height: calc(100% - 2.5rem);
      position: absolute;
      width: calc(100% - 2rem);
      justify-content: center;
    }
    span {
      color: #fff;
      margin-top: 10px;
    }
  }
  .loading {
    width: 100px;
    height: 100px;
    background: rgba(67, 74, 86, 0.3);
    margin-bottom: 40px;
    margin-top: 20px;
  }
}
.big-content-time{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>
