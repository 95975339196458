<template>
  <div class="conventions-graph__detail-section">
  <h2>Convenciones</h2>
  <div ref="containerConventions" v-if="sectionId !== 3 && graph !== 'perTime'" class="conventions-graph__detail-section__container" :class="{'conventions-graph__detail-section__container__one-row' : onlyOneCol}" :style="`${dataConventionsArray && dataConventionsArray.length <= 6 ? 'grid-template-columns: 1fr;' : (!onlyOneCol && dataConventionsArray && dataConventionsArray.length <= 8 ? 'overflow: hidden!important;': '')}`">
    <div class="conventions-graph__detail-section__container__info" v-for="(data, index) in dataConventionsArray" :key="index">
      <div
        class="conventions-graph__detail-section__container__info__icon-bg"
        :style="`
          ${sectionId !== 4 && graph !== 'perQuantityReservations' && data.color ? `background-color:${data.color}!important` : ''};
          ${sectionId !== 4 && graph === 'perQuantityReservations' && colorsByDefault && index === 0 ? `background-color: ${colorsByDefault[0]}!important` :
            (sectionId !== 4 && graph === 'perQuantityReservations' && colorsByDefault && index === 1 ? `background-color: ${colorsByDefault[1]}!important` : ''
          )}
          ${sectionId === 4 && graph !== 'perRequeriments' && graph !== 'perEffectiveNotEffective' && graph !== 'quantityAllTotalReservations' && graph !== 'perNotEffective'
            ? `background-color:${getColorByNameDefault(data.name)}!important`
            : `background-color:${data.color}!important`
          };
        `"
      >
        <i
          class="conventions-graph__detail-section__container__info__icon-bg__mask"
          v-if="graph !== 'perRequeriments' ? getUrlImageReferrer(data.referrer) !== null : true"
          :style="`
            -webkit-mask-image: url('${graph !== 'perRequeriments' ? getUrlImageReferrer(data.referrer) : data.iconURL}');
            mask-image: url('${graph !== 'perRequeriments' ? getUrlImageReferrer(data.referrer) : data.iconURL}');
          `"
        >
        </i>
        <i
          class="conventions-graph__detail-section__container__info__icon-bg__mask"
          :style="`
            -webkit-mask-image: url('${getUrlImageReferrer(data.name)}');
            mask-image: url('${getUrlImageReferrer(data.name)}');
          `"
          v-else
        >
        </i>
      </div>
      <div
        v-if="(sectionId !== 4 && graph === 'perQuantityReservations') || graph === 'perEffectiveNotEffective'"
        class="conventions-graph__detail-section__container__info__people conventions-graph__detail-section__container__info__people__pie-chart"
      >
        {{data.name}}
      </div>
      <div v-if="sectionId !== 4 && (graph !== 'perQuantityReservations' && graph !== 'perRequeriments' && graph !== 'perEffectiveNotEffective')" class="conventions-graph__detail-section__container__info__people">
        {{ filtersBy === 1 ? (graph === 'perQuantityReservations' ? (Number(data?.value) || 0) : (Number(data?.reservations) || 0)) : (Number(data?.people) || 0) }}
      </div>
      <div v-if="graph === 'perRequeriments' || graph === 'perEffectiveNotEffective'" class="conventions-graph__detail-section__container__info__people">
        {{ Number(data?.value) || 0 }}
      </div>
      <div v-if="graph === 'perNotEffective' && sectionId === 4" class="conventions-graph__detail-section__container__info__people">
        {{ Number(secondFiltersBy === 1 ? (filtersBy === 1 ? data?.cancelled : data?.cancelledByPeople) : (filtersBy === 1 ? data?.noShow : data?.noShowByPeople)) || 0 }}
      </div>
      <div v-if="graph === 'quantityAllTotalReservations'" class="conventions-graph__detail-section__container__info__people">
        {{ Number(secondFiltersBy === 1 ? data?.total : data?.effective) || 0 }}
      </div>
      <div v-if="graph === 'perDay' && sectionId !== 4" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(filtersBy === 1 ? data?.reservations : data?.people) * 100) /
          (Number(getTotalsByType(dataConventions, filtersBy === 1 ? 'reservations' : 'people')) || 1)).toFixed(decimals)
        }}%
      </div>
      <div v-if="graph === 'perGroup' && sectionId !== 4" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(filtersBy === 1 ? data?.reservations : data?.people) * 100) /
          (Number(getTotalsByRange(dataConventions, filtersBy === 1 ? 'reservations' : 'people')) || 1)).toFixed(decimals)
        }}%
      </div>
      <div v-if="graph === 'perEffective' && sectionId !== 4" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(data?.value) * 100) /
          (Number(getTotalsByType(dataConventions, 'value')) || 1)).toFixed(decimals)
        }}%
      </div>
      <div v-if="graph === 'perNotEffective' && sectionId !== 4" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(data?.reservations) * 100) /
          (Number(getTotalsByType(dataConventions, 'reservations')) || 1)).toFixed(decimals)
        }}%
      </div>
      <div v-if="(graph === 'perRequeriments' || graph === 'perEffectiveNotEffective')" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(data?.value) * 100) /
          (generalData?.rows?.reduce((sum, objeto) => sum + (Number(objeto?.value) || 0), 0) || 1)).toFixed(2)
        }}%
      </div>
      <!-- Si la vista es generales -->
      <div v-if="(graph === 'quantityAllTotalReservations' && sectionId === 4) || graph === 'perQuantityReservations'" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(secondFiltersBy === 1 ? data?.total : data?.effective) * 100) /
          (generalData?.rows?.reduce((sum, objeto) => sum + (Number(secondFiltersBy === 1 ? objeto?.total : objeto?.effective) || 0), 0) || 1)).toFixed(2)
        }}%
      </div>
      <div v-if="(graph === 'perNotEffective' && sectionId === 4)" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(secondFiltersBy === 1 ? (filtersBy === 1 ? data?.cancelled : data?.cancelledByPeople) : (filtersBy === 1 ? data?.noShow : data?.noShowByPeople)) * 100) /
          (generalData?.rows?.reduce((sum, objeto) => sum + (Number(secondFiltersBy === 1 ? (filtersBy === 1 ? objeto?.cancelled : objeto?.cancelledByPeople) : (filtersBy === 1 ? objeto?.noShow : objeto?.noShowByPeople)) || 0), 0) || 1)).toFixed(2)
        }}%
      </div>
      <div v-if="sectionId === 4 && data?.name === 'reservations'" class="conventions-graph__detail-section__container__info__people">
        {{ Number(filtersBy === 1 ? generalData?.resevationReservations : generalData?.resevationPeople) || 0 }}
      </div>
      <div v-if="sectionId === 4 && data?.name === 'walkIn'" class="conventions-graph__detail-section__container__info__people">
        {{ Number(filtersBy === 1 ? generalData?.walkinReservations : generalData?.walkinPeople) || 0 }}
      </div>
      <div v-if="sectionId === 4 && data?.name === 'precompra'" class="conventions-graph__detail-section__container__info__people">
        {{ Number(filtersBy === 1 ? generalData?.prePurchaseReservations : generalData?.prePurchasePeople) || 0 }}
      </div>
      <div v-if="sectionId === 4 && data?.name === 'reservations'" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(filtersBy === 1 ? generalData?.resevationReservations : generalData?.resevationPeople) * 100) /
          (Number(filtersBy === 1 ? generalData?.reservas : generalData?.people) || 1)).toFixed(decimals)
        }}%
      </div>
      <div v-if="sectionId === 4 && data?.name === 'walkIn'" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(filtersBy === 1 ? generalData?.walkinReservations : generalData?.walkinPeople) * 100) /
          (Number(filtersBy === 1 ? generalData?.reservas : generalData?.people) || 1)).toFixed(decimals)
        }}%
      </div>
      <div v-if="sectionId === 4 && data?.name === 'precompra'" class="conventions-graph__detail-section__container__info__percent">
        {{
          ((Number(filtersBy === 1 ? generalData?.prePurchaseReservations : generalData?.prePurchasePeople) * 100) /
          (Number(filtersBy === 1 ? generalData?.reservas : generalData?.people) || 1)).toFixed(decimals)
        }}%
      </div>
    </div>
    <div v-if="((dataConventionsArray && dataConventionsArray.length > 6) && !onlyOneCol)" :style="`height: ${containerHeight}px`" class="conventions-graph__detail-section__separator"></div>
  </div>
  <div v-if="sectionId === 3 && walkingData" class="conventions-graph__detail-section__container no-before-fix" :style="`grid-template-columns: 1fr; overflow: hidden`">
    <div class="conventions-graph__detail-section__container__info">
      <div class="conventions-graph__detail-section__container__info__icon-bg" :style="`width: 20px;height: 24px;background-color: #8A51B4!important;`">
        <i
          class="icon_walkin big-walking icon-mks"
        ></i>
      </div>
      <div class="conventions-graph__detail-section__container__info__people">{{filtersBy === 1 ? walkingData.reservations : walkingData.people }}</div>
      <div class="conventions-graph__detail-section__container__info__percent" v-if="walkingData.dataPeopleYate === null">100%</div>
      <div class="conventions-graph__detail-section__container__info__percent" v-else>{{ (((filtersBy === 1 ? walkingData.reservations : walkingData.people ) * 100) / (filtersBy === 1 ? walkingData.dataReservationD : walkingData.dataPeopleD )).toFixed(decimals) }}%</div>
    </div>
    <div class="conventions-graph__detail-section__container__info" v-if="walkingData.dataPeopleYate !== null">
      <div class="conventions-graph__detail-section__container__info__icon-bg" :style="`width: 20px;height: 24px;background-color: rgb(0, 0, 153)!important;`">
        <i
          class="icon_yate icon-mks"
        ></i>
      </div>
      <div class="conventions-graph__detail-section__container__info__people">{{filtersBy === 1 ? walkingData.dataReservationYate : walkingData.dataPeopleYate }}</div>
      <div class="conventions-graph__detail-section__container__info__percent">{{ (((filtersBy === 1 ? walkingData.dataReservationYate : walkingData.dataPeopleYate ) * 100) / (filtersBy === 1 ? walkingData.dataReservationD : walkingData.dataPeopleD )).toFixed(decimals) }}%</div>
    </div>
  </div>
  <div v-if="sectionId === 4 && graph === 'perTime'" class="conventions-graph__detail-section__container no-before-fix" :style="`grid-template-columns: 1fr; overflow: hidden`">
    <div class="conventions-graph__detail-section__container__info">
      <div class="conventions-graph__detail-section__container__info__icon-bg default-conventions-prec" :style="`width: 37px;height: 24px;background-color: ${colorsByDefault.reservations}!important;`">
        <i
          class="icon-mks icon_reservation"
        ></i>
      </div>
      <div class="conventions-graph__detail-section__container__info__tag">Reservas</div>
    </div>
    <div class="conventions-graph__detail-section__container__info">
      <div class="conventions-graph__detail-section__container__info__icon-bg default-conventions-prec" :style="`width: 37px;height: 24px;background-color: ${colorsByDefault.prepurchase}!important;`">
        <i
          class="icon-mks icon_reserva_precompro"
        ></i>
      </div>
      <div class="conventions-graph__detail-section__container__info__tag">Precompras</div>
    </div>
    <div class="conventions-graph__detail-section__container__info">
      <div class="conventions-graph__detail-section__container__info__icon-bg default-conventions-prec" :style="`width: 37px;height: 24px;background-color: ${colorsByDefault.walkin}!important;`">
        <i
          class="icon-mks icon_metrics_walkin"
        ></i>
      </div>
      <div class="conventions-graph__detail-section__container__info__tag">Walkin</div>
    </div>
  </div>
  <div class="conventions-graph__detail-section__total" v-if="graph !== 'perTime' && graph !== 'perRequeriments'">
    <div>Total {{filtersBy === 1 ? 'reservas' : 'personas'}}</div>

    <span v-if="sectionId === 4 && (graph !== 'perEffectiveNotEffective' && (graph !== 'quantityAllTotalReservations' && graph !== 'perNotEffective'))">
      {{ Number(getTotalsGenerals(generalData, filtersBy)) || 0 }}
    </span>

    <span v-if="sectionId === 3">
      {{ filtersBy === 1 ? (Number(walkingData?.reservations) || 0) : (Number(walkingData?.people) || 0) }}
    </span>

    <span v-if="sectionId !== 4 && sectionId !== 3 && graph !== 'perQuantityReservations' && graph !== 'perEffectiveNotEffective'">
      {{
        (graph === 'perDay' || graph === 'perEffective') || (graph === 'perNotEffective')
          ? (Number(getTotalsByType(dataConventions, filtersBy === 1 ? 'reservations' : 'people')) || 0)
          : (Number(getTotalsByRange(dataConventions, filtersBy === 1 ? 'reservations' : 'people')) || 0)
      }}
    </span>

    <span v-if="(sectionId === 4 && graph === 'perNotEffective')">
      {{ dataConventions.reduce((sum, objeto) => sum + (Number(secondFiltersBy === 1 ? (filtersBy === 1 ? objeto?.cancelled : objeto?.cancelledByPeople) : (filtersBy === 1 ? objeto?.noShow : objeto?.noShowByPeople)) || 0), 0) }}
    </span>

    <span v-if="graph === 'perEffectiveNotEffective'">
      {{ dataConventions.reduce((sum, objeto) => sum + (Number(objeto?.value) || 0), 0) }}
    </span>

    <span v-if="(sectionId === 4 && graph === 'quantityAllTotalReservations') || graph === 'perQuantityReservations'">
      {{ dataConventions.reduce((sum, objeto) => sum + (Number(secondFiltersBy === 1 ? objeto?.total : objeto?.effective) || 0), 0) }}
    </span>
  </div>
</div>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'ConventionsVue',
  props: ['sectionId', 'filtersBy', 'secondFiltersBy', 'dataConventions', 'graph', 'type', 'walkingData', 'generalData', 'colorsByDefault', 'onlyOneCol'],
  data () {
    return {
      decimals: 1,
      containerHeight: 0,
      intervalContainerConventions: null
    };
  },
  computed: {
    dataConventionsArray () {
      if (this.graph === 'perDay') {
        if (this.sectionId === 4) {
          return this.getTotalsByRange(this.dataConventions);
        } else {
          return this.dataConventions;
        }
      }
      if (this.graph === 'perGroup') {
        return this.getTotalsByRange(this.dataConventions);
      }
      if (this.graph === 'perEffective' ||
        this.graph === 'perNotEffective' ||
        this.graph === 'perQuantityReservations' ||
        this.graph === 'perRequeriments' ||
        this.graph === 'perEffectiveNotEffective' ||
        this.graph === 'quantityAllTotalReservations'
      ) {
        return this.dataConventions;
      }
      return [];
    }
  },
  mounted () {
    this.calculateContainerHeight();
  },
  methods: {
    getColorByNameDefault (type) {
      if (this.colorsByDefault) {
        const colorMap = {
          Reservas: this.colorsByDefault.reservations,
          reservations: this.colorsByDefault.reservations,
          Precompras: this.colorsByDefault.prepurchase,
          precompra: this.colorsByDefault.prepurchase,
          'Walk-in': this.colorsByDefault.walkin,
          walkIn: this.colorsByDefault.walkin
        };
        return colorMap[type] || '';
      }
    },
    calculateContainerHeight () {
      this.intervalContainerConventions = setInterval(() => {
        const container = this.$refs.containerConventions;
        if (container) {
          this.containerHeight = container.scrollHeight;
          clearInterval(this.interval); // Detener el intervalo una vez encontrado
        }
      }, 100);
    },
    getTotalsGenerals (data, type) {
      if (type === 1) {
        return (parseInt(data.resevationReservations) + parseInt(data.walkinReservations) + parseInt(data.prePurchaseReservations));
      }
      return (parseInt(data.resevationPeople) + parseInt(data.walkinPeople) + parseInt(data.prePurchasePeople));
    },
    getTotalsByRange (ranges, type = null) {
      if (ranges === null || ranges === undefined) {
        return 0;
      }
      const nameMapping = {
        whitelabel: 'página web',
        dashboard: 'telefono'
      };
      const ageGroups = ['0-5', '6-12', '+12'];
      const newRanges = ranges.map((range) => {
        const name = nameMapping[range.name] || range.name;
        const sum = ageGroups.reduce((acc, group) => acc + (range?.dataReservation?.[group] || 0), 0);
        const sumPeople = ageGroups.reduce((acc, group) => acc + (parseInt(range?.dataPeople?.[group], 10) || 0), 0);
        return {
          name: range.name,
          reservations: sum,
          people: sumPeople,
          color: this.getColorByName(name)
        };
      });
      if (type === null) {
        return newRanges;
      }
      if (newRanges.length === 0) {
        return 0;
      }
      if (type === 'reservations') {
        return newRanges.reduce((a, b) => {
          return { reservations: a.reservations + b.reservations };
        }).reservations;
      }
      return newRanges.reduce((a, b) => {
        return { people: a.people + b.people };
      }).people;
    },
    getTotalsByType (list, type) {
      const total = _.sumBy(list, (data) => {
        if (typeof data[type] === 'string') {
          return parseInt(data[type]);
        }
        return data[type];
      });
      return total;
    }
  }
};
</script>

<style lang="scss">
  @use "@/assets/styles/metrics/charts/_conventions";
</style>
