<template>
  <nav
    :class="{ overHiden: $route.path.split('/')[2] === 'schedules'}"
    aria-label="main navigation"
    class="navbar"
    id="NavBarTop"
    role="navigation"
    v-if="vendor"
  >
    <div class="navbar-menu" v-if="$route.path.split('/')[2] === 'reservations'">
      <div class="navbar-start">
        <SummaryEl :fecha="`${this.y}-${this.m}-${this.d}`" :filterActivate="filterActivate"></SummaryEl>
        <div :class="{openSummary:filterActivate}" class="switch-views" v-if="showSwitchViews">
          <div :class="{ active: map }" @click.prevent.stop="changeView('map')" class="map">Mapa</div>
          <div
            :class="{ active: !map && mapList }"
            @click.prevent.stop="changeView('list')"
            class="liste"
          >Lista</div>
          <div
            :class="{ active: !map && !mapList }"
            @click.prevent.stop="changeView('Cronology')"
            class="liste"
          >Cronologia</div>
        </div>
        <section
          class="dayFilters"
          v-if="
(
            ((validateButtons('cena') && validateButtons('almuerzo')) ||
              (validateButtons('desayuno') && validateButtons('almuerzo')))) && showFilter
          "
        >
          <div
            :class="{ active: active === 1 }"
            @click.prevent.stop="setTimes('desayuno')"
            v-if="validateButtons('desayuno')"
          >
            <p>Desayuno</p>
            <i class="icon_morningFilter"></i>
          </div>
          <div
            :class="{ active: active === 2 }"
            @click.prevent.stop="setTimes('almuerzo')"
            v-if="validateButtons('almuerzo')"
          >
            <p>Almuerzo</p>
            <i class="icon_lunchFilter"></i>
          </div>
          <div
            :class="{ active: active === 3 }"
            @click.prevent.stop="setTimes('cena')"
            v-if="validateButtons('cena')"
          >
            <p>Cena</p>
            <i class="icon_dinnerFilter"></i>
          </div>
        </section>
      </div>
      <div class="navbar-end column columns">
        <div class="calendar-button">
          <a
            :class="{summaryOpen:filterActivate}"
            @click="toggleMenuC"
            class="navbar-link"
            id="open-button-calendar"
          >
            <button @click.stop="dayBefore">
              <div></div>
            </button>
            <div class="calendar_date" style="margin-right: 10px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none" class="svg-block-day" v-if="isBlockedDay">
                <circle cx="5.5" cy="5.5" r="5.5" fill="#FF0101"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.95773 4.2V3.925C6.95773 3.7431 6.91808 3.56492 6.84314 3.4C6.77504 3.25015 6.6778 3.11124 6.55468 2.99045C6.54165 2.97767 6.52833 2.9651 6.51473 2.95273C6.23108 2.69487 5.84637 2.55 5.44523 2.55C5.04409 2.55 4.65938 2.69487 4.37573 2.95273C4.36212 2.9651 4.34881 2.97767 4.33578 2.99045C4.21266 3.11124 4.11542 3.25015 4.04732 3.4C3.97237 3.56492 3.93273 3.7431 3.93273 3.925V4.2H6.95773ZM7.26769 5.06021L7.11594 5H3.77451L3.62276 5.06021C3.48743 5.1139 3.38388 5.19771 3.3167 5.29087C3.25039 5.38281 3.22045 5.48168 3.22023 5.57573V7.22435C3.22051 7.35106 3.27537 7.48981 3.4019 7.60484C3.53101 7.72221 3.72097 7.79968 3.93323 7.8C3.93311 7.8 3.93335 7.8 3.93323 7.8L6.95686 7.8C6.95673 7.8 6.95698 7.8 6.95686 7.8C7.16912 7.79968 7.35945 7.72221 7.48856 7.60484C7.61505 7.48984 7.66992 7.35114 7.67023 7.22445V5.57568C7.66999 5.48165 7.64005 5.38279 7.57376 5.29087C7.50658 5.19771 7.40302 5.1139 7.26769 5.06021ZM8.47023 5.575V7.225C8.46975 7.58954 8.31024 7.93902 8.0267 8.19679C7.74315 8.45456 7.35872 8.59956 6.95773 8.6H3.93273C3.53173 8.59956 3.1473 8.45456 2.86376 8.19679C2.58021 7.93902 2.42071 7.58954 2.42023 7.225V5.575C2.42061 5.30776 2.50665 5.04642 2.66782 4.82293C2.78851 4.65559 2.9474 4.51489 3.13273 4.40989C3.1949 4.37466 3.26005 4.34345 3.32773 4.3166V3.925C3.32773 3.41446 3.55082 2.92483 3.94793 2.56382C3.96148 2.5515 3.9752 2.53936 3.98908 2.52741C4.38183 2.1892 4.9028 2 5.44523 2C5.98766 2 6.50863 2.1892 6.90138 2.52741C6.91526 2.53936 6.92897 2.5515 6.94253 2.56382C7.33963 2.92483 7.56273 3.41446 7.56273 3.925V4.3166C7.63041 4.34345 7.69555 4.37466 7.75773 4.40989C7.94306 4.51489 8.10195 4.65559 8.22263 4.82293C8.3838 5.04642 8.46984 5.30776 8.47023 5.575Z" fill="white"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none" class="svg-block-day" v-if="isBlockedForHours">
                <path d="M7.57376 1.10578C8.64075 1.3181 9.62081 1.84204 10.39 2.61134C11.4209 3.64297 11.9999 5.04166 11.9999 6.50002C11.9999 7.95837 11.4209 9.35707 10.39 10.3887C9.62081 11.158 8.64075 11.6819 7.57376 11.8942C6.50677 12.1066 5.40078 11.9977 4.39565 11.5815C3.39053 11.1652 2.53143 10.4603 1.92699 9.55577C1.32256 8.65128 0.999939 7.58786 0.999939 6.50002C0.999939 5.41217 1.32256 4.34875 1.92699 3.44426C2.53143 2.53976 3.39053 1.83482 4.39565 1.41857C5.40078 1.00232 6.50677 0.893471 7.57376 1.10578Z" fill="#FF0101" stroke="white" stroke-width="0.5"/>
                <path d="M7.37076 8.98652C7.39888 9.03449 7.40701 9.09158 7.39339 9.14549C7.37977 9.19939 7.34549 9.24578 7.29797 9.27463C7.25044 9.30348 7.19347 9.31248 7.13936 9.29969C7.08525 9.2869 7.03834 9.25335 7.00876 9.20627L6.11774 7.55938C6.05134 7.43659 6.01595 7.29944 6.01466 7.15985L6.01466 3.9348C6.01797 3.881 6.04168 3.83048 6.08095 3.79354C6.12023 3.75661 6.17211 3.73605 6.22602 3.73605C6.27994 3.73605 6.33182 3.75661 6.37109 3.79354C6.41037 3.83048 6.43408 3.881 6.43739 3.9348L6.43739 7.15745C6.43779 7.22738 6.45506 7.29618 6.48773 7.35802L7.37076 8.98652Z" fill="#F44336" stroke="white" stroke-width="0.5"/>
              </svg>
              <i class="icon-mks white icon_calendar_voild voild-c" style="width: 25px; height: 25px; margin-right: 0px;"></i>
              <span class="the_calendar_day">{{ dateBar_day }}</span>
            </div>
            <div class="dates">{{ dateBar }}</div>
            <button @click.stop="dayAfter">
              <div></div>
            </button>
          </a>
          <div class="menu-wrap-calendar" :class="{'blocking': blockingTime}">
            <nav class="menu-calendar">
              <CalendarEl v-if="vendor"></CalendarEl>
            </nav>
          </div>
        </div>
        <ConnectionNotificationSearch :showMenuMobile="showMenuMobile" :filterActivate="filterActivate" @openSideBar="openSideBar" @openSearch="openSearch" @changeShowMobile="() => (showMenuMobile = !showMenuMobile)" />
      </div>
    </div>
    <div class="navbar-menu columns" v-if="$route.path.split('/')[2] === 'polls'">
      <Notifications class="border-left"></Notifications>
    </div>
    <div
      :class="{ open: !isOpenMobile }"
      class="btn-groupMobile"
      v-click-outside="() => (isOpenMobile = false)"
      v-if="
        $route.path.split('/')[2] === 'reservations' &&
        !frontModal &&
        !fromTimeLine
      "
    >
      <div class="groupMobile__container">
        <a @click="toggleFull" class="btn">
          <i class="icon_fullscream icon-mks white"></i>
        </a>
        <a @click="openSearch" class="btn">
          <i class="fa fa-search"></i>
        </a>
        <a
          :class="{ open: !isOpenMobile }"
          @click="() => (isOpenMobile = !isOpenMobile)"
          class="btn"
        >
          <i class="icon_buttonPlus"></i>
        </a>
      </div>
    </div>
    <div class="btns-metrics" v-if="$route.path.split('/')[2] === 'metrics_old'">
      <div class="general-container">
        <p class="text-general" v-if="sectionMetric !== 3 && sectionMetric !== 4">Datos Generales</p>
        <div class="text-center flex-center" v-if="sectionMetric !== 3 && sectionMetric !== 4">
          <i class="icon_person icon-mks white person margin-rigth"></i>
          <div>
            <p class="text-general big">{{ dataReservation.people }}</p>
            <p class="text-general small">Nro de Personas</p>
          </div>
        </div>
        <div class="text-center flex-center" v-if="sectionMetric !== 3 && sectionMetric !== 4">
          <i
            class="icon-mks icon_reservation white reservation margin-rigth"
            v-if="sectionMetric !== 2"
          ></i>
          <i
            class="icon-mks white margin-rigth icon_reserva_precompro precompra"
            v-if="sectionMetric == 2"
          ></i>
          <div>
            <p class="text-general big">{{ dataReservation.reservas }}</p>
            <p class="text-general small">Nro de Reservas</p>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <a :class="{ active: sectionMetric == 1 }" @click="activeMetrica(1)" class="bt">Reservas</a>
        <a :class="{ active: sectionMetric == 2 }" @click="activeMetrica(2)" class="bt">Precompras</a>
        <a :class="{ active: sectionMetric == 3 }" @click="activeMetrica(3)" class="bt">Walk-In</a>
        <a :class="{ active: sectionMetric == 4 }" @click="activeMetrica(4)" class="bt">Generales</a>
      </div>
    </div>
    <div
      class="navbar-menu columns_schedules"
      style="flex-direction: row !important"
      v-if="$route.path.split('/')[2] === 'schedules'"
    >
      <div
        :class="{ 'active-nav-item': this.page === 1 }"
        @click="handleChangePage({ id: '#', page: 1 })"
        class="itemsNavSchedules"
        id="schedulesType"
      >
        <button class="buttonModalMenu button" href="#">
          <i class="icon_schedules"></i>
          Horarios
        </button>
      </div>
      <div
        :class="{ 'active-nav-item': this.page === 3 }"
        class="itemsNavSchedules"
        v-if="
          parseInt(this.params.menuDigitalCarta) === 1 &&
          parseInt(this.params.optionCustomMenu) === 1
        "
      >
        <button
          @click="handleChangePage({ id: '#menuCustom', page: 3 })"
          class="buttonModalMenu button"
          id="menuCustom"
        >
          <i class="icon-menu"></i>
          Menú
        </button>
      </div>
      <div :class="{ 'active-nav-item': this.page === 4 }" class="itemsNavSchedules">
        <button
          @click="handleChangePage({ id: '#qrView', page: 4 })"
          class="buttonModalMenu button"
          id="qrView"
        >
          <i class="icon-menu"></i>
          QR
        </button>
      </div>
      <div :class="{ 'active-nav-item': this.page === 5 }" class="itemsNavSchedules">
        <button
          @click="handleChangePage({ id: '#popUp', page: 5 })"
          class="buttonModalMenu button"
          id="popUp"
        >
          <i class="icon-menu"></i>
          Pop Up
        </button>
      </div>
      <div
        :class="{ 'active-nav-item': this.page === 15 }"
        class="itemsNavSchedules"
        v-if="this.params.activateIntegrationsModule"
      >
        <button
          @click="handleChangePage({ id: '#popUp', page: 15 })"
          class="buttonModalMenu button"
          id="popUp"
        >
          <i class="icon-menu"></i>
          Integraciones
        </button>
      </div>
      <div
        :class="{ 'active-nav-item': this.page === 16 }"
        class="itemsNavSchedules"
        v-if="this.params.activateHostDashboard === 1"
      >
        <button
          @click="handleChangePage({ id: '#host', page: 16 })"
          class="buttonModalMenu button"
          id="host"
        >
          <i class="icon-menu"></i>
          Host
        </button>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import metricsTypes from '@/store/types/metrics';
import SummaryEl from '@/components/_shared/Summary';
import Notifications from '@/components/_shared/Notifications';
import ConnectionNotificationSearch from '@/components/_shared/ConnectionNotificationSearch';
import reservationTypes from '@/store/types/reservation';
import Vue from 'vue';
import CalendarEl from '@/components/reservations/Calendar';
import globalTypes from '@/store/types/global';
import _ from 'lodash';
export default {
  name: 'ToolbarVue',
  props: ['filterActivate'],
  components: { SummaryEl, Notifications, CalendarEl, ConnectionNotificationSearch },
  data () {
    return {
      interval: null,
      interval2: null,
      interval3: null,
      isOpenL: false,
      dateBar: null,
      dateBar_day: null,
      listLength: 0,
      isOpenMobile: false,
      sectionMetric: 1,
      activeButtonMenu: false,
      activeButtonMenu2: false,
      page: 1,
      windowsize: 0,
      showMenuMobile: false,
      dayOfWeek: null,
      active: 0,
      m: null,
      y: null,
      d: null,
      isBlockedDay: false,
      isBlockedForHours: false
    };
  },
  mounted () {
    this.getListLength();
    const today = this.$moment().format('YYYY-MM-DD');
    this.dayOfWeek = this.$moment(this.selectedDate).format('d');
    this.$store.commit(reservationTypes.mutations.setSelectedDate, today);
    this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
    if (window.innerWidth < 900 && window.innerWidth > 500) {
      this.dateBar = this.$moment(this.selectedDate).format('dddd DD MMMM');
    } else {
      this.dateBar = this.$moment(this.selectedDate).format('dddd DD[/]MMM');
    }

    this.dateBar_day = this.$moment(this.selectedDate).format('DD');
    if (this.$route.path.split('/')[2] === 'reservations') {
      var reservationscontent = document.getElementById('Content-reservations');
      // eslint-disable-next-line
      classie.add(reservationscontent, "show-map");
      this.$store.dispatch({
        type: 'reservation:reservationsDay',
        data: {
          vendorId: this.$store.state.vendorId,
          month: this.selectedMonth
        }
      });
      this.$store.dispatch({
        type: 'reservation:reservations',
        data: {
          vendorId: this.$store.state.vendorId,
          date: this.selectedDate
        }
      });
      this.$store.dispatch({
        type: 'reservation:notifications',
        data: {
          vendorId: this.$store.state.vendorId,
          userId: this.encrypt(this.user.id)
        }
      });
    }
    this.interval3 = setInterval(() => {
      Vue.nextTick()
        .then(() => new Promise((resolve) => setTimeout(resolve, 400)))
        .then(() => {
          if (this.getStatusOnline) {
            this.$store.dispatch({
              type: 'auth:refreshUser',
              data: {
                userId: this.user.id
              }
            });
          }
        });
    }, 30000);

    this.interval2 = setInterval(() => {
      Vue.nextTick()
        .then(() => new Promise((resolve) => setTimeout(resolve, 100)))
        .then(() => {
          if (
            (window.localStorage.getItem('_userId') === undefined || window.localStorage.getItem('_userId') === null) ||
            (window.localStorage.getItem('_user') === undefined || window.localStorage.getItem('_user') === null) ||
            (window.localStorage.getItem('_token') === undefined || window.localStorage.getItem('_token') === null)
          ) {
            this.logout({ id: this.user.id });
          }
        });
    }, 500);
  },
  created () {
    this.active = this.filterActivate;
    this.m = this.$moment(this.dateComplete).format('MM');
    this.y = this.$moment(this.dateComplete).format('YYYY');
    this.d = this.$moment(this.dateComplete).format('DD');
    const vueContext = this;
    window.setInterval(this.getListLength, 450);
    this.windowsize = window.innerWidth;
    window.addEventListener('resize', function () {
      vueContext.windowsize = window.innerWidth;
    });
    this.isBlockedDay = false;
    this.isBlockedForHours = false;
    const dateFormatted = parseInt(this.$moment(this.selectedDate).minutes(0).hours(0).seconds(0).milliseconds(0).format('x'));
    this.isBlockedDay = this.getBlocked(dateFormatted);
    if (!this.isBlockedDay) {
      this.isBlockedForHours = this.getHoursBlocked(dateFormatted);
    }
  },
  methods: {
    openSideBar () {
      this.$store.commit(globalTypes.mutations.setShowSideBar, true);
    },
    dayBefore () {
      this.$store.commit(reservationTypes.mutations.setType, '');
      this.$store.commit(reservationTypes.mutations.setInfoReservation, null);
      this.$store.commit(reservationTypes.mutations.setViewReservation, false);
      const result = this.$moment(this.selectedDate).subtract(1, 'days');
      const date = result.format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, date);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
      this.processChangeDate(date);
    },
    dayAfter () {
      this.$store.commit(reservationTypes.mutations.setType, '');
      this.$store.commit(reservationTypes.mutations.setInfoReservation, null);
      this.$store.commit(reservationTypes.mutations.setViewReservation, false);
      const result = this.$moment(this.selectedDate).add(1, 'days');
      const date = result.format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, date);
      this.processChangeDate(date);
    },
    processChangeDate (date) {
      this.$store.commit(
        reservationTypes.mutations.setLoadingReservation,
        true
      );
      this.$store.dispatch({
        type: 'reservation:reservationsDay',
        data: {
          vendorId: this.$store.state.vendorId,
          month: this.selectedMonth
        }
      });
      this.$store
        .dispatch({
          type: 'reservation:reservations',
          data: { vendorId: this.$store.state.vendorId, date: date }
        })
        .finally(() => {
          this.loader = false;
          this.$store.commit(
            reservationTypes.mutations.setLoadingReservation,
            false
          );
        })
        .then(() => { })
        .catch(() => {
          this.show = true;
          this.$store.commit(
            reservationTypes.mutations.setLoadingReservation,
            false
          );
        });
    },
    handleChangePage (value) {
      this.page = value.page;
      value.id === '#menuCustom'
        ? (this.activeButtonMenu = true)
        : (this.activeButtonMenu = false);
      value.id === '#qrView'
        ? (this.activeButtonMenu2 = true)
        : (this.activeButtonMenu2 = false);
      this.$emit('showPage', value);
    },
    getListLength () {
      this.listLength = window.localStorage.getItem('listLength');
    },
    TogleMobile () {
      this.isOpenMobile = !this.isOpenMobile;
    },
    isResponsive () {
      if (window.innerWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    whrActiv (num) {
      if (this.active === num) {
        this.active = 0;
        this.$emit('activateFilterF', this.active);
        this.$store.commit(reservationTypes.mutations.setResetDateFilterSelect);
      } else {
        this.active = num;
        this.$emit('activateFilterF', this.active);
      }
    },
    toggleFull () {
      this.$emit('change-full', true);
    },
    toggleMenuC () {
      var bodyEl = document.body;
      if (bodyEl.classList.contains('show-menu-calendar')) {
        // eslint-disable-next-line
        classie.remove(bodyEl, "show-menu-calendar");
      } else {
        // eslint-disable-next-line
        classie.add(bodyEl, "show-menu-calendar");
      }
      this.isOpenC = !this.isOpenC;
    },
    toggleMenuL () {
      this.$store.commit(
        reservationTypes.mutations.setSideLateral,
        !this.sidelateral
      );
    },
    openSearch () {
      this.$store.commit(reservationTypes.mutations.setSideLateralSearch, true);
    },
    setEditMapToolbar (value) {
      this.$store.commit(globalTypes.mutations.setEditMap, value);
    },
    validateButtons (tipo) {
      if (this.turns) {
        if (this.turns[tipo]) {
          if (this.turns[tipo][this.selectedDate] !== undefined) {
            return true;
          } else if (this.turns[tipo][this.dayOfWeek] !== undefined) {
            return true;
          }
        }
      }
      return false;
    },
    setTimes (tipo) {
      this.$store.commit(reservationTypes.mutations.setResetDateFilterSelect);
      const dayOfWeek = this.$moment(this.dateComplete).format('d');
      if (this.turns) {
        if (this.turns[tipo]) {
          if (this.turns[tipo][this.selectedDate] !== undefined) {
            this.turns[tipo][this.selectedDate].forEach((item) => {
              item.times.forEach((time) => {
                const hora = time.split(':');
                const newDate = `${this.y}-${this.m}-${this.d} ${hora[0]}:${hora[1]}:00`;
                this.toggleSet(newDate);
              });
            });
          } else if (this.turns[tipo][dayOfWeek] !== undefined) {
            this.turns[tipo][dayOfWeek].forEach((item) => {
              item.times.forEach((time) => {
                const hora = time.split(':');
                const newDate = `${this.y}-${this.m}-${this.d} ${hora[0]}:${hora[1]}:00`;
                this.toggleSet(newDate);
              });
            });
          }
        }
      }
      if (tipo === 'desayuno') {
        this.whrActiv(1);
      }
      if (tipo === 'almuerzo') {
        this.whrActiv(2);
      }
      if (tipo === 'cena') {
        this.whrActiv(3);
      }
      if (this.mealTypeDay !== tipo) this.$store.commit(reservationTypes.mutations.setMealTypeDay, tipo);
      else this.$store.commit(reservationTypes.mutations.setMealTypeDay, '');
    },
    toggleSet (timestamp) {
      if (_.includes(this.dateFilterSelect, timestamp)) {
        const i = this.dateFilterSelect.indexOf(timestamp);
        this.dateFilterSelect.splice(i, 1);
      } else {
        this.$store.commit(
          reservationTypes.mutations.setDateFilterSelect,
          timestamp
        );
      }
    },
    changeView (type) {
      const reservationscontent = document.getElementById(
        'Content-reservations'
      );
      switch (type) {
        case 'list':
          // eslint-disable-next-line
          classie.remove(reservationscontent, "show-map");
          this.$store.commit(reservationTypes.mutations.setSelectTableId, '');
          this.$store.commit(reservationTypes.mutations.setSelectTableInfo, '');
          this.$store.commit(reservationTypes.mutations.setMap, false);
          this.$store.commit(reservationTypes.mutations.setList, true);
          break;
        case 'map':
          // eslint-disable-next-line
          classie.add(reservationscontent, "show-map");
          this.$store.commit(reservationTypes.mutations.setList, false);
          this.$store.commit(reservationTypes.mutations.setMap, true);
          break;
        case 'Cronology':
          // eslint-disable-next-line
          classie.remove(reservationscontent, "show-map");
          this.$store.commit(reservationTypes.mutations.setList, false);
          this.$store.commit(reservationTypes.mutations.setMap, false);
          break;
      }
    },
    activeMetrica (section) {
      this.$emit('sectionId', section);
      this.sectionMetric = section;
    },
    logoutWS (idUser) {
      if (parseInt(this.user.id) === parseInt(idUser)) {
        this.logout({
          id: parseInt(this.user.id)
        });
      }
    }
  },
  destroyed () {
    clearInterval(this.interval);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
    const vueContext = this;
    window.removeEventListener('resize', function () {
      vueContext.windowsize = window.innerWidth;
    });
  },
  watch: {
    filterActivate (value) {
      this.active = value;
    },
    selectedDate () {
      this.isBlockedDay = false;
      this.isBlockedForHours = false;
      this.m = this.$moment(this.dateComplete).format('MM');
      this.y = this.$moment(this.dateComplete).format('YYYY');
      this.d = this.$moment(this.dateComplete).format('DD');
      this.dayOfWeek = this.$moment(this.selectedDate).format('d');
      this.dateBar = this.$moment(this.selectedDate).format('dddd DD[/]MMM');
      this.dateBar_day = this.$moment(this.selectedDate).format('DD');
      this.$store.commit(reservationTypes.mutations.setResetDateFilterSelect);
      const dateFormatted = parseInt(this.$moment(this.selectedDate).minutes(0).hours(0).seconds(0).milliseconds(0).format('x'));
      this.isBlockedDay = this.getBlocked(dateFormatted);
      if (!this.isBlockedDay) {
        this.isBlockedForHours = this.getHoursBlocked(dateFormatted);
      }
    },
    blocked () {
      this.isBlockedDay = false;
      this.isBlockedForHours = false;
      const dateFormatted = parseInt(this.$moment(this.selectedDate).minutes(0).hours(0).seconds(0).milliseconds(0).format('x'));
      this.isBlockedDay = this.getBlocked(dateFormatted);
      if (!this.isBlockedDay) {
        this.isBlockedForHours = this.getHoursBlocked(dateFormatted);
      }
    }
  },
  computed: {
    ...mapGetters({
      turns: [reservationTypes.getters.theTurns],
      dataReservation: [metricsTypes.getters.dataReservation],
      frontModal: [reservationTypes.getters.frontModal],
      fromTimeLine: [reservationTypes.getters.fromTimeLine],
      showSide: [globalTypes.getters.showSideBar],
      mealTypeDay: reservationTypes.getters.mealTypeDay
    }),
    showSwitchViews () {
      if (this.windowsize > 1200) {
        return true;
      } else if (this.windowsize < 1200 && this.showMenuMobile) {
        return true;
      } else {
        return false;
      }
    },
    showFilter () {
      if (this.windowsize > 600) {
        return true;
      } else if (this.windowsize <= 600 && this.showMenuMobile) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="scss">
.svg-block-day {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 0px;
  left: -4px;
  z-index: 1;
}
.tooltip-inner {
  border-radius: 4px !important;
  background-color: white !important;
  border: unset !important;
  border: solid 1px #444b57 !important;
  transform: translateX(25%);
  color: #444b57 !important;
  text-align: center !important;
  font-size: 14px !important;
  font-family: Source Sans Pro !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.611) !important;
  &::after {
    position: absolute;
    width: 10px;
    height: 10px;
    content: " ";
    display: block;
    background-color: white;
    top: -5px;
    left: 22%;
    border-radius: 32%;
    transform: rotate(45deg);
    border-bottom: unset;
    border-right: unset;
  }
  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: " ";
    display: block;
    background-color: white;
    top: -6px;
    left: 22%;
    border-radius: 32%;
    transform: rotate(45deg);
    border-bottom: unset;
    border-right: unset;
    background-color: #444b57;
  }
}
.tooltip-arrow {
  display: none !important;
}
.navbar-menu.columns_schedules {
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    height: fit-content;
    & > div {
      max-width: 15%;
      flex: 0.25;
      button {
        font-size: 12px !important;
        .icon_schedules {
          transform: translateX(4px) scale(0.7);
        }
      }
    }
  }
}
#NavBarTop {
  height: 52px;
  .iconHeader {
    border-radius: 50%;
    background-color: white;
    width: fit-content;
    position: absolute;
    left: 10px;
    z-index: 100;
    width: 30px;
    height: 30px;
    @media screen and (min-width: 600px) {
      display: none;
    }
    img {
      object-fit: cover;
      cursor: pointer;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  @media screen and (max-width: 900px) {
    height: 104px;
    min-height: 104px;
  }
  @media screen and (max-width: 600px) {
    height: 80px;
    min-height: 80px;
  }
}
.navbar {
  align-items: center !important;
  .navbar-menu {
    align-items: stretch;
    display: flex;
    width: 100%;
    background-color: unset;
    padding: unset;
    height: 100%;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      gap: 3px;
      justify-content: center;
      &.columns_schedule {
        flex-direction: row;
        gap: unset;
      }
    }
    @media screen and (max-width: 600px) {
      /* justify-content: flex-end; */
    }
    .lastContentNavbar {
      @media screen and (min-width: 600px) and (max-width: 900px) {
        width: 100%;
        position: absolute;
        justify-content: flex-end;
        .connectionContainer {
          position: absolute;
          left: 12px;
        }
        .controlContainer {
          position: absolute;
          width: 26px;
          height: 33px;
          right: 12px;
          bottom: -48px;
        }
      }
      @media screen and (max-width: 600px) {
        width: 100%;
        justify-content: flex-end;
        gap: 14px;
        .connectionContainer {
          transform: scale(0.8);
          position: absolute;
          left: 53px;
        }
      }
    }
    .navbar-end {
      @media screen and (min-width: 900px) and (max-width: 1200px) {
        flex: 0.55;
        #open-button-calendar {
          width: 250px;
          &.summaryOpen {
            margin-left: 37px;
          }
        }
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        min-width: 100%;
        position: relative;
        justify-content: center;
        .calendar-button {
          z-index: 10;
        }
        #open-button-calendar {
          width: 45vw;
          height: 40px;
          .dates {
            font-size: 16px;
            padding-left: 8px;
          }
          .calendar_date {
            i {
              height: 29px;
              width: 29px;
            }
            span {
              text-align: center;
              font-size: 15px;
              left: 22%;
              font-family: Source Sans Pro;
              font-weight: 500;
            }
          }
        }
      }
      @media screen and (max-width: 600px) {
        max-height: 40px;
        border-bottom: 1px solid #525B69;
        padding-top: 4px!important;
        padding-bottom: 4px!important;
        #open-button-calendar {
          width: 55vw;
          max-height: 34px;
          .calendar_date {
            i {
              height: 20px;
              width: 20px;
            }
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
    .navbar-start {
      max-height: 52px;
      @media screen and (min-width: 900px) and (max-width: 1200px) {
        flex: 0.55;
        #open-button-calendar {
          width: 250px;
          &.summaryOpen {
            margin-left: 35px;
          }
        }
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        min-width: 100%;
        #open-button-calendar {
          width: 250px;
          margin-left: 35px;
        }
      }
      @media screen and (max-width: 600px) {
        max-height: 42px;
      }
      .dayFilters {
        width: fit-content;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f3f3f3;
        gap: 2px;
        padding: 2px;
        border-radius: 4px;
        @media screen and (min-width: 900px) {
          display: none;
        }
        @media screen and (max-width: 900px) and (min-width: 600px) {
          margin-right: 46px;
        }
        div {
          height: 100%;
          width: 40px;
          background-color: white;
          border-radius: 4px;
          cursor: pointer;
          p {
            color: #444b57;
            text-align: center;
            font-size: 14px;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            @media screen and (min-width: 600px) {
              display: none;
            }
          }
          i {
            cursor: pointer;
            display: block;
            mask-size: 55%;
            mask-position: center;
            mask-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-color: #444b57;
            @media screen and (max-width: 600px) {
              display: none;
            }
            &.icon_dinnerFilter {
              mask-size: 45%;
            }
          }
          &.active {
            background-color: #444b57;
            i {
              background-color: white;
            }
            p {
              color: white;
              font-weight: 600;
            }
          }
        }
        @media screen and (max-width: 600px) {
          position: absolute;
          right: 0;
          top: 145px;
          flex-direction: column;
          width: 100px;
          background: #f3f3f3;
          height: fit-content;
          padding-top: 20px;
          &::after {
            position: absolute;
            top: 10px;
            border-radius: 2px;
            content: " ";
            width: 76%;
            height: 2px;
            background: #444b57;
          }
          div {
            width: 100%;
            min-height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              height: 20px;
              width: 100%;
              mask-size: center;
              mask-size: contain;
              &.icon_dinnerFilter {
                mask-size: 19%;
              }
            }
          }
        }
      }
    }
    .switch-views {
      @media screen and (min-width: 1200px) {
        /* margin-right: 27px; */
      }
      @media screen and (max-width: 1200px) {
        position: absolute;
        top: 52px;
        right: 0;
        flex-direction: column;
        width: 100px;
        height: fit-content;
        div {
          width: 100%;
          height: 32px;
        }
      }
      @media screen and (max-width: 900px) {
        top: 105px;
      }
      @media screen and (max-width: 600px) {
        z-index: 10000;
        top: 44px;
      }
      &.openSummary {
        @media screen and (min-width: 1200px) {
          position: relative;
          margin-right: 0px;
          left: 15px;
        }
      }
      @media screen and (min-width: 1800px) {
        margin-right: 140px;
        &.openSummary {
          margin-right: 140px;
        }
      }
      @media screen and (min-width: 1400px) {
        margin-right: 90px;
        &.openSummary {
          margin-right: 70px;
        }
      }
    }
  }
  .btn-groupMobile {
    z-index: 100;
    position: absolute;
    top: 46px;
    right: 7px;
    @media screen and (min-width: 600px) {
      display: none;
    }
    .groupMobile__container {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: center;
      gap: 6px;
      .btn {
        filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.3));
        background: #444b57;
        border: 1px solid #79808d;
        border-radius: 4px;
        width: 31px;
        height: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:nth-child(2) {
          i {
            width: unset;
            height: unset;
            background-color: unset;
          }
        }
        &:nth-child(3):not(.open) {
          background: #e0e0e0;
          & i {
            transform: rotate(45deg);
            background-color: #444b57;
          }
        }
        i {
          font-size: 12px;
          font-weight: 600;
          line-height: 1;
          color: white;
          margin: unset;
          mask-size: 40%;
          mask-repeat: no-repeat;
          mask-position: center;
          background-color: white;
          width: 100%;
          height: 100%;
          &.plus {
            font-size: 26px;
          }
        }
      }
    }
    &.open {
      .groupMobile__container {
        .btn:nth-child(1),
        .btn:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
.overHiden {
  overflow: hidden !important;
}
.active-nav-item {
  background: #373d46 !important;
}
.buttonModalMenu {
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  font-size: 18px !important;
  color: #fff !important;
  align-self: center !important;
  span {
    display: contents;
  }
  .icon-menu {
    background: url("../../assets/icons/icon_menu_custom.svg");
    background-repeat: no-repeat;
    display: block;
    padding: 16px;
  }
}
.buttonModalMenu:focus {
  box-shadow: none !important;
}
.conventions-button {
  right: 80px;
  bottom: 5px;
  position: fixed;
  z-index: 9;
  .dropdown-menu{
    min-width: 14rem;
  }
  .dropdown-content {
    margin-bottom: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }
  .item-dd {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 15px 0;
    cursor: default;
    .msg {
      flex: 2;
      text-align: right;
      padding-left: 30px;
      color: #606060;
    }
    .convention-color {
      flex: 1;
      border: solid 0.5px rgb(163, 158, 158);
      margin-left: 10px;
      margin-right: 20px;
    }
  }
  .res-sit {
    width: 15px;
    height: 15px;
    max-width: 15px;
    min-width: 15px;
    border-radius: 100px;
    background-color: #9568fc;
  }
  .walk-sit {
    width: 15px;
    height: 15px;
    max-width: 15px;
    min-width: 15px;
    border-radius: 100px;
    background-color: #01adc6;
  }
  .free-table {
    width: 15px;
    height: 15px;
    max-width: 15px;
    min-width: 15px;
    border-radius: 100px;
    background-color: #ebeced;
  }
  .reser-atra-table {
    width: 15px;
    height: 15px;
    max-width: 15px;
    min-width: 15px;
    border-radius: 100px;
    border-color: #ffd43b !important;
    background-color: #ebeced;
  }
  .reser-retrasada-table{
    width: 15px;
    height: 15px;
    max-width: 15px;
    min-width: 15px;
    border-radius: 100px;
    border-color: #FF8A00 !important;
    background-color: #ebeced;
  }
  .privada {
    position: relative !important;
    margin-left: 10px;
    margin-right: 20px;
    left: auto;
    top: auto;
    height: 16px;
    width: 16px;
    border: none;
  }
  .reser-proxima-table {
    width: 15px;
    height: 15px;
    max-width: 15px;
    min-width: 15px;
    border-radius: 100px;
    border-color: #01adc6 !important;
    background-color: #ebeced;
  }
  .block-table {
    width: 15px;
    height: 15px;
    max-width: 15px;
    min-width: 15px;
    border-radius: 100px;
    background-color: #de2929;
  }
  .convButton {
    background: #ebeced;
    color: #05121e;
    font-weight: bold;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    line-height: 22px;
    box-shadow: 0px 3px 6px #00000029;
  }
}
.wait-button, .settings-button {
  background-color: #f2f2f2;
  height: auto;
  border-radius: 50px 0 0 50px;
  box-shadow: 0px 3px 6px #00000029;
  right: 0px;
  bottom: 13%;
  position: fixed;
  z-index: 9;
  border-right: 0;
  cursor: pointer;
  .wait-icon, .settings-icon {
    height: 50px;
    width: 50px;
    margin-top: 0px;
    border-radius: 50%;
    border: 2px solid #fff;
    position: relative;
    padding: 1.5px;
    background-color: #444b57;
    display: flex;
    align-items: center;
    margin-right: 10px;
    .wait-circle {
      top: -6px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #fff;
      text-align: center;
      flex: initial;
      color: #000;
      font-size: 11px;
      font-weight: 600;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      right: -3px;
    }
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 10px;
      width: 45px;
      z-index: 1;
    }
  }
}
.btns-metrics {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  .bt {
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    &.active {
      background: rgba(55, 61, 70, 1);
    }
  }
  .btn-container {
    display: flex;
    height: 100%;
  }
  .general-container {
    align-items: center;
    display: flex;
    height: 100%;
    width: 500px;
    justify-content: space-around;
    .icon-mks {
      &.person {
        height: 30px !important;
        width: 30px !important;
      }
      &.reservation {
        height: 30px !important;
        width: 40px !important;
      }
      &.margin-rigth {
        margin-right: 12px !important;
      }
      &.precompra {
        height: 30px;
        width: 30px;
      }
    }
  }
  .text-general {
    color: white;
    font-size: 12px;
    font-weight: bold;
    &.small {
      font-size: 9px;
    }
    &.big {
      font-size: 16px;
    }
  }
  .text-center {
    text-align: center;
    &.flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.settings-button {
  height: 30px;
  width: 30px;
  bottom: 6%;
  z-index: 9999;
  .settings-icon {
    height: 30px;
    width: 30px;
  }
}
.icon_schedules {
  background-image: url(../../assets/icons/icon_schedules.svg);
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}
.navbar-menu .columns_schedules {
  flex-direction: row !important;
  justify-content: center;
}
@media (min-width: 1001px) {
  .btns-metrics {
    height: 100%;
  }
}
.itemNavSchedules {
  justify-content: center !important;
  font-weight: normal;
  font-size: 18px;
}
.itemsNavSchedules {
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .itemsNavSchedules {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    i {
      display: flex;
    }
  }
  .itemNavSchedules {
    display: flex !important;
  }
}
@media only screen and (max-width: 565px) {
  .itemNavSchedules {
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    i {
      width: 20px;
      height: 20px;
    }
  }
  .columns_schedules {
    flex-direction: row !important;
    justify-content: center;
  }
}
@media screen and (min-width: 100px) and (max-width: 565px) {
  .columns_schedules {
    padding: 0px !important;
  }
  .buttonModalMenu {
    flex-direction: column;
    .icon-menu {
      padding: 10px;
      margin-top: 5px;
    }
  }
}
@media only screen and (max-width: 320px) {
  .itemsNavSchedules {
    height: 100%;
  }
  .itemNavSchedules {
    padding: 0;
  }
}
#NavBarTop {
  .conts {
    gap: 8px;
    padding-left: 8px;
    min-width: 492px;
    @media screen and (min-width: 1200px) {
      min-width: 300px;
    }
    @media screen and (max-width: 800px) {
      gap: 4px;
    }
  }
  span {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-family: Source Sans Pro;
    font-weight: 600;
  }
  .navbar-item {
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    color: white;
    background-color: #303640;
    padding: unset;
    width: 46px;
    height: 46px;
    padding-top: 2px;
    padding-bottom: 5px;
    justify-content: space-around;
    i {
      margin: unset;
    }
    @media screen and (max-width: 600px) {
      width: 30px;
      height: 30px;
      span {
        font-size: 9px;
      }
      i {
        width: 16px;
        height: 12px;
      }
    }
  }
}
.lastContentNavbar {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding-right: 8px;
  #notifications {
    margin-left: 4px;
    i {
      margin: unset;
    }
  }
  .searchedinmenu, .connectionContainer, .controlContainer {
    width: 34px;
    height: 34px;
    display: block;
    cursor: pointer;
    i {
      display: block;
      position: initial;
      width: 100% !important;
      height: 100% !important;
      color: white;
      background-color: white;
      mask-size: cover;
      mask-position: center;
      mask-repeat: no-repeat;
    }
  }
  .connectionContainer, .controlContainer {
    width: 25px;
    height: 25px;
    i {
      mask-size: contain;
    }
  }
  .controlContainer {
    position: relative;
    width: 20px;
    height: 20px;
    &.filterActive:not(.opened) {
      &::after {
        @media screen and (max-width: 600px) {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -5px;
          right: -5px;
          background-color: red;
          border-radius: 50%;
          content: "1";
          height: 11px;
          width: 11px;
          font-size: 8px;
          color: white;
        }
      }
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
    &.opened {
      background-color: #2b2f38;
      align-self: flex-end;
      border-radius: 5px;
      height: 30px !important;
      width: 30px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        position: initial;
        height: 70% !important;
        width: 70% !important;
      }
      @media screen and (min-width: 600px) and (max-width: 900px) {
        transform: translateY(6px);
      }
    }
    @media screen and (min-width: 1300px) {
      display: none;
    }

    @media screen and (max-width: 800px) {
      right: 7px!important;
      &.opened {
        height: 44px !important;
        @media screen and (min-width: 600px) and (max-width: 900px) {
          transform: translate(6px, 6px);
        }
      }
    }
    @media screen and (max-width: 600px) {
      right: 0px!important;
      justify-items: center;
      &.opened {
        width: 25px!important;
        height: 25px!important;
        /* @media screen and (min-width: 600px) and (max-width: 900px) {
          transform: translate(6px, 6px);
        } */
      }
    }
  }
}
#NavBarTop.navbar {
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.show-right-bar #NavBarTop.navbar {
  @media screen and (max-width: 600px) {
    display: block;
  }
}
</style>
