<template>
  <div class="modal_redeem_new">
    <div class="close_container" @click="$emit('close')"><img src="@/assets/icons/polls/close_black.svg" alt=""></div>
    <img src="@/assets/icons/giftCards/redimidas.svg" alt="" class="redeem-logo">
    <h2 class="redeem-title">¿Cuánto vas a redimir?</h2>
    <div class="redeem-redention">
      <div class="redeem-redention-item">
        <div class="mb-10 redeem-redention-item-check" @click="full = !full, partial = !partial">
          <img src="@/assets/icons/giftCards/check-back.svg" v-if="full" alt="" class="redeem-redention-item-check-back">
          <img src="@/assets/icons/giftCards/checked-back.svg" v-if="full" alt="" class="redeem-redention-item-check-back-checked">
          <img src="@/assets/icons/giftCards/check.svg" v-if="!full" alt="" class="redeem-redention-item-check-clear">
        </div>
        <div class="redeem-redention-item-title mb-10">Redención total</div>
        <div class="redeem-redention-item-subtitle" v-if="giftCard.pendingAmount === 0">{{giftCard.value| currency('$ ', 0, { thousandsSeparator: ',' }) }}</div>
        <div class="redeem-redention-item-subtitle" v-else>{{giftCard.pendingAmount| currency('$ ', 0, { thousandsSeparator: ',' }) }}</div>
      </div>
      <div class="redeem-redention-item ml-50">
        <div class="mb-10 redeem-redention-item-check" @click="partial = !partial, full = !full">
          <img src="@/assets/icons/giftCards/check-back.svg" v-if="partial" alt="" class="redeem-redention-item-check-back">
          <img src="@/assets/icons/giftCards/checked-back.svg" v-if="partial" alt="" class="redeem-redention-item-check-back-checked">
          <img src="@/assets/icons/giftCards/check.svg" v-if="!partial" alt="" class="redeem-redention-item-check-clear">
        </div>
        <div class="redeem-redention-item-title">Redención parcial</div>
        <div class="redeem-redention-item-parcial mt-7">
          <input type="number" min="0" v-model="amount" placeholder="$" :disabled="full === 1">
        </div>
      </div>
    </div>
    <div class="redeem-redention-alert" v-if="partial">
      <span v-html="getSaldo()"></span>
    </div>
    <div class="redeem-redention-footer">
      <div class="redeem-redention-footer-item">
        <div class="redeem-redention-item-redime">
          <span>¿Quién redime el bono?</span>
        </div>
        <div class="redeem-redention-item-input">
          <input type="text" id="redimeNameHost" v-model="name" placeholder="Nombre del host que recibe el bono">
        </div>
      </div>
      <div class="redeem-redention-footer-item">
        <button @click="redeemGiftCard" :class="getClassButton()">
          <span>Aceptar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['giftCard'],
  data () {
    return {
      amount: 0,
      name: null,
      full: true,
      partial: false,
      isSent: 1
    };
  },
  mounted () {
    setTimeout(() => {
      document.getElementById('redimeNameHost').focus();
    }, 300);
  },
  methods: {
    getSaldo () {
      const minMoney = 0;
      this.isSent = 0;
      if (parseInt(this.amount) >= minMoney) {
        if (this.giftCard.isPartial) {
          if (parseInt(this.giftCard.pendingAmount) >= parseInt(this.amount)) {
            const saldo = parseInt(this.giftCard.pendingAmount) - parseInt(this.amount);
            if (saldo > 0) {
              this.isSent = 1;
              return '<span>El saldo pendiente por redimir es de ' + saldo.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }).split(',')[0] + '</span>';
            } else if (saldo === 0) {
              this.isSent = 1;
            }
          } else {
            return '<span style="color: red;">*El valor debe ser inferior a ' + parseInt(this.giftCard.pendingAmount).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }).split(',')[0] + '</span>';
          }
        } else {
          if (parseInt(this.giftCard.value) >= parseInt(this.amount)) {
            const saldoTotal = parseInt(this.giftCard.value) - parseInt(this.amount);
            if (saldoTotal > 0) {
              this.isSent = 1;
              return '<span>El saldo pendiente por redimir es de ' + saldoTotal.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }).split(',')[0] + '</span>';
            } else if (saldoTotal === 0) {
              this.isSent = 1;
            }
          } else {
            return '<span style="color: red;">*El valor debe ser inferior a ' + parseInt(this.giftCard.value).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }).split(',')[0] + '</span>';
          }
        }
      } else {
        return '<span style="color: red;">*El valor debe ser superior a ' + minMoney.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }).split(',')[0] + '</span>';
      }
    },
    getClassButton () {
      if ((this.name === null && (!this.full || !this.partial)) || (this.name === null && this.partial && this.amount === 0) || this.isSent === 0) {
        return 'inactive';
      } else {
        const regex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+){1,4}$/;
        const nombreApellido = document.getElementById('redimeNameHost').value ? document.getElementById('redimeNameHost').value.trim() : document.getElementById('redimeNameHost').value;
        this.redimeName = null;
        if (regex.test(nombreApellido)) {
          return 'active';
        } else {
          return 'inactive';
        }
      }
    },
    redeemGiftCard () {
      const regex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+){1,4}$/;
      const nombreApellido = document.getElementById('redimeNameHost').value ? document.getElementById('redimeNameHost').value.trim() : document.getElementById('redimeNameHost').value;
      this.redimeName = null;
      if (regex.test(nombreApellido)) {
        if (this.partial) {
          this.$emit('redeem', { id: this.giftCard.id, redime: this.name, amount: this.amount });
        } else {
          this.$emit('redeem', { id: this.giftCard.id, redime: this.name });
        }
        this.$emit('close');
      } else {
        this.$buefy.toast.open({
          message: 'Debes escribir nombre y apellido!',
          type: 'is-danger'
        });
        setTimeout(() => {
          document.getElementById('redimeNameHost').focus();
        }, 300);
      }
    }
  }
};
</script>

<style lang="scss">
.modal_redeem_new {
  margin: 0 auto;
  width: 369px;
  height: 395px;
  flex-shrink: 0;
  position: relative;
  background: white;
  text-align: center;
  color: #444B57;
  border-radius: 10px;
  border: 0.5px solid rgba(68, 75, 87, 0.20);
  background: #FFF;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.25);
  user-select: none;
  .close_container {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    user-select: none;
  }
  .redeem-logo {
    width: 61px;
    height: 44px;
    flex-shrink: 0;
    margin-top: 30px;
    user-select: none;
  }
  .redeem-title {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #444B57;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
  }
  .redeem-redention {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    &-alert {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      span {
        color: #444B57;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    &-item {
      width: 120px;
      height: 120px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #444B57;
      background: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &-check {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
        &-clear {
          opacity: 0.4;
          width: 14px;
          height: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-back {
          width: 14px;
          height: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          &-checked {
            position: absolute;
            top: 3px;
            left: 2px;
          }
        }
      }
      &-redime {
        color: #444B57;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-parcial {
        width: 96px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 6px;
        border: 1px solid #A8ADB5;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          width: 100%;
          height: 100%;
          border: unset;
          background: transparent;
          text-align: center;
        }
      }
      &-input {
        width: 180px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 6px;
        border: 1px solid #A8ADB5;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          width: 100%;
          height: 100%;
          border: unset;
          background: transparent;
          text-align: center;
          &::placeholder {
            color: #444B57;
            text-align: center;
            font-family: 'Source Sans Pro';
            font-size: 11px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }
      }
      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &-subtitle {
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .mb-10 {
        margin-bottom: 10px;
      }
      .mt-7 {
        margin-top: 7px;
      }
    }
    .ml-50 {
      margin-left: 50px;
    }
    &-footer {
      width: 369px;
      height: 121px;
      flex-shrink: 0;
      border-radius: 1px 1px 10px 10px;
      background: #F7F7F7;
      margin-top: 20px;
      padding: 26px 20px 20px 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      .inactive {
        display: inline-flex;
        padding: 8px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 25px;
        opacity: 0.5;
        background: #444B57;
        pointer-events: none;
        span {
          color: #FFF;
          font-family: 'Source Sans Pro';
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .active {
        display: inline-flex;
        padding: 8px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 25px;
        background: #444B57;
        cursor: pointer;
        span {
          color: #FFF;
          font-family: 'Source Sans Pro';
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      &-item {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 12px;
      }
    }
  }
}
// @import "@/assets/styles/giftCards/_modal_redeem";
</style>
