<template>
  <nav
    class="navbar"
    role="navigation"
  >
    <div class="container-navbar">
      <div class="navbar-menu_v2">
        <button :class="{ active: this.page === 1 }" @click="handleChangePage({ id: '#', page: 1 })">
          <p>Horarios</p>
        </button>
        <button :class="{ active: this.page === 4 }" @click="handleChangePage({ id: '#qrView', page: 4 })">
          <p>Menú-QR</p>
        </button>
        <button :class="{ active: this.page === 5 }" @click="handleChangePage({ id: '#popUp', page: 5 })">
          <p>Pop Up</p>
        </button>
        <button :class="{ active: this.page === 15 }" @click="handleChangePage({ id: '#popUp', page: 15 })">
          <p>Integraciones</p>
        </button>
        <button :class="{ active: this.page === 16 }" @click="handleChangePage({ id: '#host', page: 16 })">
          <p>Host</p>
        </button>
      </div>
      <div class="navbar-search d-flex">
        <div class="clientImg" @click="openSideBar"><img :src="vendor.thumbnailURL" /></div>
        <b-input placeholder="Buscar por nombre"
          type="search"
          v-model="search"
          icon="search"
          class="search-input"
          v-if="false">
        </b-input>
      </div>
    </div>
  </nav>
</template>
<script>
import globalTypes from '@/store/types/global';
export default {
  props: ['filterActivate'],
  data () {
    return {
      search: '',
      page: 1,
      activeButtonMenu: false,
      activeButtonMenu2: false
    };
  },
  methods: {
    openSideBar () {
      this.$store.commit(globalTypes.mutations.setShowSideBar, true);
    },
    setActive (button) {
      this.isActive = button;
    },
    handleChangePage (value) {
      this.page = value.page;
      value.id === '#menuCustom'
        ? (this.activeButtonMenu = true)
        : (this.activeButtonMenu = false);
      value.id === '#qrView'
        ? (this.activeButtonMenu2 = true)
        : (this.activeButtonMenu2 = false);
      this.$emit('showPage', value);
    }
  }
};
</script>
<style lang="scss" scoped>
  .navbar {
    position: fixed;
    width: 100%;
    padding: 11px 20px 11px 40px;
    background: #444B57;
  }
  .container-navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .navbar-menu_v2 {
    padding: 2px;
    width: 100%;
    height: 30px;
    border-radius: 4px;
    background: #F3F3F3;
    flex: 0;
    display: flex;
  }
  .navbar-menu_v2 > button {
    border-width: 0px;
    width: 90px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #FFF;
    cursor: pointer;
  }
  .navbar-menu_v2 > button.active {
    background: #444B57;
  }
  .navbar-menu_v2 > button.active > p {
    color:#FFF;
    font-weight: 600;
  }
  .navbar-menu_v2 > button > p {
    color:#606773;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .navbar-search > b-input{
    width: 200px;
    height: 30px;
    border-radius: 4px;
    background: #FFF;
    color: #A8ADB5;
    font-family: "Source Sans Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .search-input {
    position: relative;
    padding-left: 30px; /* Ajusta este valor según el tamaño de tu icono */
  }
  .search-input::before {
    content: url('../../assets/icons/polls/search_black.svg');
    position: absolute;
    left: 40px; /* Ajusta este valor para colocar el icono dentro del input */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .clientImg {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    .navbar {
      padding: 12px 11px;
    }
  }
  @media screen and (max-width: 600px) {
    #schedules .navbar {
      height: 45px!important;
    }
  }
  @media screen and (max-width: 450px) {
    .navbar {
      padding: 5px 8px;
    }
    .menuActive {
      min-height: 80px!important;
    }
    .container-navbar {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 6px;
    }
    .navbar-menu_v2 {
      order: 2;
      width: 100%;
      gap: 1.72px;
      padding: 2px 1.72px;
      flex: 1;
    }
    .navbar-menu_v2 > button {
      width: 100%;
      height: 26px;
      flex: 1;
    }
    .navbar-menu_v2 > button > p {
      font-size: 11px;
    }
    .navbar-search {
      justify-content: space-between;
      order: 1;
      align-items: center;
    }
    .search-input {
      display: none;
    }
    .search-input {
      width: 341px;
    }
    .clientImg {
      display: block;
      width: 30px;
      height: 30px;
    }
  }
  @media screen and (max-width: 390px) {
    .navbar-menu_v2 > button > p {
      font-size: 10px;
    }
  }
</style>
