<template>
  <div id="ReservationList">
    <div v-show="!size(reservationList) && !loadingReservationCalendar" class="Nohay box-loading">
      <i class="icon-mks icon_reservations loading"></i>
      <div>No hay reservas en el momento</div>
      <p v-if="loadingReservationCalendar">Buscando reservas para {{monthName}} ...</p>
    </div>
    <div v-show="loadingReservationCalendar" class="Nohay box-loading">
      <i class="icon-mks icon_reservations loading rotating"></i>
      <p>Buscando reservas para {{monthName}} ...</p>
    </div>
    <div v-if="!loadingReservationCalendar" class="fullWidth">
      <div v-for="(reservations, time) in reservationList" :key="'reservation-item-map-time#' + time" class="bock-box" >
        <div v-show="customFilter(reservations, query).length" class="header-hour">
          <div class="side left">
            <div class="dot"></div>
            <div>{{time}}</div>
          </div>
          <div class="side right reservation-list-map-icons-precompro">
            <!-- <i class="icon-mks white icon_reservation"></i>
            <div class="number-top">{{customFilter(reservations, query).length}}</div> -->
            <section
              v-show="getPrecomprasquantity(customFilter(reservations, query)) > 0"
            >
              <i class="icon_precompro icon-mks black"></i>
              <div>
                {{ getPrecomprasquantity(customFilter(reservations, query)) }}
              </div>
            </section>
            <section
              v-show="getWalkingQuantity(customFilter(reservations, query)) > 0"
            >
              <i class="icon_walk-in icon-mks black"></i>
              <div>{{ getWalkingQuantity(customFilter(reservations, query)) }}</div>
            </section>
            <section
              v-show="getReservationsQuantity(customFilter(reservations, query)) > 0"
            >
              <i class="icon_reservation icon-mks black"></i>
              <div>{{ getReservationsQuantity(customFilter(reservations, query)) }}</div>
            </section>
            <section>
              <i class="icon_person icon-mks black"></i>
              <div>{{ getPeopleQuantity(customFilter(reservations, query)) }}</div>
            </section>
          </div>
        </div>
        <ReservationItem v-for="(reservation, indReservation) in customFilter(reservations, query)" :key="'reservation-item-map-info#' + indReservation" :reservation="reservation" :query="query"></ReservationItem>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import Vue from 'vue';
import ReservationItem from './ReservationItem';
export default {
  props: ['filter', 'query', 'isConfirmada'],
  components: { ReservationItem },
  data: () => ({
    currentUpdateReservation: '',
    reservationList: [],
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear()
  }),
  mounted () {
    this.currentMonth = this.$moment().format('M');
    this.currentYear = this.$moment().format('YYYY');
    this.reservationList = this.getReservationList();
  },
  computed: {
    monthName () {
      const firstOfThisMonth = this.currentYear + '-' + this.currentMonth + '-01 00:00:00';
      return this.$moment(firstOfThisMonth).format('MMMM, YYYY');
    }
  },
  methods: {
    size: _.size,
    chargedReservationSig () {
      this.page = this.page + 1;
      const info = this.getReservationList();
      this.reservationList = info;
    },
    chargedReservationAnt () {
      this.page = this.page - 1;
      const info = this.getReservationList();
      this.reservationList = info;
    },
    getReservationList () {
      if (this.map) {
        const filter = this.filter;
        const reservationList = _(this.reservations[this.selectedDate])
          .sortBy(reservation => (parseInt(reservation.date)))
          .filter(reservation => {
            let returnable = true;
            switch (filter) {
              case 'active':
                returnable = reservation.codeStatus === 1 || reservation.codeStatus === 0 || reservation.codeStatus === 7 || reservation.codeStatus === 8;
                break;
              case 'finish':
                returnable = reservation.codeStatus === 4 || reservation.codeStatus === 5 || reservation.codeStatus === 3;
                break;
              case 'partialSeated':
                returnable = reservation.codeStatus === 6;
                break;
              case 'seated':
                returnable = reservation.codeStatus === 2;
                break;
            }
            return (
              returnable &&
              (!_.size(this.selectedDates) ||
                _.includes(this.selectedDates, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss')))
            );
          })
          .groupBy(reservation => {
            const reservationDate = new Date(parseInt(reservation.date));
            return this.$moment(reservationDate).format('hh:mm a');
          })
          .value();
        return reservationList;
      }
    },
    customFilter (reservations, query) {
      if (!query) return reservations;

      return reservations.filter((reservation) => {
        const isVipMatch = query.toLowerCase() === 'vip' && (reservation.isVip === 1 || reservation.isVip === true);
        const servicesMatch = Array.isArray(reservation.services) && reservation.services.length > 0 &&
          reservation.services.some((service) =>
            service.requirements.displayName.toLowerCase().includes(query.toLowerCase())
          );
        const generalMatch =
          reservation.displayName.toLowerCase().includes(query.toLowerCase()) ||
          reservation.phone?.includes(query) ||
          reservation.comments?.toLowerCase().includes(query.toLowerCase()) ||
          reservation.commentRestaurant?.toLowerCase().includes(query.toLowerCase()) ||
          reservation.tableName?.toLowerCase().includes(query.toLowerCase()) ||
          reservation.identityDocument?.toLowerCase().includes(query.toLowerCase());
        return isVipMatch || servicesMatch || generalMatch;
      });
    },
    getPrecomprasquantity (reservations) {
      let quantity = 0;
      reservations.forEach((reservation) => {
        if (reservation.balancePaid > 0) {
          quantity++;
        }
      });
      return quantity;
    },
    getWalkingQuantity (reservations) {
      let quantity = 0;
      reservations.forEach((reservation) => {
        if (reservation.referrer === 'Walk-In') {
          quantity++;
        }
      });
      return quantity;
    },
    getReservationsQuantity (reservations) {
      let quantity = 0;
      reservations.forEach((reservation) => {
        if (reservation.referrer !== 'Walk-In' && !reservation.balancePaid) {
          quantity++;
        }
      });
      return quantity;
    },
    getPeopleQuantity (reservations) {
      let peopleNumber = 0;
      reservations.forEach((reservation) => {
        peopleNumber += reservation.people;
      });
      return peopleNumber;
    }
  },
  watch: {
    ..._.mapValues({ selectedDate: '', filter: '', query: '' }, () => function () {
      Vue.nextTick()
        .then(() => {
          this.reservationList = this.getReservationList();
        });
    }),
    reservations () {
      this.reservationList = this.getReservationList();
    },
    selectedDate () {
      this.currentMonth = this.$moment(this.selectedDate).format('M');
      this.reservationList = this.getReservationList();
    },
    selectedDates () {
      this.reservationList = this.getReservationList();
    },
    map () {
      if (this.map) {
        this.reservationList = this.getReservationList();
      }
    }
  }
};
</script>
<style lang="scss">

#ReservationList{
  height: 100%;
  overflow: auto;
  margin-bottom: 5px;
  .Nohay{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.box-loading{
        display:flex;
        flex-direction:column;
        align-items:center;
        &.calendar-bg{
          height: calc(100% - 2.5rem);
          position: absolute;
          width: calc(100% - 2rem);
          justify-content: center;
        }
        span{
          color:#fff;
          margin-top:10px;
        }
      }
      .loading{
        width: 100px;
        height: 100px;
        background: rgba(67, 74, 86, 0.3);
        margin-bottom: 40px;
      }

  }
  .fullWidth{
    width:100%;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    background:#EBECED;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    outline: 1px solid #fff;
    border-radius: 47px;
    border: 1px solid #fff;
    -webkit-box-shadow: 0px -1px 0px #d1d1d1;
    -moz-box-shadow: 0px -1px 0px #d1d1d1;
    box-shadow: inset 0px 0px 4px #fff;
    width: 100%;
  }
  .bock-box{
    border-right: 1px solid #E5E6E7;
    display: flex;
    flex-direction: column;
  }
  .header-hour{
    align-items: center;
    justify-content: space-between;
    background: #444B57;
    padding: 5px 10px;
    color: #fff;
    font-size: 11px;
    margin: 5px;
    flex-direction: row;
    display:flex;
    border-radius: 5px;
    -webkit-transform: translate3d(0,0,0);
    .side{
      display: flex;
      align-items: center;
      width: 50%;
      position: relative;
      &.left{
        justify-content: flex-start;
      }
      &.right{
        justify-content: flex-end;
        margin-bottom: -5px;
        i{
          margin-top: -1px;
        }
        .number-top{
          margin-top: -13px;
          font-size: 9px;
          font-weight: 700;
          margin-left:-3px;
        }
      }

    }
    .dot{
      height: 5px;
      width: 5px;
      background: green;
      border-radius: 50%;
      margin-right:5px;
    }
  }
  .reservation-list-map-icons-precompro{
    margin-bottom: 0!important;
    gap: 10px;
    section{
      display: flex;
      i{
        width: 18px;
        height: 16px;
        background: #fff;
      }
    }
  }
}
</style>
