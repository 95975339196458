<template>
  <div class="List-canvas animated fadeIn">
    <div class="top-bar">
      <div class="filter-box">
        <span class="titler">{{
          selectedDate | moment("dddd DD [de] MMMM")
        }}</span>
        <div
          @click="() => (windowWidth <= 600 ? (showFilterMobile = true) : null)"
        >
          <b-dropdown
            v-model="listSearch"
            aria-role="list"
            id="filterDropDownList"
            :mobile-modal="false"
            :disabled="windowWidth <= 600"
          >
            <span
              slot="trigger"
              class="filterModify"
              :filterQuantity="filterOptions.length"
              :class="{ haveFilters: filterOptions.length > 0 }"
              ><i class="fa fa-filter"></i> Filtrar</span
            >
            <b-dropdown-item
              value="Próximas"
              aria-role="listitem"
              class="item-dd"
              :class="{
                selectedOption: filterOptions.find((e) => e === 'Próximas'),
              }"
            >
              <label
                :class="{
                  selectedOption: filterOptions.find((e) => e === 'Próximas'),
                }"
                @click="updateDataList"
                aria-role="listitem"
                class="item-dd"
                ><div class="dot-next">
                  <input
                    type="checkbox"
                    name="option1"
                    value="Próximas"
                    v-model="filterOptions"
                  />
                </div>
                Próximas</label
              >
            </b-dropdown-item>
            <b-dropdown-item
              v-if="vendorParams.purchaseCost > 0"
              value="Precompras"
              aria-role="listitem"
              class="item-dd"
              :class="{
                selectedOption: filterOptions.find((e) => e === 'Precompras'),
              }"
            >
              <label
                v-if="vendorParams.purchaseCost > 0"
                :class="{
                  selectedOption: filterOptions.find((e) => e === 'Precompras'),
                }"
                @click="updateDataList"
                aria-role="listitem"
                class="item-dd"
                ><div class="dot-prec">
                  <input
                    type="checkbox"
                    name="option2"
                    value="Precompras"
                    v-model="filterOptions"
                  />
                </div>
                Precompras</label
              ></b-dropdown-item
            >
            <b-dropdown-item
              value="Sentadas"
              aria-role="listitem"
              class="item-dd"
              :class="{
                selectedOption: filterOptions.find((e) => e === 'Sentadas'),
              }"
            >
              <label
                @click="updateDataList"
                :class="{
                  selectedOption: filterOptions.find((e) => e === 'Sentadas'),
                }"
                aria-role="listitem"
                class="item-dd"
                ><div class="dot-sit">
                  <input
                    type="checkbox"
                    name="option3"
                    value="Sentadas"
                    v-model="filterOptions"
                  />
                </div>
                Sentadas</label
              ></b-dropdown-item
            >
            <b-dropdown-item
              value="Finalizadas"
              aria-role="listitem"
              class="item-dd"
              :class="{
                selectedOption: filterOptions.find((e) => e === 'Finalizadas'),
              }"
            >
              <label
                @click="updateDataList"
                :class="{
                  selectedOption: filterOptions.find(
                    (e) => e === 'Finalizadas'
                  ),
                }"
                aria-role="listitem"
                class="item-dd"
                ><div class="dot-finished">
                  <input
                    type="checkbox"
                    name="option4"
                    value="Finalizadas"
                    v-model="filterOptions"
                  />
                </div>
                Finalizadas</label
              ></b-dropdown-item
            >
            <b-dropdown-item
              value="Canceladas"
              aria-role="listitem"
              class="item-dd"
              :class="{
                selectedOption: filterOptions.find((e) => e === 'Canceladas'),
              }"
            >
              <label
                @click="updateDataList"
                aria-role="listitem"
                :class="{
                  selectedOption: filterOptions.find((e) => e === 'Canceladas'),
                }"
                class="item-dd"
                ><div class="dot-cancel">
                  <input
                    type="checkbox"
                    name="option5"
                    value="Canceladas"
                    v-model="filterOptions"
                  />
                </div>
                Canceladas</label
              ></b-dropdown-item
            >
            <b-dropdown-item
              value="No llegó"
              aria-role="listitem"
              class="item-dd"
              :class="{
                selectedOption: filterOptions.find((e) => e === 'No llegó'),
              }"
            >
              <label
                @click="updateDataList"
                aria-role="listitem"
                class="item-dd"
                :class="{
                  selectedOption: filterOptions.find((e) => e === 'No llegó'),
                }"
                ><div class="dot-noShow">
                  <input
                    type="checkbox"
                    name="option6"
                    value="No llegó"
                    v-model="filterOptions"
                  />
                </div>
                No llegó</label
              ></b-dropdown-item
            >
          </b-dropdown>
        </div>
        <section class="rightSide">
        <div class="Serach-box">
          <span class="material-icons-outlined"> search </span>
          <input v-model="query" type="text" autocomplete="off" @keyup.esc="query = ''" placeholder="Buscar" />
        </div>
        <i
          v-show="!generate && !validatePermission('ReadOnly')"
          class="icon-mks black icon_dashboard_printer"
          @click="generateReport($event)"
        ></i>
          <ReporteReservation
            v-if="activeModalReport"
            v-model="apiReportDownload"
            @close-emit="CloseMenuM"
            :event="eventForModalReport"
          ></ReporteReservation>
        </section>

      </div>
    </div>
    <div class="content-list">
      <div
        v-show="isEmptyFilterList(filteredReservations, query)"
        class="reservation-empty"
      >
        <div v-show="loadingReservationCalendar" class="Nohay box-loading">
          <i class="icon-mks icon_reservations loading rotating"></i>
          <p>Buscando reservas ...</p>
        </div>
        <div v-show="!loadingReservationCalendar" class="Nohay box-loading">
          <i class=" icon_calendarLoadingPage loading"></i>
          <span class="titler">{{ selectedDate | moment("dddd DD [de] MMMM") }}</span>
          <p>No hay reservas {{noReservationsMessage}}</p>
        </div>
      </div>
      <div
        v-for="(reservations, time, counter) in filteredReservations"
        :key="time"
      >
        <div v-show="customFilterList(reservations, query).length" class="header-hour">
          <div class="side left">
            <div>{{ time }}</div>
          </div>
          <div class="side right">
            <section
              v-show="getPrecomprasquantity(customFilterList(reservations, query)) > 0"
            >
              <i class="icon_precompro icon-mks black"></i>
              <div>
                {{ getPrecomprasquantity(customFilterList(reservations, query)) }}
              </div>
            </section>
            <section
              v-show="getWalkingQuantity(customFilterList(reservations, query)) > 0"
            >
              <i class="icon_walk-in icon-mks black"></i>
              <div>{{ getWalkingQuantity(customFilterList(reservations, query)) }}</div>
            </section>
            <section
              v-show="getReservationsQuantity(customFilterList(reservations, query)) > 0"
            >
              <i class="icon_reservation icon-mks black"></i>
              <div>{{ getReservationsQuantity(customFilterList(reservations, query)) }}</div>
            </section>
            <section>
              <i class="icon_person icon-mks black"></i>
              <div>{{ getPeopleQuantity(customFilterList(reservations, query)) }}</div>
            </section>
          </div>
        </div>
        <ReservationItem
          v-for="(reservation, index) in customFilterList(reservations, query)"
          :key="index"
          :i="!isHidden(time)"
          :zindex="99999 - counter * 99 - index"
          :count="time"
          :pos="index"
          v-show="reservation"
          :reservation="reservation"
          @dataAlertYellow="infoAlertYellow = $event"
        ></ReservationItem>
      </div>
    </div>
    <Sections :reservationsBySection="reservationsBySection"></Sections>
    <portal selector="#Reservations" v-if="showFilterMobile">
      <div class="modalForFilterList">
        <div class="contentModal">
          <label
            :class="{
              selectedOption: filterOptions.find((e) => e === 'Próximas'),
            }"
            @click="updateDataList"
            aria-role="listitem"
            class="item-dd"
            ><div class="dot-next">
              <input
                type="checkbox"
                name="option1"
                value="Próximas"
                v-model="filterOptions"
              />
            </div>
            Próximas</label
          >
          <label
            v-if="vendorParams.purchaseCost > 0"
            :class="{
              selectedOption: filterOptions.find((e) => e === 'Precompras'),
            }"
            @click="updateDataList"
            aria-role="listitem"
            class="item-dd"
            ><div class="dot-prec">
              <input
                type="checkbox"
                name="option2"
                value="Precompras"
                v-model="filterOptions"
              />
            </div>
            Precompras</label
          >
          <label
            @click="updateDataList"
            :class="{
              selectedOption: filterOptions.find((e) => e === 'Sentadas'),
            }"
            aria-role="listitem"
            class="item-dd"
            ><div class="dot-sit">
              <input
                type="checkbox"
                name="option3"
                value="Sentadas"
                v-model="filterOptions"
              />
            </div>
            Sentadas</label
          >
          <label
            @click="updateDataList"
            :class="{
              selectedOption: filterOptions.find((e) => e === 'Finalizadas'),
            }"
            aria-role="listitem"
            class="item-dd"
            ><div class="dot-finished">
              <input
                type="checkbox"
                name="option4"
                value="Finalizadas"
                v-model="filterOptions"
              />
            </div>
            Finalizadas</label
          >
          <label
            @click="updateDataList"
            aria-role="listitem"
            :class="{
              selectedOption: filterOptions.find((e) => e === 'Canceladas'),
            }"
            class="item-dd"
            ><div class="dot-cancel">
              <input
                type="checkbox"
                name="option5"
                value="Canceladas"
                v-model="filterOptions"
              />
            </div>
            Canceladas</label
          >
          <label
            @click="updateDataList"
            aria-role="listitem"
            class="item-dd"
            :class="{
              selectedOption: filterOptions.find((e) => e === 'No llegó'),
            }"
            ><div class="dot-noShow">
              <input
                type="checkbox"
                name="option6"
                value="No llegó"
                v-model="filterOptions"
              />
            </div>
            No llegó</label
          >
          <button @click="() => (showFilterMobile = false)">Cerrar</button>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>
import ReservationItem from '@/components/reservations/Center/Lista/ReservationItem';
import ReporteReservation from '@/components/_shared/ReporteReservation';
import Sections from '@/components/reservations/Center/Lista/Sections';
import _ from 'lodash';
import Vue from 'vue';
import { Portal as portal } from '@linusborg/vue-simple-portal';

export default {
  name: 'ListVue',
  components: { ReservationItem, Sections, ReporteReservation, portal },
  data: () => ({
    query: '',
    filteredReservations: [],
    reservationsBySection: {},
    listSearch: 'Todas',
    filterOptions: [],
    info: [],
    generate: false,
    dotClass: null,
    infoAlertYellow: null,
    showFilterMobile: false,
    windowWidth: window.innerWidth,
    activeModalReport: false,
    eventForModalReport: {}
  }),
  mounted () {
    this.updateDataList();
  },
  computed: {
    noReservationsMessage () {
      if (this.selectedDates.length > 1) {
        return ' en las horas seleccionadas';
      } else if (this.selectedDates.length > 0) {
        return ' en la hora seleccionadas';
      } else {
        return '';
      }
    }
  },
  methods: {
    getPeopleQuantity (reservations) {
      let peopleNumber = 0;
      reservations.forEach((reservation) => {
        peopleNumber += reservation.people;
      });
      return peopleNumber;
    },
    getWalkingQuantity (reservations) {
      let quantity = 0;
      reservations.forEach((reservation) => {
        if (reservation.referrer === 'Walk-In') {
          quantity++;
        }
      });
      return quantity;
    },
    getReservationsQuantity (reservations) {
      let quantity = 0;
      reservations.forEach((reservation) => {
        if (reservation.referrer !== 'Walk-In' && !reservation.balancePaid) {
          quantity++;
        }
      });
      return quantity;
    },
    getPrecomprasquantity (reservations) {
      let quantity = 0;
      reservations.forEach((reservation) => {
        if (reservation.balancePaid > 0) {
          quantity++;
        }
      });
      return quantity;
    },
    generateReport (e) {
      const posicion = e.target.getBoundingClientRect();
      this.eventForModalReport = { pageX: posicion.x + 8, pageY: posicion.y + 9 };
      if (this.activeModalReport) {
        this.activeModalReport = false;
        return;
      }
      this.activeModalReport = true;
    },
    isShow (time) {
      if (_.includes(this.info, time)) {
        const i = this.info.indexOf(time);
        this.info.splice(i, 1);
      } else {
        this.info.push(time);
      }
    },
    isHidden (time) {
      return _.includes(this.info, time);
    },
    addFilter (filter) {
      if (filter !== 'Todas') {
        if (!_.includes(this.filterOptions, filter)) {
          this.filterOptions.push(filter);
          this.listSearch = 'Todas';
          this.updateDataList();
        } else {
          this.listSearch = 'Todas';
        }
      }
    },
    delfilter (filter) {
      if (_.includes(this.filterOptions, filter)) {
        const i = this.filterOptions.indexOf(filter);
        this.filterOptions.splice(i, 1);
        this.updateDataList();
      }
    },
    getFilteredReservations () {
      if (!this.map) {
        const fil = this.filterOptions;
        const reservationList = _(this.reservations[this.selectedDate])
          .sortBy((reservation) => parseInt(reservation.date))
          .filter((reservation) => {
            let returnable = true;
            let data = false;
            if (fil.length > 0) {
              if (_.includes(fil, 'Próximas')) {
                if (data) {
                  returnable +=
                    reservation.codeStatus === 0 ||
                    reservation.codeStatus === 1 ||
                    reservation.codeStatus === 8 ||
                    reservation.codeStatus === 7;
                } else {
                  data = true;
                  returnable =
                    reservation.codeStatus === 0 ||
                    reservation.codeStatus === 1 ||
                    reservation.codeStatus === 8 ||
                    reservation.codeStatus === 7;
                }
              }
              if (_.includes(fil, 'Sentadas')) {
                if (data) {
                  returnable += reservation.codeStatus === 2;
                } else {
                  data = true;
                  returnable = reservation.codeStatus === 2;
                }
              }
              if (_.includes(fil, 'Finalizadas')) {
                if (data) {
                  returnable += reservation.codeStatus === 3;
                } else {
                  data = true;
                  returnable = reservation.codeStatus === 3;
                }
              }
              if (_.includes(fil, 'No llegó')) {
                if (data) {
                  returnable += reservation.codeStatus === 5;
                } else {
                  data = true;
                  returnable = reservation.codeStatus === 5;
                }
              }
              if (_.includes(fil, 'Canceladas')) {
                if (data) {
                  returnable += reservation.codeStatus === 4;
                } else {
                  data = true;
                  returnable = reservation.codeStatus === 4;
                }
              }
              if (_.includes(fil, 'Precompras')) {
                if (data) {
                  returnable += reservation.balancePaid > 0;
                } else {
                  data = true;
                  returnable = reservation.balancePaid > 0;
                }
              }
            }
            return (
              returnable &&
              (!_.size(this.selectedDates) ||
                _.includes(this.selectedDates, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss'))) &&
              (!_.size(this.sectionFilterSelect) ||
                _.includes(this.sectionFilterSelect, reservation.sectionName))
            );
          })
          .orderBy(
            (reservation) =>
              reservation.codeStatus === 3 ||
              reservation.codeStatus === 4 ||
              reservation.codeStatus === 5
          )
          .groupBy((reservation) => {
            const reservationDate = new Date(parseInt(reservation.date));
            return this.$moment(reservationDate).format('hh:mm a');
          })
          .value();
        return reservationList;
      }
    },
    getReservationsBySection () {
      const reservationList = _(this.reservations[this.selectedDate])
        .groupBy((reservation) => {
          return reservation.sectionName;
        })
        .value();
      return reservationList;
    },
    updateDataList () {
      Vue.nextTick()
        .then(() => new Promise((resolve) => setTimeout(resolve, 100)))
        .then(() => {
          this.filteredReservations = this.getFilteredReservations();
          this.reservationsBySection = this.getReservationsBySection();
        });
    },
    CloseMenuM () {
      this.activeModalReport = false;
    },
    OpenMenuM () {
      // eslint-disable-next-line
      classie.add(
        document.getElementById('DownloadXLS-list'),
        'show-menu-messager'
      );
    },
    getDotClass (filt) {
      switch (filt) {
        case 'Próximas':
          return 'dot-next';
        case 'Sentadas':
          return 'dot-sit';
        case 'Finalizadas':
          return 'dot-finished';
        case 'Canceladas':
          return 'dot-cancel';
        case 'No llegó':
          return 'dot-noShow';
        case 'Precompras':
          return 'dot-prec';
        default:
          return 'dot';
      }
    },
    customFilterList (reservations, query) {
      if (!query) return reservations;

      return reservations.filter((reservation) => {
        const isVipMatch = query.toLowerCase() === 'vip' && (reservation.isVip === 1 || reservation.isVip === true);
        const servicesMatch = Array.isArray(reservation.services) && reservation.services.length > 0 &&
          reservation.services.some((service) =>
            service.requirements.displayName.toLowerCase().includes(query.toLowerCase())
          );
        const generalMatch =
          reservation.displayName.toLowerCase().includes(query.toLowerCase()) ||
          reservation.phone?.includes(query) ||
          reservation.comments?.toLowerCase().includes(query.toLowerCase()) ||
          reservation.commentRestaurant?.toLowerCase().includes(query.toLowerCase()) ||
          reservation.tableName?.toLowerCase().includes(query.toLowerCase()) ||
          reservation.identityDocument?.toLowerCase().includes(query.toLowerCase());
        return isVipMatch || servicesMatch || generalMatch;
      });
    },
    isEmptyFilterList (reservations, query) {
      if (reservations && typeof reservations === 'object') {
        if (Object.values(reservations).length > 0 && !query) {
          return false;
        }
        const result = Object.values(reservations).map((items) => {
          return items.filter((reservation) => {
            const isVipMatch = query.toLowerCase() === 'vip' && (reservation.isVip === 1 || reservation.isVip === true);
            const servicesMatch = Array.isArray(reservation.services) && reservation.services.length > 0 &&
              reservation.services.some((service) =>
                service.requirements.displayName.toLowerCase().includes(query.toLowerCase())
              );
            const generalMatch =
              reservation.displayName.toLowerCase().includes(query.toLowerCase()) ||
              reservation.phone?.includes(query) ||
              reservation.comments?.toLowerCase().includes(query.toLowerCase()) ||
              reservation.commentRestaurant?.toLowerCase().includes(query.toLowerCase()) ||
              reservation.tableName?.toLowerCase().includes(query.toLowerCase()) ||
              reservation.identityDocument?.toLowerCase().includes(query.toLowerCase());
            return isVipMatch || servicesMatch || generalMatch;
          });
        });
        if (result.some(sub => sub.length > 0) === true) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  watch: {
    selectedDate () {
      this.updateDataList();
    },
    reservations () {
      this.updateDataList();
    },
    selectedDates () {
      this.updateDataList();
    },
    sectionFilterSelect () {
      this.filteredReservations = this.getFilteredReservations();
    },
    listSearch () {
      this.updateDataList();
    },
    showOld () {
      this.updateDataList();
    },
    infoAlertYellow () {
      this.$emit('dataAlertYellow', this.infoAlertYellow);
    },
    map () {
      this.activeModalReport = false;
      if (!this.map) {
        this.updateDataList();
      }
    }
  }
};
</script>

<style lang="scss">
.List-canvas, .modalForFilterList {
  .dot-prec {
    height: 5px;
    width: 5px;
    background: #c9d200;
    border-radius: 50%;
    margin-right: 5px;
  }
  .dot-next {
    height: 5px;
    width: 5px;
    background: #00a8c1;
    border-radius: 50%;
    margin-right: 5px;
  }
  .dot-sit {
    height: 5px;
    width: 5px;
    background: #def1f6;
    border-radius: 50%;
    margin-right: 5px;
  }
  .dot-finished {
    height: 5px;
    width: 5px;
    background: #71e356;
    border-radius: 50%;
    margin-right: 5px;
  }
  .dot-cancel {
    height: 5px;
    width: 5px;
    background: #d85757;
    border-radius: 50%;
    margin-right: 5px;
  }
  .dot-noShow {
    height: 5px;
    width: 5px;
    background: #ebeced;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.modalForFilterList, #filterDropDownList {
  .dot-all {
    height: 5px;
    width: 5px;
    background: #05121e;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.modalForFilterList {
  z-index: 100000;
  @media screen and (max-width: 600px) {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #00000073;
  }
  .contentModal {
    background-color: white;
    @media screen and (max-width: 600px) {
      padding: 10px;
      grid-template-columns: 1fr;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 80%;
      margin: auto;
      height: fit-content;
      width: 210px;
      border-radius: 6px;
      label, button {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row;
        border: 1px solid #d9dbde;
        padding: 12px 0;
        border-radius: 4px;
        justify-content: center;
        gap: 10px;
        margin-bottom: 3px;
        font-size: 14px;
        &.selectedOption {
          background-color: #444b57;
          color: white;
        }
        input {
          visibility: hidden;
        }
        &:hover {
          filter: brightness(1.05);
          cursor: pointer;
        }
      }
      button {
        border: 1px solid #444b57;
        width: 100%;
        border-radius: 9px;
        padding: 8px 0px;
        margin-top: 20px;
        margin-bottom: unset;
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
#filterDropDownList {
  &:hover {
    background-color: #dcdee1;
    width: fit-content;
    border-radius: 4px;
  }
}
.List-canvas {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #cccccc2e;
  border-radius: 5px 5px;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch !important;
  .filter-box{
    @media screen and (max-width:600px) {
      .titler{
        display: none;
      }
    }
  }
  .titler , .Nohay.box-loading .titler {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #444B57;
    &.hide{
      visibility: hidden;
    }
  }
  .dropdown.is-disabled {
    opacity: 1;
    cursor: pointer;
  }
  .dropdown.is-mobile-modal > .dropdown-menu {
    @media screen and (min-width: 600px) and (max-width: 1000px) {
      position: absolute !important;
      width: fit-content;
      max-width: unset;
      max-height: unset;
      top: unset !important;
      left: unset !important;
      bottom: unset !important;
      right: unset !important;
      transform: unset;
      white-space: normal;
      overflow-y: auto;
      height: fit-content;
      transform: translateY(25%);
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
      position: absolute !important;
      width: fit-content;
      max-width: unset;
      max-height: unset;
      top: unset !important;
      left: unset !important;
      bottom: unset !important;
      right: unset !important;
      transform: unset;
      white-space: normal;
      overflow-y: auto;
      height: fit-content;
      transform: translateY(25%);
    }
  }
  .dropdown-menu {
    width: 228px;
    transform: translateY(0%);
    .dropdown-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding: unset;
      overflow: hidden;
      border-radius: 4px;
      a {
        width: 100%;
        height: 30px;
        padding: unset;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        background-color: white;
        border-bottom: 1px solid #d9dbde;
        &:nth-child(2n - 1) {
          border-right: 1px solid #d9dbde;
        }
        &:nth-last-child(-n + 1) {
          border-bottom: unset;
        }
      }
    }
  }
  .filterModify {
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 1px 20px;
    &.haveFilters {
      background-color: #444b57;
      color: white;
      border: unset;
      position: relative;
      &::after{
        position: absolute;
        top: -5px;
        right: -5px;
        border-radius: 50%;
        background-color: #F83037;
        color: white;
        content: attr(filterQuantity);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 13px;
        height: 13px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
      }
    }
    i {
      font-size: 25px;
    }
  }
  /* Menu */
  .menu-wrap-messager {
    position: absolute;
    z-index: 999;
    background: transparent;
    -webkit-transition: width 0.3s, height 0.3s;
    transition: width 0.3s, height 0.3s;
    width: 0;
    height: 0;
    font-size: 1.5em;
    left: 10%;
    top: 0;
    right: -20%;
    -webkit-transform-origin: right top;
    transform-origin: right top;
    border-radius: 10px;
  }
  .menu-messager {
    opacity: 0;
    font-size: 0.65em;
    color: #000;
    text-align: right;
    border-radius: 10px;
    padding: 10px;
    z-index: 2;
    position: relative;
    background: #fff;
  }
  .not-show-menu {
    display: none;
    z-index: 9999;
    position: relative;
    max-width: 338px;
  }
  .show-menu-messager {
    display: flex;
    position: absolute;
    top: 115%;
    width: 350px;
    right: 10px;
    .circle-absolute {
      background: #fff;
      height: 30px;
      width: 30px;
      position: absolute;
      right: 25px;
      top: 0px;
      border-radius: 0;
      z-index: 1;
      transform: rotate(45deg);
    }
  }
  /* Shown menu */
  .show-menu-messager .table-detail {
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  .show-menu-messager .menu-wrap-messager {
    width: 100%;
    -webkit-animation: anim-jelly 0.8s linear forwards;
    animation: anim-jelly 0.8s linear forwards;
    top: 0;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  .show-menu-messager .menu-messager {
    opacity: 1;
    -webkit-transition: opacity 0.3s 0.3s;
    transition: opacity 0.3s 0.3s;
    -webkit-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    z-index: 2;
    .table-detail {
      opacity: 1;
    }
  }
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 6px 8px 0px 8px;
    @media screen and (max-width: 600px) {
      padding: 2px 0px 0px 0px;
    }
    .filter-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 9999;
      width: 100%;
      margin-top: 4px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      .rightSide{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (max-width: 600px) {
          flex: 1;
          gap:15px;
          .Serach-box{
            width: 210px;
          }
        }
      }
      label.item-dd {
        padding-left: 16px;
        width: 100%;
        height: 30px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        input {
          visibility: hidden;
        }
        &.selectedOption {
          background-color: #444b57;
          color: white;
        }
      }
      .Serach-box {
        height: 100%;
        border: 1px solid #dddddd;
        display: flex;
        margin: 0 10px;
        border-radius: 6px;
        align-items: center;
        padding: 2px 5px;
        width: 180px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-left: 25px;
        @media screen and (min-width: 900px) and (max-width: 1200px) {
          width: 120px;
        }
        @media screen and (max-width: 600px) {
          margin: unset;
        }
        input {
          border: none;
          outline: none;
          height: 25px;
          width: 50%;
          flex: 1;
        }
        i {
          font-size: 22px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .Headers-box {
    background: #444b57;
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 13px;
    width: calc(100%);
    .content-fills {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      flex: 11;
    }
    .column {
      padding: 8px 10px;
    }
    .names {
      width: 100px;
    }
    .peoples {
      min-width: 60px;
      max-width: 60px;
      border: 1px solid #353a43;
      border-top: 0;
      border-bottom: 0;
    }
    .mesas {
      min-width: 90px;
      border-right: 1px solid #353a43;
    }
    .rerements {
      min-width: 140px;
    }
    .timer {
      padding: 0 10px 0 0;
      min-width: 90px;
    }
    .actions {
      min-width: 70px;
      max-width: 70px;
      height: 100%;
      padding: 0 10px;
    }
  }
  @media (max-width: 1000px) {
    .show-menu-messager {
      top: 7%;
      right: 0;
      left: 0;
      .menu-wrap-messager {
        left: 1%;
      }
    }
  }
  .header-hour {
    z-index: -1;
    align-items: center;
    justify-content: space-between;
    background: #dcdee1;
    padding: 3px 2px;
    color: #444b56;
    font-size: 11px;
    margin: 5px 0;
    flex-direction: row;
    display: flex;
    position: relative;
    border-radius: 5px;
    height: 26px;
    .side {
      display: flex;
      align-items: center;
      width: 50%;
      position: relative;
      &.left {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        justify-content: flex-start;
        margin-left: 10px;
      }
      &.right {
        justify-content: flex-end;
        height: 20px;
        section {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          height: 100%;
          font-size: 13px;
        }
        div {
          margin-right: 10px;
        }
        i {
          transform: translateY(1.5px);
          height: 80%;
          width: 18px;
        }
        .material-icons {
          color: #9ba0a5;
          margin-top: 0px;
          display: flex;
        }
        .number-top {
          margin-top: -13px;
          font-size: 9px;
          font-weight: 700;
          margin-left: -3px;
        }
      }
    }
    .dot {
      height: 5px;
      width: 5px;
      background: #71e356;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .content-list {
    max-height: calc(100%);
    overflow-y: auto;
    z-index: 1 !important;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;
    @media screen and (min-width: 600px) and (max-width: 900px) {
      padding-left: 2px;
    }
    @media screen and (max-width: 600px) {
      padding: unset;
    }
    ::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #606773 !important;
      border-radius: 4px !important;
      box-shadow: unset !important;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  @media (max-width: 414px) {
    .Headers-box {
      display: none !important;
    }
  }
  .reservation-empty {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .Nohay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.box-loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.calendar-bg {
        height: calc(100% - 2.5rem);
        position: absolute;
        width: calc(100% - 2rem);
        justify-content: center;
      }
      span {
        color: #fff;
        margin-top: 10px;
      }
    }
    .loading {
      width: 100px;
      height: 100px;
      background: rgba(67, 74, 86, 0.3);
      margin-bottom: 2px;
      margin-top: 20px;
    }
  }
}
.btn-tag-filter {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 30px;
  padding: 0px 12px;
  border-radius: 4px;
  border: solid 1px black;
  gap: 11px;
  .dot-next {
    margin: unset;
  }
  i {
    position: unset;
    width: 14px;
    height: 14px;
    mask-size: cover;
    background: #374957;
    transform: translateY(1px);
    cursor: pointer;
  }
}
</style>
