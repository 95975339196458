<template>
  <div class="sidebar-info-container__section-filter-dates__date__ranges" :class="{'sidebar-info-container__section-filter-dates__date__ranges-modal' : modeModal, 'sidebar-info-container__section-filter-dates__date__ranges__general' : sectionId === 4}">
    <i class="icon-mks icon_close" @click="()=>{
      !modeModal ? $emit('closeFilters') : $parent.close();
    }"></i>
    <h3 v-if="!modeModal">
      Seleccione el rango de fechas
    </h3>
    <div v-else class="sidebar-info-container__section-filter-dates__date__ranges__start-final-dates">
      <div class="sidebar-info-container__section-filter-dates__date__ranges__start-final-dates__date">
        <h3>Seleccione la fecha inicio</h3>
        <span class="no-opacity-date" v-if="dateFromGlobal !== null">{{dateFromGlobal | moment("DD/MM/YYYY") }}</span>
        <span v-else>00/00/2024</span>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="51" height="12" viewBox="0 0 51 12" fill="none">
        <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM51 6L41 0.226497V11.7735L51 6ZM1 7H42V5H1V7Z" fill="#444B57"/>
      </svg>
      <div class="sidebar-info-container__section-filter-dates__date__ranges__start-final-dates__date">
        <h3>Selecciona la fecha fin</h3>
        <span class="no-opacity-date" v-if="dateToGlobal !== null">{{dateToGlobal | moment("DD/MM/YYYY") }}</span>
        <span v-else>00/00/2024</span>
      </div>
    </div>
    <v-date-picker
      id="daysContainer"
      v-model="dateFixPicker"
      is-range
      :columns="2"
      transition="fade"
      :theme-styles="themeStyles"
      :from-page="fromPage"
      :masks="dateMasks"
      @dayclick="validateDateRange"
      :show-edge-dates="false"
      :min-date="new Date(yearGlobal, 0, 1)"
      :max-date="new Date(yearGlobal, 11, 31)"
    >
    </v-date-picker>
    <b-button v-if="!modeModal" :disabled="!valueDate || (!valueDate.start && !valueDate.end)" @click="() =>{
      $emit('filterWithDates');
    }">Buscar</b-button>
    <b-button v-else :disabled="!dateDisplay || (!dateDisplay.start && !dateDisplay.end)" @click="() =>{
      $emit('filterWithDates');
      $parent.close();
    }">Buscar</b-button>
  </div>
</template>

<script>
import metricsTypes from '@/store/types/metrics';
import { mapGetters } from 'vuex';

export default {
  props: ['valueDate', 'dateMasks', 'themeStyles', 'modeModal', 'sectionId'],
  data () {
    return {
      dateDisplay: null,
      dateFixPicker: null,
      tempStartDate: null,
      saveDate: false
    };
  },
  computed: {
    ...mapGetters({
      dateFromGlobal: [metricsTypes.getters.dateFrom],
      dateToGlobal: [metricsTypes.getters.dateTo],
      yearGlobal: [metricsTypes.getters.year]
    }),
    fromPage () {
      if (this.valueDate?.start) {
        const startDate = new Date(this.valueDate.start);
        return { month: startDate.getMonth() + 1, year: startDate.getFullYear() }; // +1 porque getMonth() es 0-indexado
      }
      // Fecha predeterminada si no hay `valueDate`
      const defaultDate = new Date();
      return { month: defaultDate.getMonth() + 1, year: defaultDate.getFullYear() };
    }
  },
  mounted () {
    if (!this.valueDate) {
      this.dateDisplay = null;
    }
    this.dateFixPicker = this.valueDate;
  },
  methods: {
    changeDataAux (val) {
      if (this.saveDate) {
        const dateFromFormat = this.$moment(val.start)
          .set('year', this.yearGlobal)
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const dateToFormat = this.$moment(val.end)
          .set('year', this.yearGlobal)
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const updatedDate = {
          start: dateFromFormat,
          end: dateToFormat
        };
        this.dateDisplay = updatedDate;
        this.$emit('changeDate', updatedDate);
      }
    },
    validateDateRange (date) {
      if (!this.dateFixPicker?.start) {
        this.tempStartDate = date.date; // Accede al objeto Date desde el parámetro
        return;
      }
      if (new Date(date.date) < new Date(this.tempStartDate) || !this.tempStartDate) {
        this.$nextTick(() => {
          this.dateFixPicker = { start: date.date, end: null };
          this.tempStartDate = date.date;
          this.saveDate = false;
        });
      } else {
        this.saveDate = true;
      }
    }
  },
  watch: {
    yearGlobal (val) {
      const updatedData = {
        start: this.$moment(this.valueDate.start).set('year', val).toISOString(),
        end: this.$moment(this.valueDate.end).set('year', val).toISOString()
      };
      this.$emit('changeDate', updatedData);
    },
    sectionId () {
      this.dateDisplay = null;
      this.$emit('changeDate', null);
      this.$emit('closeFilters');
    },
    dateFixPicker (val) {
      if (val) {
        this.changeDataAux(val);
      }
    }
  }
};
</script>

<style>

</style>
