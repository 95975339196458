<template>
  <div
    :class="{
      opacity25:
        reservation.codeStatus == 3 ||
        reservation.codeStatus == 4 ||
        reservation.codeStatus == 5,
    }"
    id="ReservItemList"
    ref="ItemList"
  >
    <div :style="{ 'z-index': zindex }" class="item-list animated fadeIn columns is-mobile">
      <div
        :class="getLeftBarClass(reservation)"
      ></div>
      <section class="infoContainer">
        <div
          :class="getBackgroundListClass(reservation)"
          @click="isShowViewReservation(reservation)"
          class="name column infoSpace"
          v-tooltip="{
            placement: 'bottom',
          }"
          v-if="validatePermission('Finish') || validatePermission('MixSFCN')"
        >
          <p>{{ maxText(reservation.displayName) | lowercase }}</p>
          <div class="rightSide">
            <div class="client-icon visit icon_star_fill_visit icon-mks black-pre"
              v-tooltip="{ content: `Asistencias: ${showUser}`, placement: 'bottom' }" v-if="showUser && showUser > 1">
              <span class="visit-number">{{ showUser }}</span>
            </div>
            <div class="client-icon no-show icon_rombo_no_show icon-mks red"
              v-tooltip="{ content: `No-show: ${notShowUser}`, placement: 'bottom' }" v-if="notShowUser && notShowUser > 1">
              <span class="visit-number">{{ notShowUser }}</span>
            </div>
            <GeneralTags :infoReserva="reservation" :existTag="reservation.isExistsTag === 1" :customClass="'container-tags-reservation-global-in-items-list'" />
            <a class="vipItem" v-if="reservation.isHotel">
              <i
                class="icon-mks black icon_hotel_habitation"
                style="margin: 0px; height: 15px; width: 15px;"
                v-tooltip="{content: 'Huésped', placement: 'bottom'}"
              ></i>
            </a>
          </div>
        </div>
        <div
          :class="getBackgroundListClass(reservation)"
          @click="isShowViewReservation(reservation)"
          class="people infoSpace"
        >
          <i class="icon_person icon-mks black"></i>&nbsp;
          <span>
            {{
            reservation.people
            }}
          </span>
        </div>
        <div
          :class="getBackgroundListClass(reservation)"
          @click="isShowViewReservation(reservation)"
          class="mesa column infoSpace"
        >
          <span v-if="sections.length">
            {{
            textTable(reservation.tableName, reservation.sectionName , reservation.subSectionName)
            }}
          </span>
          <span v-else>{{ textTable(reservation.tableName) }}</span>
          <span class="threePoints" v-if="reservation.tableName.includes(',')">
            <i></i>
            <i></i>
            <i></i>
          </span>
        </div>
        <section
          :class="[getBackgroundListClass(reservation), {
            'before-fix-cancel' : reservation.codeStatus === 4 || reservation.codeStatus == 3,
            'noClick':modalActive,
          }]"
          @click="()=> addShowService(reservation)"
          @touchend="() => windowWidth > 1100 ? addShowService(reservation) : null"
          class="lastInfo column infoSpace allSpaceAvailable"
          ref="lastInfo"
          :style="reservation.codeStatus === 4 || reservation.codeStatus == 3 ? 'border-right:0px; border-radius: 6px 0px 0px 6px;' : ''"
        >
          <!-- v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', theme: 'light-border'}" -->
          <portal selector=".content-wrap">
            <div
              :class="{
                modalSemi: (reservation.celebrationName && !reservation.overbook) || (!reservation.celebrationName && reservation.overbook),
                alertswithoutEvent:reservation.overbook,
                modalComplete: reservation.celebrationName && reservation.overbook,
                elementIsCloserToTop: elementIsCloserToTop,
              }"
              :style="{ top: (distanceToTop + (windowWidth <= 600 ? 0 : 2)) + 'px' }"
              class="columnx"
              ref="ModalService"
              v-click-outside="(e) => delShowService(e)"
              v-if="
                serviceShow.services != null || serviceShow.comments != null
              "
            >
              <div class="Requeriments">
                <span class="title">
                  <div class="virtualBorder"></div>
                  <i class="icon_requeriments"></i>&nbsp;Requerimientos
                </span>
                <center
                  class="cont"
                  v-if="
                    !serviceShow.services || serviceShow.services.length === 0
                  "
                >
                  <span class="comentarios">Sin requerimientos</span>
                  <div class="virtualBorder"></div>
                </center>
                <center class="cont requerimentContainer" v-if="serviceShow.services.length > 0">
                  <div class="virtualBorder"></div>
                  <a
                    :key="pos"
                    class="item-requirement"
                    href="javascript:void(0)"
                    v-for="(service, pos) in serviceShow.services"
                  >
                    {{
                    transformServices(service.requirements.displayName)
                    }}
                  </a>
                </center>
              </div>
              <div class="Comments">
                <span class="title">
                  <i class="icon_carateristicas"></i> Comentarios
                </span>
                <center class="cont" v-if="!serviceShow.comments && !serviceShow.commentRestaurant">
                  <span class="comentarios">Sin comentarios</span>
                </center>
                <center class="cont comentariosContainer" :style="`${(serviceShow.comments !== null && serviceShow.comments !== '') && (serviceShow.commentRestaurant !== null && serviceShow.commentRestaurant !== '') ? 'grid-template-columns: 1fr 1fr;': ''}`" v-else>
                  <div class="container-comments-list-reservation" v-if="serviceShow.comments !== null && serviceShow.comments !== ''">
                    <h4>Comentario cliente</h4>
                    <span
                      class="comentarios"
                    >{{ serviceShow.comments }}</span>
                  </div>
                  <div class="container-comments-list-reservation" v-if="serviceShow.commentRestaurant !== null && serviceShow.commentRestaurant !== ''">
                    <h4>Comentario Restaurante</h4>
                    <span
                      class="comentarios"
                    >{{ serviceShow.commentRestaurant }}</span>
                  </div>
                </center>
              </div>
              <div class="Celebrations" v-if="reservation.celebrationName">
                <span class="title">
                  <div class="virtualBorder"></div>
                  <i class="icon_celebraciones"></i>&nbsp;Celebracion:
                </span>
                <center class="cont celebrationContainer">
                  <div class="celebration">
                    <img :src="reservation.celebrationIcon" alt />
                    {{ reservation.celebrationName }}
                  </div>
                  <div class="virtualBorder"></div>
                </center>
              </div>
              <div class="Alerts" v-if="reservation.overbook">
                <span class="title">
                  <div class="virtualBorder"></div>
                  <i class="icon_alert_danger"></i>&nbsp;Alertas:
                </span>
                <center class="cont alertsContainer">
                  <div
                    :key="sobrereservation.id"
                    class="itemAlerts"
                    v-for="sobrereservation in reservation.overbook"
                  >
                    <div
                      :class="{'background-list-walk-In':sobrereservation.referrer==='Walk-In','background-list-precompra':sobrereservation.balancePaid>0}"
                      class="blockType"
                    ></div>
                    <i class="icon_dashboard_clock icon-mks black"></i>
                    <p class="hourItem">{{Number(sobrereservation.date) | moment('hh:mm a')}}</p>
                    <p class="displayName">{{sobrereservation.displayName}}</p>
                    <p class="peopleQuantity">{{sobrereservation.people}}</p>
                    <i
                      @click.prevent.stop="setReservationSeated(sobrereservation)"
                      class="icon_dashboard_seat black icon-mks"
                      v-if="(selectedDate == systemDate.substr(0, 10) || selectedDate == yesterday) && sobrereservation.status !=='Sentada' && validatePermission('Seated')"
                    ></i>
                    <i
                      @click="isShowViewReservation(reservation)"
                      class="icon_estadofinalizar black icon-mks"
                      v-else
                    ></i>
                  </div>
                  <div class="virtualBorder"></div>
                </center>
              </div>
              <div class="Alerts" v-if="reservation.hasDuplicatedReservations">
                <span class="title">
                  <div class="virtualBorder"></div>
                  <img
                    class="icon-mks icon-info-alert"
                    src="@/assets/icons/alert_yellow.svg"
                    style="width: 13px; height: 13px!important; margin-right: unset;"
                    v-if="
                      reservation.hasDuplicatedReservations &&
                      (reservation.codeStatus === 1 ||
                        reservation.codeStatus === 0 ||
                        reservation.codeStatus === 7)
                    "
                    v-tooltip="{
                      content: 'Usuario con otras Reservas',
                      placement: 'bottom',
                    }"
                  />
                  <span class="atention">¡Atención!</span>
                </span>
                <center class="cont" :style="{'flex-direction': (reservation.relatedReservations) ? 'column' : 'row'}">
                  <div class="virtualBorder"></div>
                  <span class="alert-message">Este usuario tiene una reserva en otros restaurantes a la misma hora.</span>
                  <div class="icon-logo">
                    <div v-for="(log, index) in reservation.relatedReservations" :key="'log' + index">
                      <img style="width: 21px; height: 14px;" v-if="log.logo" :src="log.logo" alt="precompro.com"/>
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </portal>
          <div class="icons-requeriments allSpaceAvailable">
            <div class="allIconsContainer">
              <img
                class="icon-mks walk"
                style="height: 20px!important; width: 15px!important"
                v-if="reservation.referrer == 'Walk-In'"
                src="@/assets/icons/allTimeLineIcons/walkIcon.svg"
                v-tooltip="{ content: 'Walking', placement: 'bottom' }"
              />
              <img v-else-if="getUrlImageReferrer(reservation.referrer) !== null" :src="getUrlImageReferrer(reservation.referrer)" height="16" width="16" v-tooltip="{content: `Reserva ${reservation.referrer === 'dashboard' ? 'teléfono': reservation.referrer}`, placement: 'bottom'}">
              <i
                class="icon-mks black icon_alergy"
                style="height: 18px !important; width: 18px !important; margin-top:0;"
                v-if="reservation.alergies != null"
                v-tooltip="{
                  content: 'Alergia(s): ' + (reservation.alergies.length > 15 ? reservation.alergies.substring(0, 15) + '...'  : reservation.alergies),
                  placement: 'bottom',
                }"
              ></i>
              <i class="icon-mks icon_pets"
                v-if="reservation.hasPets && reservation.hasPets === 1"
                style="height: 18px !important; width: 18px !important; margin-top:0;"
              >
              </i>
              <img class="icon_glob" src="@/assets/icons/globo_2.svg" v-if="reservation.comments || reservation.commentRestaurant" />
              <img
                :key="llave"
                :src="serv.requirements.iconURL"
                v-for="(serv, llave) in reservation.services"
                v-show=" llave < iconsQuantity"
              />
              <span class="threePoints" v-if="reservation.services.length > iconsQuantity">
                <i></i>
                <i></i>
                <i></i>
              </span>
              <i
                class="icon-mks black icon_decoration"
                style="margin-right: 2px; height: 16px !important; width: 16px !important;"
                v-if="
                  vendor.id !== 172 &&
                  vendor.id !== 173 &&
                  reservation.decoration
                "
                v-tooltip="{ content: 'Tiene Decoración', placement: 'bottom' }"
              ></i>
              <i
                class="icon-mks black icon_heart"
                style="margin-right: 2px; height: 16px !important; width: 16px !important;"
                v-if="
                  (vendor.id == 172 || vendor.id == 173) &&
                  reservation.decoration
                "
                v-tooltip="{ content: 'Tiene Decoración', placement: 'bottom' }"
              ></i>
              <img
                :src="reservation.celebrationIcon"
                height="30"
                v-if="reservation.celebrationName != null"
                v-tooltip="{
                  content: 'Tiene Celebración',
                  placement: 'bottom',
                }"
                width="30"
              />
            </div>
          </div>
          <div class="alertContainer" v-if="reservation.hasDuplicatedReservations">
            <img
              class="icon-mks icon-info-alert"
              src="@/assets/icons/alert_yellow.svg"
              v-if="
                reservation.hasDuplicatedReservations &&
                (reservation.codeStatus === 1 ||
                  reservation.codeStatus === 0 ||
                  reservation.codeStatus === 7)
              "
              v-tooltip="{
                content: 'Usuario con otras Reservas',
                placement: 'bottom',
              }"
            />
          </div>
          <div
            class="balancedPaid"
            v-if="reservation.balancePaid > 0 && reservation.codeStatus !== 4"
          >{{ pesosFormater(reservation.balancePaid) }}</div>
          <div
            :class="{
              cancelada: reservation.codeStatus == 4,
              finalizada: reservation.codeStatus == 3,
            }"
            class="timer"
            v-if="reservation.codeStatus != 2"
          >
            <span
              :class="{ cancelada: reservation.codeStatus == 4 }"
              class="import-info"
              v-if="true"
            >
              &nbsp;
              <i class="icon_ok" v-if="reservation.codeStatus === 3"></i>
              <i class="icon_cancel" v-if="reservation.codeStatus === 4"></i>
              <i class="icon_prohibited" v-if="reservation.codeStatus === 5"></i>
              <p class="timeCount" :style="reservation.codeStatus === 4 || reservation.codeStatus == 3 ? 'margin-right: -70px;' : ''">{{ statusDisplay(reservation) }}</p>
            </span>
          </div>
          <div :class="{}" class="timer" v-if="reservation.codeStatus === 2">
            <i class="icon_dashboard_clock icon-mks black"></i>
            <p
              :class="{ hourMark: reservation.codeStatus === 2 }"
              class="timeCount"
            >{{ statusDisplay(reservation) }}</p>
          </div>
          <i
            class="icon_alert_danger mainList"
            v-if="reservation.overbook && reservation.codeStatus !== 2"
          ></i>
        </section>
        <div
          class="is-1 actions"
          v-if="
            !executeAction &&
            (reservation.codeStatus <= 2 || reservation.codeStatus == 6 || reservation.codeStatus == 8) &&
            !validatePermission('ReadOnly') && !validatePermission('MixSFCN')
          "
        >
          <button
            :class="[getBackgroundListClass(reservation),{
              'background-list-grey': reservation.codeStatus > 2,
            }]"
            @click.prevent.stop="moveResevation(null, reservation)"
            class="btn-actions infoSpace"
            v-tooltip="{ content: 'Mover Reserva', placement: 'bottom' }"
            v-if="reservation.codeStatus <= 2"
          >
            <i aria-hidden="true" class="fa fa-arrows"></i>
          </button>
          <button
            :class="getBackgroundListClass(reservation)"
            @click.prevent.stop="setReservationRequestConfirmed(reservation)"
            class="btn-actions cursor infoSpace"
            title="Confirmar solicitud de reserva"
            v-if="reservation.codeStatus === 8"
          >
            <i class="icon_gray icon-mks gray"></i>
          </button>
          <button
            :class="getBackgroundListClass(reservation)"
            @click.prevent.stop="setReservationConfirmed(reservation)"
            class="btn-actions cursor infoSpace"
            v-if="reservation.codeStatus === 0"
          >
            <i class="icon_gray icon-mks gray"></i>
          </button>
          <button
            :class="getBackgroundListClass(reservation)"
            @click.prevent.stop="setNotReservationConfirmed(reservation)"
            class="btn-actions flex cursor infoSpace"
            v-if="reservation.codeStatus == 1"
          >
            <img
              class="img-check"
              src="@/assets/icons/icono-check.svg"
              title="Reserva confirmada por restaurante"
            />
          </button>
          <button
            :class="getBackgroundListClass(reservation)"
            class="btn-actions flex infoSpace"
            v-if="
              reservation.isUserConfirmed == 'email' ||
              reservation.isUserConfirmed == 'sms' ||
              reservation.isUserConfirmed == 'whatsapp' ||
              reservation.isUserConfirmed == 'integration'
            "
          >
            <img
              alt="Reserva Confirmada"
              class="img-check"
              src="@/assets/icons/icoon-check-2.svg"
              title="Reserva confirmada por usuario"
            />
          </button>
          <button
            class="btn-actions infoSpace"
            @click.prevent.stop="setReservationSeatedPartial(reservation)"
            v-if="
              (selectedDate == systemDate.substr(0, 10) ||
                selectedDate == yesterday) &&
              (reservation.codeStatus == 1 || reservation.codeStatus == 0) &&
              params.activatePartialSeated &&
              validatePermission('Seated') &&
              validateSittingOnlyHour(reservation)
            "
            v-tooltip="{
              content: 'Sentar Reserva Parcial',
              placement: 'bottom',
            }"
            :class="getBackgroundListClass(reservation)"
          >
            <img
              class="seatpartial"
              src="@/assets/icons/sentado-parcial.svg"
              v-if="parseInt(reservation.balancePaid) <= 0"
            />
            <img
              class="seatpartial"
              src="@/assets/icons/icono-sentado-verde.svg"
              v-if="parseInt(reservation.balancePaid) > 0"
            />
          </button>
          <button
            :class="getBackgroundListClass(reservation)"
            @click="oldStatus('isPartialSeated', reservation.id)"
            class="btn-actions infoSpace"
            v-if="
              reservation.referrer !== 'Walk-In' && reservation.codeStatus == 6
            "
            v-tooltip="{ content: 'Regresar a Próximas', placement: 'bottom' }"
          >
            <i class="icon_reload black icon-mks"></i>
          </button>
          <button
            :class="getBackgroundListClass(reservation)"
            @click="oldStatus('isSeated', reservation.id)"
            class="btn-actions infoSpace"
            v-if="
              reservation.referrer !== 'Walk-In' &&
              reservation.codeStatus == 2 &&
              reservation.codeStatus != 4 &&
              reservation.codeStatus != 3
            "
          >
            <i class="icon_reload black icon-mks"></i>
          </button>
          <button
            :class="getBackgroundListClass(reservation)"
            @click.prevent.stop="setReservationSeated(reservation)"
            class="btn-actions infoSpace"
            v-if="
              (validateSittingOnlyHour(reservation) ||
                selectedDate == yesterday) &&
              !(
                reservation.codeStatus == 2 &&
                reservation.codeStatus != 4 &&
                reservation.codeStatus != 3
              ) &&
              (validatePermission('Seated') || validatePermission('MixSFCN'))
            "
          >
            <i class="icon_dashboard_seat black icon-mks"></i>
          </button>
          <button
            :class="[getBackgroundListClass(reservation),{
              'background-list-grey': !validateSitting(reservation),
            }]"
            @click.prevent.stop="setReservationSeated(reservation)"
            class="btn-actions infoSpace"
            v-else-if="
              reservation.referrer != 'Walk-In' &&
              !(
                reservation.codeStatus == 2 &&
                reservation.codeStatus != 4 &&
                reservation.codeStatus != 3
              ) &&
              (validatePermission('Seated') || validatePermission('MixSFCN')) &&
              validateSitting(reservation)
            "
            v-tooltip="{ content: 'Sentar Reserva', placement: 'bottom' }"
          >
            <i class="icon_dashboard_seat black icon-mks"></i>
          </button>
          <button
            :class="[getBackgroundListClass(reservation)]"
            @click="oldStatus('walk-in', reservation.id)"
            class="btn-actions infoSpace"
            v-if="reservation.referrer === 'Walk-In' && reservation.codeStatus == 2"
          >
            <i class="icon_reload black icon-mks"></i>
          </button>
          <button
            :class="getBackgroundListClass(reservation)"
            @click.prevent.stop="isShowViewReservation(reservation)"
            class="btn-actions infoSpace"
            v-if="
              reservation.codeStatus == 2 &&
              reservation.codeStatus != 4 &&
              reservation.codeStatus != 3 &&
              (validatePermission('Finish') || validatePermission('MixSFCN'))
            "
          >
            <i class="icon_estadofinalizar black icon-mks"></i>
          </button>
        </div>
        <div
          class="actions"
          v-if="
            (reservation.codeStatus == 4 && (!reservation.balancePaid || reservation.balancePaid)) ||
            reservation.codeStatus == 3
          "
        >
          <div v-if="reservation.codeStatus === 4 || reservation.codeStatus == 3" class="fix-spaces-between-content"></div>
          <button
            :class="[getBackgroundListClass(reservation),{
              'background-list-grey': reservation.balancePaid,
            }]"
            @click="oldStatus(transfromCodeStatus(reservation.codeStatus), reservation.id)"
            class="btn-actions infoSpace"
            v-tooltip="{ content: 'Regresar a Próximas', placement: 'bottom' }"
          >
            <i class="icon_reload black icon-mks" v-if="!reservation.balancePaid"></i>
          </button>
        </div>
        <div class="is-1 actions" v-if="reservation.codeStatus > 3 && reservation.codeStatus > 6"></div>
        <div class="executeAction" v-if="executeAction">
          <i class="fa fa-spinner fa-spin"></i>&nbsp;
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { Portal as portal } from '@linusborg/vue-simple-portal';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import { reservationCodes } from '@/helpers/reservationCodes';
import _ from 'lodash';
import GeneralTags from '@/components/Extends/GeneralTags';
import AlertRequest from '@/components/_shared/AlertRequest';

export default {
  components: { portal, GeneralTags },
  props: ['reservation', 'pos', 'i', 'count', 'zindex'],
  data: () => ({
    iconsQuantity: 0,
    now: null,
    cancelShow: false,
    executeAction: false,
    elementIsCloserToTop: false,
    distanceToTop: 0,
    windowWidth: window.innerWidth,
    modalActive: false,
    serviceShow: {
      comments: null,
      commentRestaurant: null,
      services: null,
      removingModalTime: null
    },
    alertShow: {
      show: false,
      vendors: []
    },
    yesterday: null,
    alerts: [],
    isLoading: false,
    intervalModal: null,
    touchdisabled: false,
    userReservations: null
  }),
  computed: {
    ...mapGetters({
      additionalQuestions: [reservationTypes.getters.additionalQuestions],
      systemDate: [globalTypes.getters.serverTime]
    }),
    showUser () {
      if (this.reservation.peopleMetrics) {
        return this.reservation.peopleMetrics.show;
      } else {
        return 0;
      }
    },
    notShowUser () {
      if (this.reservation.peopleMetrics) {
        return this.reservation.peopleMetrics.noShow;
      } else {
        return 0;
      }
    }
  },
  beforeMount () {
    let interval;
    this.obtenerAnchoPantalla();
    window.addEventListener('resize', this.obtenerAnchoPantalla);
    document.getElementsByClassName('content-list')[0].addEventListener('scroll', () => {
      clearInterval(interval);
      interval = setInterval(() => {
        this.touchdisabled = false;
        clearInterval(interval);
      }, 300);
      this.touchdisabled = true;
    });
  },
  mounted () {
    this.now = this.$moment().valueOf();
    this.interval = setInterval(() => {
      this.now = this.$moment().valueOf();
    }, 1000);
    this.yesterday = this.$moment(this.systemDate)
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
  },
  destroyed () {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.obtenerAnchoPantalla);
  },
  methods: {
    getLeftBarClass (res) {
      let leftBarClass = '';
      if ((res.codeStatus === 8)) leftBarClass = 'left-bar-request-reservation';
      if (res.balancePaid > 0 && res.referrer !== 'Walk-In' && (res.codeStatus === 7)) leftBarClass = 'left-bar-precompra-pending';

      if (res.balancePaid > 0 && res.referrer !== 'Walk-In' &&
        (res.codeStatus === 3 || res.codeStatus === 2 || res.codeStatus === 1 || res.codeStatus === 0)
      ) leftBarClass = 'left-bar-precompra';

      if ((res.balancePaid === 0 || res.balancePaid === null) && res.referrer !== 'Walk-In' &&
        (res.codeStatus === 3 || res.codeStatus === 2 || res.codeStatus === 1 || res.codeStatus === 0 || res.codeStatus === 6)
      ) leftBarClass = 'left-bar-reserva';

      if (res.referrer !== 'Walk-In' && res.codeStatus === 4) leftBarClass = 'left-bar-reserva-cancel';

      if (res.referrer === 'Walk-In') leftBarClass = 'left-bar-walkin';

      if ((res.balancePaid === 0 || res.balancePaid == null) && res.referrer !== 'Walk-In' && res.codeStatus === 5) leftBarClass = 'left-bar-reserva-noShow';

      if ((this.now > parseInt(res.date) &&
        this.now < parseInt(res.date) + 1000 * 60 * (parseInt(this.params.anticipationUserTime) + 1)) &&
        res.referrer !== 'Walk-In' && (res.codeStatus === 0 || res.codeStatus === 1)
      ) leftBarClass = 'left-bar-sobre-hora';

      if (this.now > parseInt(res.date) + 1000 * 60 * (parseInt(this.params.anticipationUserTime) + 1) && res.referrer !== 'Walk-In' &&
        (res.codeStatus === 0 || res.codeStatus === 1)
      ) leftBarClass = 'left-bar-retrasada';

      return leftBarClass;
    },
    getBackgroundListClass (res) {
      let backgroundClass = '';
      if (res.balancePaid > 0 && res.referrer !== 'Walk-In' && res.codeStatus === 2) backgroundClass = 'background-list-precompra';

      if ((res.balancePaid === 0 || res.balancePaid == null) && res.referrer !== 'Walk-In' && res.codeStatus === 2) backgroundClass = 'background-list-reserva';

      if ((res.balancePaid === 0 || res.balancePaid == null) && res.referrer !== 'Walk-In' && res.codeStatus === 6) backgroundClass = 'background-list-reserva-parcial';

      if (res.referrer === 'Walk-In' && res.codeStatus === 2) backgroundClass = 'background-list-walk-In';

      if ((this.now > parseInt(res.date) &&
        this.now < parseInt(res.date) + 1000 * 60 * (parseInt(this.params.anticipationUserTime) + 1)) &&
        res.referrer !== 'Walk-In' && (res.codeStatus === 0 || res.codeStatus === 1)
      ) backgroundClass = 'background-list-sobre-hora';

      if (this.now > parseInt(res.date) + 1000 * 60 * (parseInt(this.params.anticipationUserTime) + 1) && res.referrer !== 'Walk-In' &&
        (res.codeStatus === 0 || res.codeStatus === 1)
      ) backgroundClass = 'background-list-retrasada';

      return backgroundClass;
    },
    transfromCodeStatus (code) {
      return reservationCodes[code];
    },
    obtenerAnchoPantalla () {
      const ancho = window.innerWidth;
      if (ancho > 1275) {
        this.iconsQuantity = 3;
      } else {
        this.iconsQuantity = 1;
      }
    },
    pesosFormater (number) {
      let formattedNumber = number.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      });
      formattedNumber = formattedNumber.replace(/,/g, '.');
      return formattedNumber;
    },
    validateSitting (reservation) {
      const momentDate = this.$moment();
      const momentReservationDate = this.$moment(parseInt(reservation.date));
      let dif = null;
      if (momentDate.isBefore(momentReservationDate)) {
        dif = momentReservationDate.diff(momentDate, 'minutes');
      }
      if (dif != null) {
        if (reservation.vendorId !== 784 && reservation.vendorId !== 786 && reservation.vendorId !== 1) {
          if (
            (this.selectedDate === this.systemDate.substr(0, 10) ||
              this.selectedDate === this.yesterday) &&
            (reservation.codeStatus === 1 || reservation.codeStatus === 0) &&
            dif <= 60
          ) {
            return true;
          }
        } else {
          if (
            (this.selectedDate === this.systemDate.substr(0, 10) ||
              this.selectedDate === this.yesterday) &&
            (reservation.codeStatus === 1 || reservation.codeStatus === 0) &&
            dif <= 180
          ) {
            return true;
          }
        }
        return false;
      }
      if (
        (this.selectedDate === this.systemDate.substr(0, 10) ||
          this.selectedDate === this.yesterday) &&
        (reservation.codeStatus === 1 || reservation.codeStatus === 0)
      ) {
        return true;
      }
      return false;
    },
    validateSittingOnlyHour (reservation) {
      if (reservation.isSeated) {
        return true;
      }
      const momentDate = this.$moment();
      const momentReservationDate = this.$moment(parseInt(reservation.date));
      let dif = null;
      if (momentDate.isBefore(momentReservationDate)) {
        dif = momentReservationDate.diff(momentDate, 'minutes');
      } else {
        return true;
      }
      if (reservation.vendorId !== 784 && reservation.vendorId !== 786 && reservation.vendorId !== 1) {
        if (dif != null && dif <= 60) {
          return true;
        }
      } else {
        if (dif != null && dif <= 180) {
          return true;
        }
      }
      return false;
    },
    colorIconCompanion (info) {
      let color = 'color: red;';
      const max = info.people - 1;
      if (info.companions != null) {
        if (info.companions.indexOf(',') > 0) {
          const total = info.companions.split(', ');
          if (total.length >= max) {
            color = 'color: green;';
          } else {
            color = 'color: orange;';
          }
        } else {
          const total = 1;
          if (total >= max) {
            color = 'color: green;';
          } else {
            color = 'color: orange;';
          }
        }
      }
      return color;
    },
    messageIconCompanion (info) {
      let message = 'Aún no tiene acompañantes';
      const max = info.people - 1;
      if (info.companions != null) {
        if (info.companions.indexOf(',') > 0) {
          const total = info.companions.split(', ');
          if (total.length >= max) {
            message = 'Ya tienes todos los acompañantes';
          } else {
            message = 'Aún le faltan acompañantes';
          }
        } else {
          const total = 1;
          if (total >= max) {
            message = 'Ya tienes todos los acompañantes';
          } else {
            message = 'Aún le faltan acompañantes';
          }
        }
      }
      return message;
    },
    is_touch_device () {
      return !!('ontouchstart' in window || navigator.maxTouchPoints);
    },
    moveResevation (event, reserv) {
      this.$store.commit(
        reservationTypes.mutations.setSideLateralMove,
        reserv
      );
    },
    maxText (name) {
      if (name.length > 55) {
        return name.substr(0, 55) + '...';
      } else {
        return name;
      }
    },
    isResponsive () {
      if (window.innerWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    oldStatus (type, reservationId) {
      this.executeAction = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:undoReservationStatus',
          data: { reservationId: reservationId, item: type, userId: userId }
        })
        .then(() => {
          this.executeAction = false;
        })
        .catch(() => {
          this.executeAction = false;
        });
    },
    textTable (tableName, sectionName = null, subSectionName = null) {
      if (sectionName === null && subSectionName === null) {
        if (tableName !== null) {
          if (tableName.length > 35) {
            return tableName.substr(0, 32) + '...';
          } else {
            return tableName;
          }
        }
      } else if (subSectionName) {
        const text = subSectionName + ': ' + tableName;
        if (text !== null) {
          if (text.length > 35) {
            return text.substr(0, 32) + '...';
          } else {
            return text;
          }
        }
      } else {
        const text = sectionName + ': ' + tableName;
        if (text !== null) {
          if (text.length > 35) {
            return text.substr(0, 32) + '...';
          } else {
            return text;
          }
        }
      }
    },
    transformServices (text) {
      switch (text) {
        case 'child':
          return 'Niños';
        case 'celebration':
          return 'Celebración';
        case 'allergy':
          return 'Alergia';
        case 'vegetarian':
          return 'Vegetariano';
        case 'disabled':
          return 'Discapacitado';
        case 'pidioZona':
          return 'Pidió Zona';
        case 'pidioMesa':
          return 'Pidió Mesa';
        default:
          return text;
      }
    },
    getDistanceToTop (element) {
      const rect = element.getBoundingClientRect();
      return rect.top;
    },

    isElementCloserToTop (element) {
      const windowHeight = window.innerHeight;
      const distanceToTop = this.getDistanceToTop(element);
      this.distanceToTop = distanceToTop;
      return distanceToTop < windowHeight / 2;
    },
    centerElement (elementToCenter, elementReference) {
      // Obtenemos el rectángulo del elemento de referencia
      const refRect = elementReference.getBoundingClientRect();
      const leftBarSIze = document.getElementsByClassName('menu-elastic')[0].offsetWidth;
      const spaceleft = elementReference.offsetWidth - elementToCenter.offsetWidth;
      if (spaceleft > 0) {
        elementToCenter.style.left = refRect.left + spaceleft / 2 - leftBarSIze + 'px';
        elementToCenter.style.right = 'unset';
      } else {
        elementToCenter.style.right = '13px';
        elementToCenter.style.left = 'unset';
      }
    },
    addShowService (data) {
      if (!this.touchdisabled) {
        this.modalActive = true;
        this.serviceShow.comments = data.comments;
        this.serviceShow.commentRestaurant = data.commentRestaurant;
        this.serviceShow.services = data.services;
        this.intervalModal = setInterval(() => {
          this.elementIsCloserToTop = this.isElementCloserToTop(
            this.$refs.ItemList
          );
          if (window.innerWidth > 600) {
            this.centerElement(this.$refs.ModalService, this.$refs.lastInfo);
          }
        }, 5);
      }
    },
    delShowService (e) {
      clearInterval(this.intervalModal);
      setTimeout(() => {
        this.modalActive = false;
        this.serviceShow.comments = null;
        this.serviceShow.services = null;
      }, 200);
    },

    isShowViewReservation (data) {
      const infoReserva = JSON.parse(JSON.stringify(data));
      this.executeAction = true;
      this.$store
        .dispatch({
          type: 'people:findByReservationId',
          data: { id: infoReserva.id }
        })
        .then((response) => {
          let type = '';
          if (infoReserva.referrer !== 'Walk-In') {
            type =
              infoReserva.balancePaid === null ||
                parseInt(infoReserva.balancePaid) === 0
                ? 'Reserva'
                : 'Precompra';
          } else {
            type = 'Walk-In';
          }
          infoReserva.edit = true;
          if (response.code === 200 && response.data != null) {
            infoReserva.subscribed = response.data.subscribed;
            infoReserva.peopleId = response.data._id;
            infoReserva.peopleMetrics = response.data.peopleMetrics;
          }
          this.executeAction = false;
          this.$store.commit(reservationTypes.mutations.setType, type);
          this.$store.commit(
            reservationTypes.mutations.setInfoReservation,
            infoReserva
          );
          this.$store.commit(
            reservationTypes.mutations.setViewReservation,
            true
          );
        })
        .catch(() => {
          this.executeAction = false;
        });
    },
    setReservationRequestConfirmed (data) {
      this.$buefy.modal.open({
        parent: this,
        component: AlertRequest,
        hasModalCard: true,
        canCancel: false,
        props: {
          id: data.id
        }
      });
    },
    setReservationConfirmed (data) {
      this.executeAction = true;
      const userId = this.$store.getters['auth:user'].id;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsConfirmed',
          data: { reservationId: data.id, userId: userId }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.executeAction = false;
          } else {
            this.executeAction = false;
          }
        })
        .catch(() => {
          this.executeAction = false;
        })
        .finally(() => {
          this.executeAction = false;
        });
    },
    setNotReservationConfirmed (data) {
      this.executeAction = true;
      const userId = this.$store.getters['auth:user'].id;
      this.$store
        .dispatch({
          type: 'reservation:undoReservationStatus',
          data: { reservationId: data.id, item: 'isConfirmed', userId: userId }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.executeAction = false;
          } else {
            this.executeAction = false;
          }
        })
        .catch(() => {
          this.executeAction = false;
        })
        .finally(() => {
          this.executeAction = false;
        });
    },
    setReservationSeated (data) {
      this.delShowService();
      this.executeAction = true;
      const userId = this.$store.getters['auth:user'].id;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsSeated',
          data: { reservationId: data.id, userId: userId }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.executeAction = false;
          } else {
            this.executeAction = false;
          }
        })
        .catch(() => {
          this.executeAction = false;
        })
        .finally(() => {
          this.executeAction = false;
        });
    },
    setReservationSeatedPartial (data) {
      this.executeAction = true;
      const userId = this.$store.getters['auth:user'].id;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsSeatedPartial',
          data: { reservationId: data.id, userId: userId }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.executeAction = false;
          } else {
            this.executeAction = false;
          }
        })
        .catch(() => {
          this.executeAction = false;
        })
        .finally(() => {
          this.executeAction = false;
        });
    },
    cancel (reservationId) {
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsCancelled',
          data: { reservationId: reservationId, userId: userId, vendorId: this.vendor.id }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.executeAction = false;
          this.cancelShow = false;
        });
    },
    statusDisplay (data) {
      if (data.codeStatus === 3) return 'Finalizada';
      if (data.codeStatus === 5) return 'No llegó';
      if (data.codeStatus === 4) return 'Cancelada';
      if (data.codeStatus === 7) return 'Pendiente de pago';
      if (data.codeStatus === 8) return 'Sin confirmar';

      const fechaActual = new Date(this.now);
      if (data.codeStatus === 2) {
        let fechaSentado;
        if (data.referrer === 'Walk-In') {
          fechaSentado = new Date(parseInt(data.isSeatedWalkIn));
        } else {
          fechaSentado = new Date(parseInt(data.isSeated));
        }
        const diferenciaEnMilisegundos = fechaActual - fechaSentado;
        const segundos = Math.floor(diferenciaEnMilisegundos / 1000);
        const minutos = Math.floor(segundos / 60);
        const horas = Math.floor(minutos / 60);

        const segundosRestantes = segundos % 60;
        const minutosRestantes = minutos % 60;
        return this.$moment().hours(horas).minutes(minutosRestantes).seconds(segundosRestantes).format('HH:mm:ss');
      }
      return '';
    },
    isAlertable (mesa) {
      let inforAlert = [];
      let isAlert = false;
      const reser = this.reservations[this.selectedDate];
      const rotationTime = this.getVendor.params.rotationTime;
      const ti = parseInt(mesa);
      const data = [];

      if (reser !== undefined) {
        Object.keys(reser).forEach(function (key) {
          const tabl = reser[key].tableId.split(', ');
          const tables = tabl.map(Number);
          if (tables.includes(ti)) {
            data.push(reser[key]);
          }
        });
      }
      const total = data.length;
      for (var w = 0; w < total; w++) {
        if (
          data[w].codeStatus !== 3 &&
          data[w].codeStatus !== 4 &&
          data[w].codeStatus !== 5
        ) {
          _.map(data, function (o) {
            if (
              o.codeStatus !== 3 &&
              o.codeStatus !== 4 &&
              o.codeStatus !== 5 &&
              o.id !== data[w].id &&
              ((parseInt(o.date) < parseInt(data[w].date) + rotationTime &&
                parseInt(o.date) > parseInt(data[w].date) - rotationTime) ||
                parseInt(o.date) === parseInt(data[w].date))
            ) {
              inforAlert.push(o);
              isAlert = true;
            }
          });
        }
      }

      inforAlert = _.uniqBy(inforAlert, 'id');
      inforAlert = _.sortBy(inforAlert, 'date');
      this.alerts = inforAlert;
      return isAlert;
    },
    toggleAlert (mesa) {
      var els = document.getElementsByClassName('show-menu-alert');
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-alert');
          if (els.length === i) {
            // eslint-disable-next-line
            classie.add(
              document.getElementById('MesaListAlert#' + mesa),
              'show-menu-alert'
            );
          }
        }
      } else {
        // eslint-disable-next-line
        classie.add(
          document.getElementById('MesaListAlert#' + mesa),
          'show-menu-alert'
        );
        // eslint-disable-next-line
        classie.add(document.body, "show-mobile-map");
      }
      this.$emit('alertable', this.alerts);
    },
    closeAlert () {
      var els = document.getElementsByClassName('show-menu-alert');
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-alert');
        }
      }
    },
    convertDate (date) {
      return this.$moment(new Date(parseInt(date))).format('hh:mm a');
    }
  }
};
</script>
<style lang="scss" scoped>
.noClick {
  pointer-events: none;
}
.icon-logo {
  display: flex;
}
.atention {
  color: #FFB904;
  font-family: "Roboto";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.alert-message {
  color: #444B57;
  text-align: center;
  font-family: "Roboto";
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.columnx {
  font-family: "Source Sans Pro";
  z-index: 12;
  position: absolute;
  display: grid;
  background: #fff;
  left: 0;
  right: 0;
  margin: auto;
  padding: unset;
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 449px;
  border-radius: 2px;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  grid-template-rows: 1fr;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  min-height: 82px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr !important;
    width: 97% !important;
  }
  .virtualBorder {
    background: #DFE1E3;
    border-radius: 1px;
    width: 1px;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &:not(.elementIsCloserToTop) {
    transform: translateY(-83%);
    top: unset;
    @media screen and (min-width: 600px) {
      right: unset;
      transform: translateY(-105%);
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
      background-color: white;
      width: 10px;
      height: 10px;
      border-radius: 4px;
      transform: translateY(5px) rotate(45deg);
      z-index: -1;
    }
  }
  &.elementIsCloserToTop {
    transform: translateY(58px);
    @media screen and (min-width: 600px) {
      right: unset;
      transform: translateY(32px);
      margin: unset;
    }
    &::before, &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      margin: auto;
      background-color: white;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.08);
    }
    &::before {
      right: 2px;
      top: 2px;
      width: 21px;
      height: 17px;
      z-index: -6;
      border-top: 0 !important;
      border-right: 0;
      transform: translateY(-5px) rotate(36deg);
      -webkit-transform: translateY(-5px) rotate(40deg);
    }
    &::after {
      right: -10px;
      top: 3px;
      width: 26px;
      height: 8px;
      border-radius: 4px 0;
      -webkit-transform: translateY(-5px) rotate(45deg);
      transform: translateY(-5px) rotate(52deg);
      z-index: -1;
      border-bottom: 0;
    }
  }
  .Celebrations {
    display: flex;
    align-items: center;
    flex-direction: column;
    .virtualBorder {
      background: #DFE1E3;
      border-radius: 1px;
      width: 1px;
      position: absolute;
      left: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      height: 89%;
    }
    .celebrationContainer {
      display: flex;
      flex: 1;
      width: 100%;
    }
    .celebration {
      gap: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      color: #444b57;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .Alerts {
    display: flex;
    flex-direction: column;
    align-items: center;
    .alertsContainer {
      overflow: auto;
      display: flex;
      width: 100%;
      padding: 0px 6px;
      padding-top: 9px;
      justify-content: flex-start;
      flex-direction: column;
      gap: 5px;
      @media screen and (max-width: 600px) {
        padding: 10px 8%;
        max-height: 72px;
      }
      .itemAlerts {
        gap: 4px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width: 600px) {
          justify-content: space-evenly;
          gap: 14px;
        }
        .displayName {
          text-indent: 4px;
          flex: 1;
          text-overflow: ellipsis;
          text-align: start;
        }
        .peopleQuantity {
          margin-right: 3px;
          min-width: 6px;
        }
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          line-height: 1;
        }
        .blockType {
          background: #00adc6;
          mix-blend-mode: normal;
          border-radius: 2px;
          width: 4px;
          height: 10px;
          &.background-list-walk-In {
            background-color: #8a51b4;
          }
          &.background-list-precompra {
            background-color: #cfd452;
          }
        }
        i {
          cursor: pointer;
          mask-size: 90%;
          mask-position: center;
          width: 12px;
          height: 12px;
          margin: unset;
          transform: translateY(-1px);
          &.icon_dashboard_seat {
            width: 9px;
            margin-left: 3px;
          }
        }
      }
    }
    .virtualBorder {
      background: #DFE1E3;
      border-radius: 1px;
      width: 1px;
      position: absolute;
      left: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      height: 89%;
    }
    .title {
      line-height: 1;
      i {
        background-color: red;
        mask-position: center;
        mask-size: contain;
      }
    }
  }
  &.modalComplete {
    width: 639px;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    @media screen and (max-width: 600px) {
      width: 98%;
    }
  }
  &.modalSemi {
    width: 495px;
    @media screen and (max-width: 600px) {
      width: 98%;
    }
  }
  &.alertswithoutEvent {
    grid-template-columns: 1fr 1fr 2fr;
  }
  .comentarios {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    color: #444B57;
  }
  .cont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-color: white;
    @media screen and (max-width: 600px) {
      min-height: 42px;
    }
  }
  .title {
    position: relative;
    margin: unset;
    display: flex;
    align-items: center;
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #444b57;
    background-color: #f7f8f8;
    min-height: 25px;
    gap: 8.6px;
    padding-left: 11px;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    i {
      height: 13px;
      width: 13px;
      background: #444b57;
      mask-repeat: no-repeat;
    }
  }
  .Comments {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0px 0px 6px 0px;
    overflow: auto;
    .comentariosContainer {
      overflow: auto;
      display: grid;
      align-items: self-start;
      place-items: start;
      grid-template-columns: 1fr;
      gap: 3px;
      padding: 3px;
      .container-comments-list-reservation{
        width: 100%;
        margin-bottom: 3px;
        &:last-child{
          padding-left: 0px;
        }
        h4{
          font-weight: 600;
          font-size: 10px;
          margin-bottom: 2px;
        }
        .comentarios{
          font-size: 9px;
          width: 100%;
          min-height: auto;
          max-height: 51px;
          &::-webkit-scrollbar{
            width: 2px!important;
          }
          &::-webkit-scrollbar-thumb{
            background-color: #606773!important;
            box-shadow: none!important;
            border: 0!important;
            outline: none!important;
          }
        }
      }
      @media only screen and (max-width: 600px) {
        padding: 0 3px;
        .container-comments-list-reservation{
          padding: 0 3px!important;
          margin-top: 7px;
          h4{
            margin-bottom: 3px;
          }
          .comentarios{
            min-height: auto;
            max-height: 54px;
          }
        }
      }
      .comentarios {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
        color: #444b57;
        border: 1px solid #dfe1e3;
        border-radius: 4px;
        opacity: 1;
        padding: 4px;
        max-height: 100px;
        overflow: auto;
        overflow-wrap: anywhere;
      }
    }
  }
  .Requeriments {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
    .virtualBorder {
      position: absolute;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      height: 89%;
    }
    @media only screen and (max-width: 600px) {
      .title{
        border-radius: 8px 8px 0px 0;
      }
    }
    .requerimentContainer {
      padding: 0px 12px;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 6px;
      padding: 11px 11px;
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
      a {
        color: #444b57;
        font-family: "Roboto";
        font-style: normal;
        padding: 2px 5px;
        font-weight: 400;
        border: 1px solid #444b57;
        border-radius: 4px;
        font-size: 9px;
      }
    }
  }
  .bars {
    width: 100%;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 13px;
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      &.space {
        padding-left: 25px;
      }
    }
    .cont {
      padding: 2px 0px;
      width: 100%;
    }
    .item-requirement {
      display: inline-block;
      width: 80px;
      color: #000;
      font-size: 11px;
      border: 1px solid rgba(202, 211, 0, 1);
      border-radius: 14px;
      padding: 2px 8px;
      margin: 0 2px;
      background: rgba(252, 255, 193, 1);
    }
    hr {
      margin: 7px;
    }
  }
}
#ReservItemList {
  margin-bottom: 3px;
  height: 35px;
  @media screen and (max-width: 600px) {
    height: 62px;
  }
  .mesa.infoSpace {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    padding: 0px 10px;
    min-width: 25%;
    max-width: 25%;
    gap: 4px;
    @media screen and (max-width: 1100px) {
      min-width: 24%;
      max-width: 24%;
      border-top-right-radius: 6px;
    }
    @media screen and (max-width: 600px) {
      flex: 1;
      max-width: 37.5%;
      min-width: 37.5%;
    }
    span {
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }
  }
  .actions {
    display: flex;
    align-items: stretch;
    width: auto;
    height: 100%;
    gap: 1px;
    @media screen and (max-width: 600px) {
      padding-left: 4px;
      height: 30px;
      border-bottom-right-radius: 6px;
      border: 1px solid #dfe1e3;
      border-left: unset;
      align-items: center;
      gap: 1px;
    }
    .btn-actions {
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        height: 30px;
        border-radius: 6px;
      }
      &:hover {
        filter: brightness(1.05);
      }
      &.flex {
        display: flex;
      }
      &.cursor {
        cursor: pointer;
      }
      i.icon-mks {
        width: 15px;
        height: 15px;
        margin: 0 !important;
      }
    }
  }
  .name.infoSpace {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    min-width: 20%;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    padding-right: 5px;
    @media screen and (max-width: 600px) {
      min-width: 50%;
      border-top-left-radius: 6px;
    }
    .rightSide {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    p {
      height: fit-content;
      line-height: 0.95;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #444b57;
      text-indent: 10px;
      max-width: 95%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .lastInfo.infoSpace {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    max-width: 23%;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      border-bottom-left-radius: 6px;
      max-width: 100%;
    }
  }
  .infoContainer {
    gap: 1px;
    display: flex;
    align-items: center;
    flex: 1;
    height: 35px;
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      height: 60px;
      gap: 0;
    }
  }
  .allIconsContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
  }
  .threePoints {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 16px;
    gap: 2px;
    i {
      background: #444b57;
      height: 4px !important;
      width: 4px !important;
      border-radius: 50%;
    }
  }
  .allSpaceAvailable {
    flex: 1;
  }
  .infoSpace {
    border-radius: 6px;
    height: 100%;
    border: 1px solid #dfe1e3;
    @media screen and (max-width: 600px) {
      max-height: 30px;
      border-radius: unset;
      &:not(.lastInfo):not(.btn-actions) {
        border-bottom: unset;
      }
      &.people {
        position: relative;
        border-left: unset;
        border-right: unset;
        &::after {
          content: "";
          height: 75%;
          width: 1px;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #dfe1e3;
          display: block;
        }
        &::before {
          content: "";
          height: 75%;
          width: 1px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #dfe1e3;
          display: block;
        }
      }
      &.name {
        border-right: unset;
      }
      &.mesa {
        border-left: unset;
      }
      &.lastInfo {
        border-right: unset;
        &::before {
          content: "";
          height: 75%;
          width: 1px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #dfe1e3;
          display: block;
        }
      }
    }
  }
  .infoSpace.background-list-precompra-pending {
    background: white;
  }
  .infoSpace.background-list-precompra {
    background: #ebedb7;
    border-color: #cfd452;
    @media screen and (max-width: 600px) {
      position: relative;
      & + .actions {
        background: #ebedb7;
        border-color: #cfd452;
      }
      &::after {
        background-color: #cfd452;
      }
      &::before {
        background-color: #cfd452;
      }
    }
  }
  .infoSpace.background-list-reserva {
    background: #b3e6ee;
    border-color: #48c4d6;
    @media screen and (max-width: 600px) {
      position: relative;
      & + .actions {
        background: #b3e6ee;
        border-color: #48c4d6;
      }
      &::after {
        background-color: #48c4d6;
      }
      &::before {
        background-color: #48c4d6;
      }
    }
  }
  .infoSpace.background-list-reserva-parcial {
    background: #b3e6ee;
    border-color: #48c4d6;
    opacity: 0.9;
    @media screen and (max-width: 600px) {
      position: relative;
      & + .actions {
        background: #b3e6ee;
        border-color: #48c4d6;
        opacity: 0.9;
      }
      &::after {
        background-color: #48c4d6;
      }
      &::before {
        background-color: #48c4d6;
      }
    }
  }
  .infoSpace.background-list-walk-In {
    background: #e8dcf0;
    border-color: #c0a4d5;
    @media screen and (max-width: 600px) {
      position: relative;
      & + .actions {
        background: #e8dcf0;
        border-color: #c0a4d5;
      }
      &::after {
        background-color: #c0a4d5;
      }
      &::before {
        background-color: #c0a4d5;
      }
    }
  }
  .infoSpace.background-list-sobre-hora {
    border-color: #FFD43B;
    @media screen and (max-width: 600px) {
      position: relative;
      & + .actions {
        border-color: #FFD43B;
      }
    }
  }
  .infoSpace.background-list-retrasada {
    border-color: #FF8A00;
    &::after, &::before{
      background-color: #FF8A00;
    }
    @media screen and (max-width: 600px) {
      position: relative;
      & + .actions {
        border-color: #FF8A00;
      }
    }
  }
  .balancedPaid {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #444b57;
    margin-right: 5px;
  }
  .infoSpace.background-list-grey {
    background: #edeff0;
    pointer-events: none;
  }
  .show-menu-alert {
    display: flex;
    .circle-absolute {
      background: #fff;
      height: 30px;
      width: 30px;
      position: absolute;
      left: 10px;
      top: 0px;
      border-radius: 0;
      z-index: 1;
      transform: rotate(45deg);
    }
    .close-alert {
      position: absolute;
      right: 3px;
      z-index: 99;
      cursor: pointer;
    }
    .sobre-title {
      color: red;
      display: flex;
      align-items: center;
      font-weight: bold;
      z-index: 2;
      position: relative;
      i {
        margin-right: 10px !important;
      }
    }
    .sobre-list {
      z-index: 2;
      position: relative;
      li {
        text-align: left;
        font-size: 13px;
        padding: 5px;
      }
    }
  }
  .menu-wrap-messager {
    left: auto;
    top: 12px !important;
    right: -13px;
  }
  .show-menu-alert .circle-absolute {
    left: auto;
    top: 0px;
    right: 12px;
  }
  .show-menu-alert .close-alert {
    right: 15px;
  }
  .item-list .actions .btn-actions i.icon-mks {
    &.close-alert {
      width: 25px;
      height: 25px;
    }
  }
  @media (max-width: 1000px) {
    .show-menu-alert {
      -webkit-transform: none !important;
      transform: none !important;
      position: fixed !important;
      bottom: 75px !important;
      right: 5px !important;
      width: calc(100% - 10px) !important;
      align-items: flex-start !important;
      justify-content: flex-end !important;
      max-width: 100% !important;
      z-index: 99999 !important;
    }
    .show-menu-alert .menu-wrap-messager {
      width: 100% !important;
      -webkit-animation: none !important;
      animation: none !important;
      top: 0 !important;
      height: 100% !important;
      -webkit-transform: inherit !important;
      transform: inherit !important;
      position: relative !important;
    }
  }
  /* Shown menu */
  .show-menu-alert .table-detail {
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  .show-menu-alert .menu-wrap-messager {
    width: 340px;
    -webkit-animation: anim-jelly 0.8s linear forwards;
    animation: anim-jelly 0.8s linear forwards;
    top: 0;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  .show-menu-alert .menu-messager {
    opacity: 1;
    -webkit-transition: opacity 0.3s 0.3s;
    transition: opacity 0.3s 0.3s;
    -webkit-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    z-index: 2;
    .table-detail {
      opacity: 1;
    }
  }
}
.columns {
  margin: 0;
  padding: 0;
  &:first-child {
    margin: 0 !important;
  }
  &:last-child {
    margin: 0 !important;
  }
  .columns:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .column {
    margin: 0;
    padding: 0;
  }
}
.opacity25 {
  .name, .people, .mesa, .allIconsContainer {
    opacity: 0.6;
  }
}
.item-list {
  height: 100%;
  position: relative;
  & {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row;
    font-size: 13px;
    i {
      color: #444b57;
      font-size: 18px;
      margin-top: 0;
      &.icon_dashboard_clock {
        height: 14px;
        width: 14px;
      }
    }
    .people {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 60px;
      max-width: 60px;
      @media screen and (max-width: 600px) {
        min-width: 42px;
        max-width: unset;
        flex: 1;
      }
      i {
        width: 14px;
        height: 16px;
        background: #444b57;
      }
      span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #444b57;
        line-height: 0.8;
      }
    }
    .icons-requeriments {
      display: flex;
      padding: 5px 10px;
      overflow: hidden;
      img {
        max-height: 100%;
        height: 17px !important;
        width: 17px !important;
        &.icon_glob {
          height: 15px !important;
          width: 15px !important;
        }
      }
      i {
        height: 13px !important;
        width: 13px !important;
        margin-top: 2px;
        display: inline-block;
        margin-right: 0px;
      }
      .ml {
        margin-right: 5px;
      }
    }
    .import-info {
      display: flex;
      align-items: center;
      padding: 0 0px;
      border: unset;
      i {
        width: 16px;
        height: 16px;
        mask-repeat: no-repeat;
        mask-position: center;
        margin-right: 2px;
        &.icon_prohibited {
          background-color: #666666;
        }
        &.icon_ok {
          background-color: green;
        }
        &.icon_cancel {
          background-color: red;
        }
      }
    }
    .timer {
      display: flex;
      font-size: 11px;
      font-weight: 600;
      align-items: center;
      justify-content: flex-end;
      padding: 0 10px 0 0;
      .timeCount.hourMark {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #444b57;
        line-height: 1;
      }
      span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        gap: 6px;
      }
      &.finalizada {
        margin-right: 0px;
        margin-left: 0px;
      }
    }
  }
  .img-check {
    height: 16px;
    width: 16px;
  }
}
.cancelada {
  color: red;
  font-weight: 600;
}
.finalizada {
  color: green;
  font-weight: 600;
}
.icon-services {
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
}
.left-bar-reserva-noShow {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  border-radius: 6px;
  margin-right: 2px;
  background: #b3b3b3;
}
.left-bar-precompra {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  background: #cad300;
}
.left-bar-request-reservation {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  background: #8eb2bd;
}
.left-bar-precompra-pending {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  background: #d9d9d9;
}
.left-bar-reserva {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  background: #00adc6;
}
.left-bar-reserva-cancel {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  background: #FCACAF !important;
}
.left-bar-walkin {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  background: #8a51b4;
}
.left-bar-sobre-hora {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  background: #FFD43B;
}
.left-bar-retrasada {
  width: 6px;
  border-radius: 6px;
  margin-right: 2px;
  background: #FF8A00;
}
.backgroundPrecompraPending {
  color: #d9d9d9;
  font-size: 12px;
  font-weight: bold;
}
.backgroundPrecompra {
  color: #cad300;
  font-size: 12px;
  font-weight: bold;
}
.backgroundReserva {
  color: #00adc6;
  font-size: 12px;
  font-weight: bold;
}
.backgroundWalkIn {
  color: #9567fc;
  font-size: 12px;
  font-weight: bold;
}
.divider-v {
  height: 2.5rem;
  width: 1px;
  background: #d5d5d5;
}
.divider-v.full-height {
  height: 3.3rem;
}
.divider-double {
  border-right: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
  width: 6px;
  height: 3.2rem;
}
.icon {
  height: 1.3rem;
}
.cta-btns {
  width: 8rem;
  height: 3rem;
}
.cta-btn {
  height: 3.5rem;
  outline: none;
}
.cta-btn:hover {
  background: #fafafa;
}
.cta-btn:active {
  background: #eee;
}
.cancelled-icon {
  color: #f83037;
}
#ReservationItem-list {
  display: flex;
  align-items: stretch;
  margin: 0px !important;
  min-height: 60px;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px !important;
  .left-bar-precompra {
    width: 3px;
    height: 60px;
    background: #cad300;
  }
  .left-bar-precompra-pending {
    width: 3px;
    height: 60px;
    background: #d9d9d9;
  }
  .left-bar-reserva {
    width: 3px;
    height: 60px;
    background: #00adc6;
  }
  .left-bar-reserva-cancel {
    width: 3px;
    height: 60px;
    background: #FCACAF !important;
  }
  .left-bar-walkin {
    width: 3px;
    height: 60px;
    background: #8a51b4;
  }
  i {
    margin-right: 0;
    max-width: 100%;
    max-height: 100%;
    margin-top: 0px;
  }
  & > div:first-child {
    border-radius: 5px 0 0 5px;
  }
  .bg-box {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    max-width: 60px;
    &.reservation {
      background: #00adc6;
    }
    &.walking {
      background: #8a51b4;
    }
    &.precompra {
      background: #cad300;
    }
    .roll {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0 5px;
      width: 100%;
      justify-content: center;
      font-size: 12px;
      color: #000;
      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 28px;
        white-space: nowrap;
      }
    }
    .people {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-top: 2px;
      margin-bottom: 2px;
      font-weight: 600;
      &:after {
        content: "";
        bottom: 0px;
        position: absolute;
        height: 1px;
        width: 100%;
        left: 2px;
        background: #d5d5d5;
      }
      i {
        height: 15px;
      }
      .number-people {
        position: relative;
        font-size: 10px;
        font-weight: 700;
        margin-top: -2px;
        color: #000;
        i {
          height: 10px;
        }
      }
    }
  }
  .center-box {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 0;
    .roll {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 5px;
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      max-height: 50%;
      &.name {
        width: 100%;
      }
      .name-com {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 40px);
      }
      &:first-child {
        font-size: 11px;
        font-weight: 700;
        text-transform: capitalize;
        white-space: nowrap;
      }
      .status-box {
        .status {
          font-size: 11px;
          width: 100%;
          .timer.hourMark {
            display: flex;
            flex: 1;
            font-size: 11px;
            font-weight: 600;
            align-items: center;
            justify-content: flex-start;
            padding: 0 10px 0 0;
            min-width: 100px;
            &.cancelada, &.finalizada {
              justify-content: flex-end;
              position: absolute;
              right: 10px;
              top: 36px;
            }
            i {
              height: 15px;
              width: 15px;
              margin-right: 5px;
            }
          }
        }
      }
      .big-icon-box {
        display: flex;
        min-width: 31px;
        max-width: 93px;
        overflow: hidden;
        flex-wrap: wrap;
        max-height: 29px;
      }
      .icon-box {
        height: 30px;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #ccc;
        padding: 5px;
        width: 30px;
        i {
          margin-top: 0;
        }
        img {
          margin-top: 0;
          max-width: 100%;
        }
      }
    }
  }
  .final-box, .check-box {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 40px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 0 0 5px 0;
    cursor: pointer;
    background: transparent;
    i, img {
      height: 19px;
      width: 18px;
    }
    .action {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: none;
      padding: 5px;
      border-radius: 0 5px 5px 0;
      align-items: center;
      cursor: pointer;
      background: transparent;
      border-bottom: solid 1px #ccc;
      border-radius: 0;
      &:last-child {
        border: none;
      }
    }
  }
  .check-box {
    max-width: 30px;
    border: none;
    .action, .action:last-child {
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      &:first-child {
        border-radius: 0px 0 0 0;
      }
      &:last-child {
        border-radius: 0 0 0 0px;
      }
    }
  }
}
.executeAction {
  margin: auto;
  padding: 0px 5px;
  border-left: 1px solid #d5d5d5;
}
.icon-info-alert {
  height: 25px !important;
}
.roll-icon-alert {
  border-left: 1px solid #ccc;
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  padding: 2px 0px 0px 5px;
  .icon-info-alert {
    height: 100% !important;
  }
}
#alertYellow {
  .title-alert {
    display: flex;
    font-weight: bold;
    align-items: center !important;
  }
  .cont-alert {
    text-align: left;
    line-height: 1;
  }
  .cont-logo {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
  }
  .icon-style-alert {
    width: 20% !important;
  }
  h {
    margin: 10px 0 !important;
  }
}
.m-left-10 {
  margin-left: 10px;
}
.icon_glob {
  width: 15px !important;
  height: 15px !important;
  margin: unset;
}
.vipItemList, .vipItem {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: fit-content;
  height: fit-content;
  padding: 4px;
  margin-right: 0px;
  img {
    object-fit: cover;
    width: 20px;
    height: auto;
  }
}
.client-icon{
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  &.visit{
    padding-top: 2px;
    margin-top: 0.5px;
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
  }
  &.no-show{
    margin-top: 1px;
  }
  .visit-number{
    color: white;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.alertContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    transform: translateY(1px);
  }
}
.icon_alert_danger.mainList {
  background-color: red;
  width: 18px;
  height: 18px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  margin-right: 5px;
}
.fix-spaces-between-content{
  width: 71px;
  border: 1px solid #dfe1e3;
  border-left: 0;
  border-radius: 0px 6px 6px 0;
  @media screen and (max-width: 600px) {
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
  }
}
.before-fix-cancel::before{
  right: 28px!important;
}
</style>
