import metricsTypes from '@/store/types/metrics';
import serviceMetric from '@/helpers/apis/serviceMetric';
import serviceReport from '@/helpers/apis/serviceReport';
import moment from 'moment-timezone';
const state = {
  dataReservation: null,
  dataReservationRange: null,
  byMonth: null,
  byDay: null,
  byCountry: null,
  byRange: null,
  notEffective: null,
  dateFrom: null,
  dateTo: null,
  timeState: null,
  finalizedState: null,
  global: null,
  pse: null,
  card: null,
  byHour: null,
  byHourLastHour: null,
  byClientType: null,
  byMediaType: null,
  byExperience: null,
  byExperienceEffective: null,
  byCountryPrecompras: null,
  byCountryReservations: null,
  byCountryWalkIn: null,
  byGuest: null,
  byRequirements: null,
  byEffectiveNotEffective: null,
  byAllTotalReservations: null,
  metricReservations: [],
  year: parseInt(moment().format('YYYY')),
  filterTypeDaySection: null
};

const actions = {
  [metricsTypes.actions.getDataReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceMetric.post('metrics/' + data.data.vendorId + '/' + data.data.type, data.data)
        .then(response => {
          if (!data.data.onlyForSpecificData) {
            commit(metricsTypes.mutations.setDataReservation, response.data);
            commit(metricsTypes.mutations.setDataReservationRange, response.data);
            commit(metricsTypes.mutations.setByMonth, response.data.byMonth);
            commit(metricsTypes.mutations.setByDay, response.data.byDay);
            commit(metricsTypes.mutations.setByCountry, response.data.byCountry);
            commit(metricsTypes.mutations.setByRange, response.data.byRange);
            commit(metricsTypes.mutations.setNotEffective, response.data.notEffective);
            commit(metricsTypes.mutations.setGlobal, response.data.global);
            commit(metricsTypes.mutations.setPse, response.data.pse);
            commit(metricsTypes.mutations.setCard, response.data.card);
            commit(metricsTypes.mutations.setByHour, response.data.byHour);
            commit(metricsTypes.mutations.setByHourLastHour, response.data.byLastHour);
            commit(metricsTypes.mutations.setByClientType, response.data.byClientType);
            commit(metricsTypes.mutations.setByMediaType, response.data.byMediaType);
            commit(metricsTypes.mutations.setByExperience, response.data.byExperience);
            commit(metricsTypes.mutations.setByExperienceEffective, response.data.byExperienceEffective);
            commit(metricsTypes.mutations.setByCountryPrecompras, response.data.byCountryPrecompras);
            commit(metricsTypes.mutations.setByCountryReservations, response.data.byCountryReservations);
            commit(metricsTypes.mutations.setByCountryWalkIn, response.data.byCountryWalkIn);
            commit(metricsTypes.mutations.setByGuest, response.data.byGuest);
            commit(metricsTypes.mutations.setByRequirements, response.data.byRequirements);
            commit(metricsTypes.mutations.setByEffectiveNotEffective, response.data.effectiveNotEffective);
            commit(metricsTypes.mutations.setByAllTotalReservations, response.data.allReservations);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [metricsTypes.actions.getDataReservationFilter]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceMetric.post('metrics/' + data.data.vendorId + '/' + data.data.type, data.data)
        .then(response => {
          commit(metricsTypes.mutations.setDataReservation, response.data);
          commit(metricsTypes.mutations.setDataReservationRange, response.data);
          commit(metricsTypes.mutations.setByMonth, response.data.byMonth);
          commit(metricsTypes.mutations.setByCountry, response.data.byCountry);
          commit(metricsTypes.mutations.setByDay, response.data.byDay);
          commit(metricsTypes.mutations.setByRange, response.data.byRange);
          commit(metricsTypes.mutations.setNotEffective, response.data.notEffective);
          commit(metricsTypes.mutations.setGlobal, response.data.global);
          commit(metricsTypes.mutations.setPse, response.data.pse);
          commit(metricsTypes.mutations.setCard, response.data.card);
          commit(metricsTypes.mutations.setByHour, response.data.byHour);
          commit(metricsTypes.mutations.setByHourLastHour, response.data.byLastHour);
          commit(metricsTypes.mutations.setByClientType, response.data.byClientType);
          commit(metricsTypes.mutations.setByMediaType, response.data.byMediaType);
          commit(metricsTypes.mutations.setByCountryPrecompras, response.data.byCountryPrecompras);
          commit(metricsTypes.mutations.setByCountryReservations, response.data.byCountryReservations);
          commit(metricsTypes.mutations.setByCountryWalkIn, response.data.byCountryWalkIn);
          commit(metricsTypes.mutations.setByGuest, response.data.byGuest);
          commit(metricsTypes.mutations.setByRequirements, response.data.byRequirements);
          commit(metricsTypes.mutations.setByEffectiveNotEffective, response.data.effectiveNotEffective);
          commit(metricsTypes.mutations.setByAllTotalReservations, response.data.allReservations);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [metricsTypes.actions.getDataReservationRange]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceMetric.post('metrics/' + data.data.vendorId + '/' + data.data.type, data.data)
        .then(response => {
          commit(metricsTypes.mutations.setDataReservation, response.data);
          commit(metricsTypes.mutations.setDataReservationRange, response.data);
          commit(metricsTypes.mutations.setByMonth, response.data.byMonth);
          commit(metricsTypes.mutations.setByCountry, response.data.byCountry);
          commit(metricsTypes.mutations.setByDay, response.data.byDay);
          commit(metricsTypes.mutations.setByRange, response.data.byRange);
          commit(metricsTypes.mutations.setNotEffective, response.data.notEffective);
          commit(metricsTypes.mutations.setGlobal, response.data.global);
          commit(metricsTypes.mutations.setPse, response.data.pse);
          commit(metricsTypes.mutations.setCard, response.data.card);
          commit(metricsTypes.mutations.setByHour, response.data.byHour);
          commit(metricsTypes.mutations.setByHourLastHour, response.data.byLastHour);
          commit(metricsTypes.mutations.setByClientType, response.data.byClientType);
          commit(metricsTypes.mutations.setByMediaType, response.data.byMediaType);
          commit(metricsTypes.mutations.setByCountryPrecompras, response.data.byCountryPrecompras);
          commit(metricsTypes.mutations.setByCountryReservations, response.data.byCountryReservations);
          commit(metricsTypes.mutations.setByCountryWalkIn, response.data.byCountryWalkIn);
          commit(metricsTypes.mutations.setByGuest, response.data.byGuest);
          commit(metricsTypes.mutations.setByRequirements, response.data.byRequirements);
          commit(metricsTypes.mutations.setByEffectiveNotEffective, response.data.effectiveNotEffective);
          commit(metricsTypes.mutations.setByAllTotalReservations, response.data.allReservations);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [metricsTypes.actions.getDataReservationTime]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceMetric.post('metrics/averageReservationTime', data.data)
        .then(response => {
          commit(metricsTypes.mutations.setTimeState, response.data.metricData);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [metricsTypes.actions.getDataReservationCompleted]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceMetric.post('metrics/reservationsFinalized', data.data)
        .then(response => {
          commit(metricsTypes.mutations.setFinalizedState, response.data.metricData);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [metricsTypes.actions.getMetricReservations]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceMetric.post('metrics/reservations', data.data)
        .then(response => {
          commit(metricsTypes.mutations.setMetricReservations, response.data.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [metricsTypes.actions.generateReportMetrics]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('generateReportBooking', data.data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [metricsTypes.actions.generateReportPrepurchaseMetrics]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('generateReportPrepurchase', data.data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [metricsTypes.actions.generateReportWalkIn]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceReport.post('generateReportWalkIn', data.data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  }
};

const getters = {
  [metricsTypes.getters.dataReservation]: state => state.dataReservation,
  [metricsTypes.getters.dataReservationRange]: state => state.dataReservationRange,
  [metricsTypes.getters.byMonth]: state => state.byMonth,
  [metricsTypes.getters.byCountry]: state => state.byCountry,
  [metricsTypes.getters.byCountryPrecompras]: state => state.byCountryPrecompras,
  [metricsTypes.getters.byCountryReservations]: state => state.byCountryReservations,
  [metricsTypes.getters.byCountryWalkIn]: state => state.byCountryWalkIn,
  [metricsTypes.getters.byDay]: state => state.byDay,
  [metricsTypes.getters.byRange]: state => state.byRange,
  [metricsTypes.getters.notEffective]: state => state.notEffective,
  [metricsTypes.getters.dateFrom]: state => state.dateFrom,
  [metricsTypes.getters.year]: state => state.year,
  [metricsTypes.getters.dateTo]: state => state.dateTo,
  [metricsTypes.getters.timeState]: state => state.timeState,
  [metricsTypes.getters.finalizedState]: state => state.finalizedState,
  [metricsTypes.getters.global]: state => state.global,
  [metricsTypes.getters.pse]: state => state.pse,
  [metricsTypes.getters.card]: state => state.card,
  [metricsTypes.getters.byHour]: state => state.byHour,
  [metricsTypes.getters.byHourLastHour]: state => state.byHourLastHour,
  [metricsTypes.getters.byClientType]: state => state.byClientType,
  [metricsTypes.getters.byMediaType]: state => state.byMediaType,
  [metricsTypes.getters.byExperience]: state => state.byExperience,
  [metricsTypes.getters.byExperienceEffective]: state => state.byExperienceEffective,
  [metricsTypes.getters.metricReservations]: state => state.metricReservations,
  [metricsTypes.getters.byGuest]: state => state.byGuest,
  [metricsTypes.getters.filterTypeDaySection]: state => state.filterTypeDaySection,
  [metricsTypes.getters.byRequirements]: state => state.byRequirements,
  [metricsTypes.getters.byEffectiveNotEffective]: state => state.byEffectiveNotEffective,
  [metricsTypes.getters.byAllTotalReservations]: state => state.byAllTotalReservations
};

const mutations = {
  [metricsTypes.mutations.setDataReservation]: (state, data) => {
    state.dataReservation = data;
  },
  [metricsTypes.mutations.setDataReservationRange]: (state, data) => {
    state.dataReservationRange = data;
  },
  [metricsTypes.mutations.setByMonth]: (state, data) => {
    state.byMonth = data;
  },
  [metricsTypes.mutations.setByCountry]: (state, data) => {
    state.byCountry = data;
  },
  [metricsTypes.mutations.setByCountryPrecompras]: (state, data) => {
    state.byCountryPrecompras = data;
  },
  [metricsTypes.mutations.setByCountryReservations]: (state, data) => {
    state.byCountryReservations = data;
  },
  [metricsTypes.mutations.setByCountryWalkIn]: (state, data) => {
    state.byCountryWalkIn = data;
  },
  [metricsTypes.mutations.setByHour]: (state, data) => {
    state.byHour = data;
  },
  [metricsTypes.mutations.setByHourLastHour]: (state, data) => {
    state.byHourLastHour = data;
  },
  [metricsTypes.mutations.setByClientType]: (state, data) => {
    state.byClientType = data;
  },
  [metricsTypes.mutations.setByExperience]: (state, data) => {
    state.byExperience = data;
  },
  [metricsTypes.mutations.setByExperienceEffective]: (state, data) => {
    state.byExperienceEffective = data;
  },
  [metricsTypes.mutations.setByMediaType]: (state, data) => {
    state.byMediaType = data;
  },
  [metricsTypes.mutations.setByDay]: (state, data) => {
    state.byDay = data;
  },
  [metricsTypes.mutations.setByRange]: (state, data) => {
    state.byRange = data;
  },
  [metricsTypes.mutations.setNotEffective]: (state, data) => {
    state.notEffective = data;
  },
  [metricsTypes.mutations.setDateFrom]: (state, data) => {
    state.dateFrom = data;
  },
  [metricsTypes.mutations.setYear]: (state, data) => {
    state.year = data;
  },
  [metricsTypes.mutations.setDateTo]: (state, data) => {
    state.dateTo = data;
  },
  [metricsTypes.mutations.setTimeState]: (state, data) => {
    state.timeState = data;
  },
  [metricsTypes.mutations.setFinalizedState]: (state, data) => {
    state.finalizedState = data;
  },
  [metricsTypes.mutations.setMetricReservations]: (state, data) => {
    state.metricReservations = data;
  },
  [metricsTypes.mutations.setGlobal]: (state, data) => {
    state.global = data;
  },
  [metricsTypes.mutations.setPse]: (state, data) => {
    state.pse = data;
  },
  [metricsTypes.mutations.setCard]: (state, data) => {
    state.card = data;
  },
  [metricsTypes.mutations.setByGuest]: (state, data) => {
    state.byGuest = data;
  },
  [metricsTypes.mutations.setFilterTypeDaySection]: (state, data) => {
    state.filterTypeDaySection = data;
  },
  [metricsTypes.mutations.setByRequirements]: (state, data) => {
    state.byRequirements = data;
  },
  [metricsTypes.mutations.setByEffectiveNotEffective]: (state, data) => {
    state.byEffectiveNotEffective = data;
  },
  [metricsTypes.mutations.setByAllTotalReservations]: (state, data) => {
    state.byAllTotalReservations = data;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
