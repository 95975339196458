<template>
  <div class="sidebar-info-container__container-general-cards">
    <div class="sidebar-info-container__card-reservations-people bg_reserva">
      <InfoCardReservation
        title="Total"
        :totalReservations="totalReservationGeneral"
        :totalUsers="totalPeopleGeneral"
        customIcon="icon_reservation"
      />
      <InfoCardReservation
        title="Total efectivas"
        :totalReservations="dataReservations.resevationReservations"
        :totalUsers="dataReservations.resevationPeople"
        customIcon="icon_reservation"
      />
      <div class="sidebar-info-container__card-reservations-people__general-total">
        <h4>Total personas reservadas efectivas</h4>
        <span>{{ percentFormated(dataReservations.reservas ? Math.round((dataReservations.resevationReservations * 100) / dataReservations.reservas) : 0) }}%</span>
      </div>
    </div>
    <div class="sidebar-info-container__card-reservations-people bg_precompra">
      <InfoCardReservation
        title="Total"
        :totalReservations="totalReservationPrePurchaseGeneral"
        :totalUsers="totalPeoplePrepurchaseGeneral"
        customIcon="icon_reserva_precompro"
      />
      <InfoCardReservation
        title="Total efectivas"
        :totalReservations="dataReservations.prePurchaseReservations"
        :totalUsers="dataReservations.prePurchasePeople"
        customIcon="icon_reserva_precompro"
      />
      <div class="sidebar-info-container__card-reservations-people__general-total card-prec-aux">
        <h4>Total personas con precompras efectivas</h4>
        <span>{{ percentFormated(dataReservations.reservas ? Math.round((dataReservations.prePurchaseReservations * 100) / dataReservations.reservas) : 0) }}%</span>
      </div>
    </div>
    <div class="sidebar-info-container__card-reservations-people bg_walking">
      <InfoCardReservation
        title="Total"
        :totalReservations="dataReservations.walkinReservations"
        :totalUsers="dataReservations.walkinPeople"
        customIcon="icon_metrics_walkin"
      />
      <InfoCardReservation
        title="Total efectivas"
        :totalReservations="dataReservations.walkinReservations"
        :totalUsers="dataReservations.walkinPeople"
        customIcon="icon_metrics_walkin"
      />
      <div class="sidebar-info-container__card-reservations-people__general-total card-walk-aux">
        <h4>Total personas walkin efectivas</h4>
        <span>{{ percentFormated(dataReservations.reservas ? Math.round((dataReservations.walkinReservations * 100) / dataReservations.reservas) : 0) }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCardReservation from '@/components/MetricsV2/subcomponents/InfoCardReservation';
export default {
  props: [
    'dataReservations',
    'totalReservationGeneral',
    'totalPeopleGeneral',
    'totalReservationPrePurchaseGeneral',
    'totalPeoplePrepurchaseGeneral'
  ],
  components: {
    InfoCardReservation
  },
  computed: {
    percentFormated () {
      return (value) => {
        if (typeof value !== 'number' || isNaN(value)) {
          return '00';
        }
        return Math.round(value).toLocaleString(undefined, { minimumIntegerDigits: 2 });
      };
    }
  }
};
</script>

<style>

</style>
