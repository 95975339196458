<template>
  <div class="table-detail">
    <!-- <button @click.prevent.stop="isNotSelected()">
      Close
    </button> -->
    <Hours :date="now"></Hours>
    <div class="btns-box columns" v-if="!validatePermission('ReadOnly') && !validatePermission('MixSFCN')">
      <div class="box-visiv" >
        <div class="btn-action-messager unlocky" :class="{ blokedted:getTableIsBlockedDetail(tableInfo.blocked) }" v-if="!blockbox && validatePermission('BlockTables')" @click.prevent.stop="blockbox = true" v-tooltip="{content: 'Bloqueos mesa/hora', placement: 'bottom'}"><i class="icon_lock white icon-mks lock" v-if="!getTableIsBlockedDetail(tableInfo.blocked)" ></i> <i class="icon_unlock white icon-mks lock" v-if="getTableIsBlockedDetail(tableInfo.blocked)"></i> <i class="fa fa-spinner fa-spin" v-if="unblocking"></i></div>
        <div class="btn-action-messager icon-x" v-if="blockbox" @click.prevent.stop="blockbox = false"  v-tooltip="{content: 'Cerrar', placement: 'bottom'}">
          <i class="icon_unlock white icon-mks icon_close" v-if="!blocking"></i><i class="fa fa-spinner fa-spin" v-if="blocking"></i>
        </div>
        <span class="btn-action-messager" v-if="unblockingTable"  v-tooltip="{content: 'Tiene Decoración', placement: 'bottom'}" ><i class="fa fa-spinner fa-spin"></i>&nbsp;</span>
        <span class="btn-action-messager" v-if="blockingTable"><i class="fa fa-spinner fa-spin"></i>&nbsp;</span>
        <span class="btn-action-messager private"  v-tooltip="{content: 'Mesa Privada', placement: 'bottom'}" v-if="validatePermission('PrivateTables') && tableInfo.preventReservations == 1 && !unblockingTable" @click.prevent.stop="unTableBlocked(tableInfo.id, tableInfo.seatingSectionId, vendorId)"><i class="icon-mks white icon_key"></i></span>
        <span class="btn-action-messager unprivate" v-if="tableInfo.preventReservations == 0 && !blockingTable && validatePermission('PrivateTables')" @click.prevent.stop="tableBlocked(tableInfo.id, tableInfo.seatingSectionId, vendorId)"  v-tooltip="{content: 'Mesa no es privada', placement: 'bottom'}"><i class="icon-mks white icon_key"></i></span>
      </div>
      <div class="box-reservs column">
        <span class="btn-action-messager reserva" :class="{changeSize: params.prePurchaseDashboard && selectedDate === today}" @click.prevent.stop="viewReservation(tableInfo.seatingSectionId,tableInfo.displayName, tableInfo.id)" v-tooltip="{content: 'Crear Reserva', placement: 'bottom'}">
          <span class="material-icons-outlined">add_circle_outline</span>&nbsp;Reserva
        </span>
        <span class="btn-action-messager precompra" :class="{changeSize: params.prePurchaseDashboard && selectedDate === today}" v-if="params.prePurchaseDashboard" @click.prevent.stop="viewPrecompra(tableInfo.seatingSectionId,tableInfo.displayName, tableInfo.id)" v-tooltip="{content: 'Crear Precompra', placement: 'bottom'}">
          <span class="material-icons-outlined">add_circle_outline</span>&nbsp;Precompra
        </span>
        <span class="btn-action-messager walk" :class="{changeSize: params.prePurchaseDashboard}" v-if="selectedDate === today" @click.prevent.stop="viewWalkIn(tableInfo.seatingSectionId,tableInfo.displayName, tableInfo.id)"  v-tooltip="{content: 'Crear Walk-In', placement: 'bottom'}">
          <span class="material-icons-outlined">add_circle_outline</span>&nbsp;Walk-In
        </span>
      </div>
    </div>
    <div class="bybloqued" v-if="blockbox && timesDashboard[getDayOfWeek].length>0">
      <h2>Bloquear hora/mesa</h2>
      <div class="columns blokerHour is-mobile">
          <div v-for="(infoHours, keyHourBloker) in timesDashboard[getDayOfWeek]" :key="keyHourBloker+'.4'" :ref="changeId(infoHours.hora)" class="column">
            <a v-if="getHourTableIsBlocked(tableInfo.blocked, convertHour(infoHours.hora))"  class="hour-link bloked" @click.prevent.stop="desbloquear(tableInfo.id, tableInfo.seatingSectionId, convertHour(infoHours.hora), keyHourBloker)"><i class="fa fa-spinner fa-spin" v-if="isLoading === keyHourBloker"></i>{{ convertHour(infoHours.hora) | moment('hh:mm a') }}</a>
            <a v-if="!getHourTableIsBlocked(tableInfo.blocked, convertHour(infoHours.hora))"  class="hour-link" @click.prevent.stop="bloquear(tableInfo.id, tableInfo.seatingSectionId, convertHour(infoHours.hora), keyHourBloker)"><i class="fa fa-spinner fa-spin" v-if="isLoading === keyHourBloker"></i>{{ convertHour(infoHours.hora) | moment('hh:mm a') }}</a>
          </div>
      </div>
      <div class="btn-action-messager unlocky" v-if="getTableIsBlockedDetail(tableInfo.blocked)" @click.prevent.stop="desbloquearTable(tableInfo.id, tableInfo.seatingSectionId)"><i class="icon_lock white icon-mks lock" v-if="!unblocking"></i><i class="fa fa-spinner fa-spin" v-if="unblocking"></i> Desbloquear toda la mesa</div>
      <div class="btn-action-messager locky" v-if="!getTableIsBlockedDetail(tableInfo.blocked)" @click.prevent.stop="bloquearTable(tableInfo.id, tableInfo.seatingSectionId)"><i class="icon_unlock white icon-mks lock" v-if="!blocking"></i><i class="fa fa-spinner fa-spin" v-if="blocking"></i> Bloquear mesa todo el día </div>
    </div>
    <div v-else-if="blockbox" style="text-align:center"> <h1><b>No hay horario disponible para este dia</b> </h1> </div>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';
import Hours from './Hours';
export default {
  name: 'TableDetail',
  props: {
  },
  components: { Hours },
  data () {
    return {
      now: new Date().getTime(),
      reservaciones: [],
      blocking: false,
      blockingTable: false,
      unblocking: false,
      unblockingTable: false,
      info: [],
      isLoading: false,
      isLoadingAction: false,
      reservas: [],
      hourInstant: null,
      clock: null,
      blockbox: false
    };
  },
  methods: {
    tableBlocked (id, section, vendorId) {
      this.blockingTable = true;
      this.$store.dispatch({
        type: 'reservation:setBlockedTableSection',
        data: {
          seatingTableId: id,
          vendorId: this.idVendor
        }
      }).then(() => {
        this.blockingTable = false;
      });
    },
    unTableBlocked (id, section, vendorId) {
      this.unblockingTable = true;
      this.$store.dispatch({
        type: 'reservation:setUnBlockedTableSection',
        data: {
          seatingTableId: id,
          vendorId: this.idVendor
        }
      }).then(() => {
        this.unblockingTable = false;
      });
    },
    viewWalkIn (section, mesa, id) {
      this.$store.commit(reservationTypes.mutations.setSectionSelected, section);
      this.$store.commit(reservationTypes.mutations.setTableSelected, mesa);
      this.$store.commit(reservationTypes.mutations.setTableSelectedId, id);
      this.$store.commit(reservationTypes.mutations.setFromMap, true);
      this.setAddWalkin();
    },
    viewReservation (section, mesa, id) {
      this.$store.commit(reservationTypes.mutations.setSectionSelected, section);
      this.$store.commit(reservationTypes.mutations.setTableSelected, mesa);
      this.$store.commit(reservationTypes.mutations.setTableSelectedId, id);
      this.$store.commit(reservationTypes.mutations.setFromMap, true);
      this.setAddReservation();
    },
    viewPrecompra (section, mesa, id) {
      this.$store.commit(reservationTypes.mutations.setSectionSelected, section);
      this.$store.commit(reservationTypes.mutations.setTableSelected, mesa);
      this.$store.commit(reservationTypes.mutations.setTableSelectedId, id);
      this.$store.commit(reservationTypes.mutations.setFromMap, true);
      this.setAddPrecompra();
    },
    isNotSelected () {
      this.$store.commit(reservationTypes.mutations.setSelectTableId, '');
      this.$store.commit(reservationTypes.mutations.setSelectTableInfo, {});
    },
    changeId (id) {
      return id.replace(':', '');
    },
    desbloquear (table, section, timestamp, keyId) {
      this.isLoading = keyId;
      this.$store.dispatch({
        type: 'reservation:unBlockedHourTable',
        data: {
          seatingTableId: table,
          vendorId: this.idVendor,
          seatingSectionId: section,
          date: timestamp,
          userId: this.user.id
        }
      }).then(() => {
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    getReservationTableId () {
      const tiene = Object.keys(this.timesDashboard).length;
      this.info = [];
      const reser = this.reservas;
      const ti = this.tableInfo.id;
      const data = [];
      if (tiene > 0) {
        const hay = this.timesDashboard[this.getDayOfWeek].length;
        if (hay > 0) {
          for (var i = 0; i < hay; i++) {
            const date = this.timesDashboard[this.getDayOfWeek][i].hora;
            const hora = this.convertHour(date);
            if (reser !== undefined) {
              Object.keys(reser).forEach(function (key) {
                const tabl = reser[key].tableId.split(', ');
                const tables = tabl.map(Number);
                if (reser[key].fechaCompleta === hora && tables.includes(ti)) {
                  data.push(reser[key]);
                }
              });
            }
          }
        }
      }
      const total = data.length;
      for (var w = 0; w < total; w++) {
        this.info.push(data[w]);
      }
    },
    bloquear (table, section, timestamp, keyId) {
      this.isLoading = keyId;
      this.$store.dispatch({
        type: 'reservation:blockedHourTable',
        data: {
          seatingTableId: table,
          vendorId: this.idVendor,
          seatingSectionId: section,
          date: timestamp,
          userId: this.user.id
        }
      }).then(() => {
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    convertHour (hour) {
      const hor = hour.split(':');
      const hora = (hor[0] >= 9) ? '0' + hor[0] : hor[0];
      const minuto = hor[1];
      const hoy = this.$moment(this.dateComplete).hours(parseInt(hora)).minutes(parseInt(minuto)).format('YYYY-MM-DD HH:mm:ss');
      return hoy;
    },

    getHourTableIsBlocked (blocked, timestamp) {
      if (blocked !== undefined) {
        const hay = blocked.length;
        if (hay > 0) {
          for (var i = 0; i < hay; i++) {
            if (blocked[i].fechaCompleta === timestamp) {
              return blocked[i].isBlocked;
            }
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    bloquearTable (table, section) {
      this.blocking = true;
      this.$store.dispatch({
        type: 'reservation:blockedDayTable',
        data: {
          seatingTableId: table,
          vendorId: this.idVendor,
          seatingSectionId: section,
          date: this.selectedDate,
          userId: this.user.id
        }
      }).then(() => {
        this.blocking = false;
      }).catch(() => {
        this.blocking = false;
      });
    },
    desbloquearTable (table, section) {
      this.unblocking = true;
      this.$store.dispatch({
        type: 'reservation:unBlockedDayTable',
        data: {
          seatingTableId: table,
          vendorId: this.idVendor,
          seatingSectionId: section,
          date: this.selectedDate,
          userId: this.user.id
        }
      }).then(() => {
        this.unblocking = false;
      }).catch(() => {
        this.blocking = false;
      });
    },
    getTableIsBlockedDetail (blocked) {
      if (blocked !== undefined) {
        let arrayHourBlock = [];
        arrayHourBlock = _.filter(blocked, { fecha: this.selectedDate });
        if (arrayHourBlock.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  watch: {
    tableInfo () {
      return this.tableInfo;
    },
    reservations () {
      this.reservaciones = this.reservations[this.selectedDate];
    }
  }
};
</script>
<style lang="scss" scoped>
  .table-detail{
    position:relative;
    z-index:2;
    .btns-box{
      display:flex;
      margin-top: 10px !important;
      .box-visiv{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .btn-action-messager{
          color: #fff;
          background:#ccc;
          font-weight: 600;
          display: flex;
          padding: 0;
          border-radius: 50%;
          line-height: 22px;
          margin-right: 1px;
          width: 32px;
          height: 32px;
          align-items: center;
          justify-content:center;
          cursor:pointer;
          min-height:30px;
          &.unlocky.blokedted{
            background:#3FC769;
          }
          &.icon-x{
            position: initial;
            background:#7B7E80;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            i{
              /* height: 100%; */
              position: inherit !important;
              /* background-color: white; */
              margin-top: unset;
            }
          }
          &.unlocky{
            background:#DE2828;
          }
          &.private{
            background:#52575A;
          }
          &.unprivate{
            background:#7B7E80;
          }
          i {
            height: 17px;
            width: 17px;
            margin-right: 0;
            &.lock{
              height: 15px;
              width: 15px;
              margin-right: 0px;
              margin-top: -1px;
            }
            &.icon_close{
              height: 30px;
              width: 21px;
              margin-right: 0;
              margin-left: -1px;
            }
          }
        }
      }
      .box-reservs{
        display: flex;
        align-items: center;
        .btn-action-messager{
          color: #fff;
          font-weight: 600;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 4px 15px 3px 10px;
          border-radius: 50px;
          font-size: 12px;
          line-height: 22px;
          margin-left: 5px;
          cursor:pointer;
          &.reserva{
            background:#00ADC6;
          }
          &.requestReservation {
            background: #8eb2bd;
          }
          &.precompra{
            background:#CAD300;
            right: 8px;
          }
          &.walk{
            background:#8A51B4;
            right: 12px;
          }
          &.changeSize {
            transform: scale(0.9);
            padding: 4px 4px 3px 4px;
            white-space: nowrap;
            margin: unset;
            position: relative;
            box-sizing: border-box;
            @media screen and (min-width:1000px) and (max-width:1200px) {
              transform: scale(0.8);
            }
          }
          i {
            height: 29px;
            width: 30px;
            margin-top: -4px;
            margin-left: -10px;
            margin-bottom: -3px;
            font-size: 30px;
            margin-right: 0px;
          }
        }
      }
    }
    .bybloqued{

      h2{
        text-align: center;
        justify-content: center;
        display: flex;
        font-weight: 500;
        margin-top: 10px;
        padding: 5px;
        border-top: 1px solid #EBECED;
        font-size: 14px;
        width:100%;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .btn-action-messager{
          color: #fff;
          background:#ccc;
          font-weight: 600;
          display: flex;
          padding: 0 20px;
          border-radius: 50px;
          line-height: 22px;
          margin-right: 1px;
          height: 32px;
          width: fit-content;
          align-items: center;
          justify-content:center;
          cursor:pointer;
          min-height:30px;
          margin-top: 10px;
          &.locky{
            background:#3FC769;
          }
          &.unlocky{
            background:#DE2828;
          }
          i {
            height: 17px;
            width: 17px;
            margin-right: 5px;
            &.lock{
              height: 15px;
              width: 15px;
              margin-right:3px;
            }

          }
        }

      .blokerHour{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-top:1px solid#EBECED;
        border-right:1px solid #EBECED;
        max-height: 212px;
        overflow: auto;
        .hour-link{
          .fa-spinner{
            margin-right:5px;
          }
          &.bloked{
            color: rgb(255, 255, 255);
            background: #DE2828;
          }
          color:#000;
          border-left:1px solid#EBECED;
          border-bottom:1px solid#EBECED;
          background: transparent;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          white-space: nowrap;
          font-size: 13px;
        }

      }
    }
  }
</style>
